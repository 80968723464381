<template>
  <div id="visa_details" class="collapse" data-parent="#services_accordion">
    <div class="card-body">
      <div class="row">
        <div class="col-sm-4">
          <div class="d-flex">
            <input type="text" class="form-control" v-model="inputText" id />
            <button
              @click.prevent="handleSaveData"
              class="btn btn-submit ripple mb-0"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "Visa",
  computed: {
    ...mapGetters(["packageVisa"])
  },
  data() {
    return {
      inputText: this.packageVisa
    };
  },
  watch: {
    inputText(value) {
      this.setInputTextData({ value, type: "visa" });
    }
  },
  created() {
    this.inputText = this.packageVisa;
  },
  methods: {
    ...mapActions(["updatePackage"]),
    ...mapMutations(["setInputTextData"]),
    handleSaveData() {
      this.updatePackage({
        type: "ADVANCE",
        data: {
          advance: {
            code: this.$route.query.code,
            data: { visa: this.inputText }
          }
        }
      }).then(() => {
        this.$toasted.success("Visa Details Is Updated", {
          position: "bottom-center",
          duration: 5000
        });
      });
    }
  }
};
</script>
<style scoped>
.btn-submit {
  overflow: inherit;
  padding: 6px;
  height: 34px;
  width: 80px;
  margin-left: 0;
}
</style>
