<template>
  <div id="flight_details" class="collapse" data-parent="#services_accordion">
    <div class="card-body">
      <div class="row">
        <div class="col-12 bb">
          <p class="flight_type">
            ONWARD -
            <span class="float-right">
              <a @click.prevent="manageFlightDetails('add', 'onward')" href="javascript:void(0)" class="add-more">
                <b>ADD MORE</b>
              </a>
            </span>
          </p>
          <!-- Item For Use InterConnected Flights -->
          <div v-if="packageFlights.onward.length > 0">
            <Items
              :class="{
                bb: index != packageFlights.onward.length - 1
              }"
              @onClickRemove="manageFlightDetails('remove', 'onward', index)"
              @changeStops="(val) => handleChangeStops(val, packageFlights.onward[index])"
              :action="packageFlights.onward.length > 1"
              :destinations="destinations"
              v-for="(item, index) in $v.packageFlights.onward.$each.$iter"
              :key="item.orderId.$model"
              :itemData="{ ...item }"
            />
          </div>
        </div>
        <div class="col-12 bb">
          <p class="flight_type">
            INTER CITY/COUNTRY FLIGHT -
            <span class="float-right">
              <a @click.prevent="manageFlightDetails('add', 'interconnect')" href="javascript:void(0)" class="add-more">
                <b>ADD MORE</b>
              </a>
            </span>
          </p>
          <!-- Item For Use InterConnected Flights -->
          <div v-if="packageFlights.interconnect.length > 0">
            <Items
              :class="{
                bb: index != packageFlights.interconnect.length - 1
              }"
              @onClickRemove="manageFlightDetails('remove', 'interconnect', index)"
              @changeStops="(val) => handleChangeStops(val, packageFlights.interconnect[index])"
              :action="true"
              :destinations="destinations"
              v-for="(item, index) in $v.packageFlights.interconnect.$each.$iter"
              :key="item.orderId.$model"
              :itemData="{ ...item }"
            />
          </div>
        </div>
        <div class="col-12">
          <p class="flight_type">
            RETURN -
            <span class="float-right">
              <a @click.prevent="manageFlightDetails('add', 'return')" href="javascript:void(0)" class="add-more">
                <b>ADD MORE</b>
              </a>
            </span>
          </p>
          <!-- Item For Use InterConnected Flights -->
          <div v-if="packageFlights.return.length > 0">
            <Items
              :class="{
                bb: index != packageFlights.return.length - 1
              }"
              @onClickRemove="manageFlightDetails('remove', 'return', index)"
              @changeStops="(val) => handleChangeStops(val, packageFlights.return[index])"
              :action="packageFlights.return.length > 1"
              :destinations="destinations"
              v-for="(item, index) in $v.packageFlights.return.$each.$iter"
              :key="item.orderId.$model"
              :itemData="{ ...item }"
            />
          </div>
        </div>
      </div>
      <p class="text-center mt-4">
        <button :disabled="$v.$invalid" @click.prevent="handleSaveData" href="javascript:void(0)" class="btn btn-submit ripple mb-0">Save</button>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Items from "./item";
export default {
  name: "Flights",
  components: { Items },
  data() {
    return {
      destinations: []
    };
  },
  computed: {
    ...mapGetters(["packageFlights", "packageDetails"])
  },
  validations: {
    packageFlights: {
      onward: {
        $each: {
          origin: {},
          destination: {},
          orderId: {},
          airline: {},
          departuretime: {},
          arrivaltime: {},
          stops: {
            $each: {
              name: {}
            }
          }
        }
      },
      interconnect: {
        $each: {
          origin: {},
          destination: {},
          orderId: {},
          airline: {},
          departuretime: {},
          arrivaltime: {},
          stops: {
            $each: {
              name: {}
            }
          }
        }
      },
      return: {
        $each: {
          origin: {},
          destination: {},
          orderId: {},
          airline: {},
          departuretime: {},
          arrivaltime: {},
          stops: {
            $each: {
              name: {}
            }
          }
        }
      }
    }
  },
  async created() {
    this.destinations = await this.getPackageDestinationsCities();
  },
  methods: {
    ...mapActions(["updatePackage", "getPackageDestinationsCities"]),
    ...mapMutations(["managePackageFlights"]),
    handleSaveData() {
      this.updatePackage({
        type: "ADVANCE",
        data: {
          advance: {
            code: this.$route.query.code,
            data: { flights: this.packageFlights }
          }
        }
      }).then(() => {
        this.$toasted.success(`Update Fligths Details`, {
          position: "bottom-center",
          duration: 5000
        });
      });
    },
    manageFlightDetails(type, route, index) {
      this.managePackageFlights({
        type,
        index,
        route,
        value: {
          orderId: Math.random(),
          origin: "",
          destination: "",
          airline: "",
          departuretime: "",
          arrivaltime: "",
          stops: 0
        }
      });
    },
    handleChangeStops(val, item) {
      let newitem = [];
      if (+val > 0) {
        for (let i = 1; i <= +val; i++) {
          newitem.push({ name: "" });
        }
      } else {
        newitem = [];
      }
      item.stops = newitem;
    }
  }
};
</script>

<style></style>
