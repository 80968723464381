<template>
  <container headTitle="Create Your Package">
    <div slot="createbtn" class="package-steppers">
      <div class="row">
        <div
          v-for="(step, index) in steppers"
          :key="step.name"
          class="col-md-4"
        >
          <div
            @click="() => activeStepper(step, index)"
            :class="{ active: activeStep == step.component }"
            class="pkg-step-heading"
          >
            {{ step.name }}
          </div>
        </div>
      </div>
    </div>

    <div slot="content">
      <!-- Stepper First Package Name -->
      <!--   <div v-if="!packageByCodeLoading"> -->
      <package-basic
        :updateMode="updateMode"
        v-show="activeStep == 'package-basic'"
        v-on:continueBtn="e => handleSubmit(e)"
      />
      <package-details
        :updateMode="updateMode"
        v-show="activeStep == 'package-details'"
        v-on:continueBtn="e => handleSubmit(e)"
      />
      <package-advance
        :updateMode="updateMode"
        :activeStep="activeStep"
        v-show="activeStep == 'package-advance'"
        v-on:continueBtn="e => handleSubmit(e)"
      />
      <!--  </div>
      <div v-else>
        <div id="splash-wrapper">
          <div id="splash-content"></div>
        </div>
      </div>-->
    </div>
  </container>
</template>

<script>
import PackageBasic from "./components/basic";
import PackageDetails from "./components/details";
import PackageAdvance from "./components/advance";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "Package",
  components: {
    PackageBasic,
    PackageDetails,
    PackageAdvance
  },
  computed: {
    ...mapGetters(["packageServices", "packageByCodeLoading"])
  },
  data() {
    return {
      updateMode: false,
      isPackage: false,
      steppers: [
        {
          name: "Package Basics",
          component: "package-basic",
          isvalid: true
        },
        {
          name: " Inclusions & Itinerary",
          component: "package-details",
          isvalid: false
        },
        {
          name: "Services & Pricing",
          component: "package-advance",
          isvalid: false
        }
      ],
      activeStep: "package-basic"
    };
  },
  created() {
    this.getThemeIcons();
    this.getPackageServices();
    if (this.$route.query.code && this.$route.query.code != "") {
      let localThis = this;
      document.title = `Loading... ${this.$route.query.code}`;
      this.getPackageByCode(this.$route.query.code)
        .then(data => {
          localThis.updateMode = true;
          if (data.code) {
            document.title = data.name;
            localThis.isPackage = true;
            localThis.steppers[1].isvalid = true;
            localThis.steppers[2].isvalid = true;
          } else {
            this.$router.push({ path: "/package/listing" });
            //localThis.isPackage = false;
            /*  localThis.$toasted.error(
              `Package Code - ${localThis.$route.query.code} is not available`,
              {
                position: "bottom-center",
                duration: 5000,
              }
            ); */
          }
        })
        .catch(err => {
          const message = JSON.parse(
            err.message.replace(/GraphQL error: /, "")
          );
          if (message.message == "publishrequest") {
            localThis.$toasted.info(`Package Pusblish Request Is Pending`, {
              position: "bottom-center",
              duration: 5000
            });
          }
        });
    }
    if (this.$route.query.tabName && this.$route.query.tabName != "") {
      this.activeStep = this.$route.query.tabName;
    } else {
      this.$router.push({ path: "/package/listing" });
    }
  },
  methods: {
    ...mapActions(["getPackageByCode", "getThemeIcons", "getPackageServices"]),
    ...mapMutations(["isFieldsDataExists"]),
    activeStepper(step) {
      if (!this.updateMode || this.isPackage) {
        if (step.isvalid) {
          this.$router
            .push({
              query: { code: this.$route.query.code, tabName: step.component }
            })
            .then(data => {
              this.$router.go();
            });
        }
      }
    },
    handleSubmit(value) {
      this.updateMode = true;
      this.steppers[1].isvalid = true;
      this.steppers[2].isvalid = true;
      this.activeStep = this.steppers[value].component;
    }
  },
  destroyed() {
    const states = {
      selectedOption: "",
      options: {
        isSingleHotel: false,
        items: []
      },
      flights: {
        onward: {},
        interconnect: [],
        return: {}
      },
      newSelectedDates: [],
      sightseeing: [],
      transfers: [],
      bus: [],
      hotels: [],
      meals: [],
      visa: "",
      taxes: "",
      cruise: [],
      prices: {
        adult: {
          single: "",
          singleallowed: true,
          twin: "",
          twinallowed: true,
          extrabed: "",
          extrabedallowed: true,
          surcharge: ""
        },
        child: {
          cwb: "",
          cwballowed: true,
          cnb: "",
          cnballowed: true,
          cwbsurcharge: "",
          cnbsurcharge: ""
        },
        "surcharge-description": "",
        infant: "",
        infantallowed: true
      }
    };
    for (const state in states) {
      this.isFieldsDataExists({ value: states[state], type: state });
    }
  }
};
</script>

<style>
.package-steppers {
  /*  margin: 15px 0 30px; */
  counter-reset: package-steps;
}

.pkg-step-heading {
  position: relative;
  padding: 5px 15px 5px 40px;
  font-size: 14px;
  overflow: hidden;
  color: #8f8f8f;
  cursor: pointer;
}

.pkg-step-heading.active {
  color: #17a2b8;
}

.pkg-step-heading:before {
  counter-increment: package-steps;
  content: "0" counter(package-steps);
  position: absolute;
  top: 0px;
  left: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  background: #8f8f8f;
  color: #fff;
  text-align: center;
  font-size: 12px;
}

.pkg-step-heading.active:before {
  background: #17a2b8;
}

.pkg-step-heading:after {
  background: #8f8f8f;
  content: " ";
  height: 1px;
  margin-left: 15px;
  width: 100%;
  position: absolute;
  top: 50% !important;
}

.pkg-step-heading.active:after {
  background: #3c3c3c;
}

/* .package-content {
  background: #f5f6f6;
  padding: 15px;
  margin: 0 0 30px;
} */

.btn-submit {
  border-radius: 0;
  padding: 6px 25px;
  background: #17a2b8;
  color: #fff;
  margin: 0 5px;
}

.btn-submit:active,
.btn-submit:hover,
.btn-submit:focus,
.btn-submit:visited {
  color: #fff;
}

.btn-cancel {
  background: #f56d6d;
}

.package_info {
  position: relative;
  background: #fff;
  margin: 30px 0 0;
  padding: 35px 15px 15px 15px;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.175);
  -moz-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.175);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.175);
}

.package_info-icon {
  position: absolute;
  top: 0;
  left: 50%;
  width: 60px;
  height: 60px;
  line-height: 55px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  box-shadow: 0 -3px 8px rgba(0, 0, 0, 0.175);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.package_info-icon:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  border-radius: 50%;
  border: 1px solid #17a2b8;
  z-index: 3;
}

.package_info-icon:after {
  position: absolute;
  content: "";
  bottom: -5px;
  left: -3px;
  right: -3px;
  height: 35px;
  background: #fff;
}

.package_info-icon svg,
.package_info-popup svg {
  position: relative;
  vertical-align: middle;
  z-index: 9;
}

.package_info > h3,
.package-info-content > h3 {
  font-size: 16px;
  margin: 0 0 15px;
}

.info_list > li {
  list-style: none;
  font-size: 13px;
  color: #8f8f8f;
  position: relative;
  padding: 2px 2px 2px 25px;
}

.info_list > li::before {
  content: "\203A";
  position: absolute;
  top: 0;
  left: 5px;
  line-height: 18px;
  font-weight: bold;
  color: #17a2b8;
  font-size: 24px;
  margin: 0 0 3px;
}

.info_list > li > b {
  color: #3c3c3c;
}

.inclusion-checkbox > label {
  margin: 0 25px 15px 0;
}

.services {
  margin: 15px 0;
  user-select: none;
}

.services_label {
  display: inline-block;
  margin: 0 10px 15px 0;
  cursor: pointer;
  user-select: none;
}

.services_checkbox {
  display: none;
}

.services_span {
  display: inline-block;
  border-radius: 50px;
  padding: 5px 30px;
  background: transparent;
  border: 1px solid #17a2b8;
  color: #17a2b8;
}

.services_checkbox:checked ~ .services_span {
  background: #17a2b8;
  color: #fff;
}

.service-details,
.package-options {
  margin: 15px 0;
  background: #fff;
  color: #8f8f8f;
  padding: 15px;
  border-bottom: 1px solid #d7d7d7;
}

.flight_type {
  color: #666;
}

.br {
  border-right: 1px solid #d7d7d7;
}

.bb {
  border-bottom: 1px solid #d7d7d7;
  margin-bottom: 15px;
}

.btn-hotel {
  margin: 25px 2px 0;
  padding: 6px 10px;
  font-size: 12px;
}

.remove-service {
  font-size: 12px;
  color: #f56d6d;
  text-transform: none;
}

.remove-service:hover,
.remove-service:active,
.remove-service:visited,
.remove-service:focus {
  color: #f56d6d;
  text-decoration: none;
}

.add-more {
  font-size: 12px;
  color: #17a2b8;
}

.add-more:hover,
.add-more:active,
.add-more:visited,
.add-more:focus {
  color: #17a2b8;
  text-decoration: none;
}

.hotel-section {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d7d7d7;
}

.service-details .hotel-section:only-child {
  margin: 0;
  padding: 0;
  border: 0;
}

.pkg-inc-list {
  padding: 0 15px 0 15px;
  margin: 15px 0 10px;
}

.pkg-inc-list > li {
  position: relative;
  margin: 0 0 15px;
  padding-right: 80px;
  cursor: pointer;
}

.li-close {
  position: absolute;
  top: -2px;
  right: -15px;
  width: 30px;
  height: 25px;
  line-height: 22px;
  text-align: center;
  font-size: 20px;
  color: #f56d6d;
  font-weight: bold;
  cursor: pointer;
}

.li-move-up {
  position: absolute;
  top: -2px;
  right: 49px;
  width: 30px;
  height: 25px;
  line-height: 22px;
  text-align: center;
  color: #17a2b8;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
  cursor: pointer;
}

.li-move-down {
  position: absolute;
  top: -2px;
  right: 17px;
  width: 30px;
  height: 25px;
  line-height: 22px;
  text-align: center;
  color: #17a2b8;
  cursor: pointer;
}

.pkg-inc-list > li:first-child .li-move-up,
.pkg-inc-list > li:last-child .li-move-down,
.pkg-iten-section li:first-child .pkg-iten .li-move-up,
.pkg-iten-section li:last-child .pkg-iten .li-move-down {
  display: none;
}

.pkg-inc-list > li:last-child .li-move-up,
.pkg-iten-section li:last-child .pkg-iten .li-move-up {
  right: 17px;
}

.pkg-iten {
  margin-top: 40px;
  margin-right: 6px;
  position: relative;
}

.pkg-iten .li-move-up,
.pkg-iten .li-move-down {
  top: 3px;
}

.pkg-inc-list > li > span,
.pkg-iten > span {
  display: none;
}

.pkg-inc-list > li:hover > span,
.pkg-iten-section > li:hover .pkg-iten > span {
  display: block;
}

.pkg-inc-list > li:hover {
  color: #17a2b8;
  border-bottom: 1px dashed;
}
.table .thead-dark th {
  font-weight: normal;
}

.table .thead-light td {
  color: #3c3c3c;
  background-color: #fff;
  border-bottom: 1px solid #d7d7d7;
}

.package_info-popup {
  display: block;
  position: fixed;
  top: 15%;
  right: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  background: #fff;
  margin: 0;
  text-align: center;
  cursor: pointer;
  z-index: 1034;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.175);
  -moz-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.175);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.175);
}

.package_info-popup:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  border: 1px solid #17a2b8;
  z-index: 3;
}

.package-info-content,
.package-info-checkbox:checked ~ .package-info-content,
.package-info-overlay,
.package-info-checkbox:checked ~ .package-info-overlay {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.package-info-content {
  position: fixed;
  top: calc(15% + 60px);
  max-height: calc(100vh - 15vh - 70px);
  right: 0;
  max-width: 360px;
  overflow-y: auto;
  padding: 15px;
  background: #fff;
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  transform: scaleY(0);
  transform-origin: top;
  z-index: 1034;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.175);
  -moz-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.175);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.175);
}

.package-info-checkbox:checked ~ .package-info-content {
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  transform: scaleY(1);
}

.package-info-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  margin: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}

.package-info-checkbox:checked ~ .package-info-overlay {
  opacity: 1;
  visibility: visible;
  z-index: 1033;
}

.sgt-table.table .thead-dark th {
  min-width: 140px;
}

.package-options .form-control {
  margin: 0 0 10px;
}

.package-options .form-para,
.package-options .form-control {
  font-size: 13px;
}

.pkg-option-parent {
  margin: 0 -15px;
  overflow-x: auto;
}

.pkg-option-div {
  min-width: 25%;
  padding: 0 15px;
}

.services_accordion .card-header,
.services_accordion .card {
  border-radius: 0;
}

.services_accordion .card {
  border-bottom: 0;
  margin-bottom: -1px;
}

.services_accordion .card-header {
  padding: 0;
}

.services_accordion .btn-link {
  margin: 0;
  width: 100%;
  margin: 0;
  border: 0;
  display: block;
  text-align: left;
  padding: 12px 15px;
  text-decoration: none;
  font-weight: bold;
  color: #3c3c3c;
  font-size: 14px;
  text-transform: uppercase;
  position: relative;
}

.chevron svg,
.chevron svg {
  vertical-align: middle;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.chevron {
  float: right;
  font-size: 34px;
  font-weight: bold;
  line-height: 12px;
}

.collapsed .chevron svg {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}

.avail-dates {
  margin: 0;
  padding: 0 0 0 20px;
  overflow: hidden;
}
.avail-dates > li {
  float: left;
  width: 20%;
}
</style>
