import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["isPkgDatesLoading", "pkgDatesDetails", "datesPricing"]),
    calendarDates() {
      const date = this.$moment(this.currentDate);
      let weekOffset = 0;
      const startOfMonth = date.startOf("month");
      const _selectedMonth = this?.renderDates?.find(
        _m => _m.month == date.format("MM-YYYY")
      );
      let diff = startOfMonth.weekday() - weekOffset;
      if (diff < 0) diff += 7;
      /* Previous Month Dates */
      let prevMonthDays = [];
      for (let i = 0; i < diff; i++) {
        prevMonthDays.push({
          day: startOfMonth.clone().subtract(diff - i, "days"),
          className: "disabled"
        });
      }

      /* Current Month dates with Selection dates */
      let currentMonthDays = [];
      for (let i = 1; i < date.daysInMonth() + 1; i++) {
        if (_selectedMonth?.items?.length) {
          const _day = this.$moment(
            new Date(
              `${date.year()}/${date.month() + 1}/${i < 9 ? `0${i}` : i}`
            )
          );
          const _selectDay = _selectedMonth?.items.find(_cd =>
            this.$moment(_cd.day).isSame(_day)
          );
          if (_selectDay) {
            if (this.$moment().isSameOrBefore(_day, "days")) {
              currentMonthDays.push({
                day: _day,
                className:
                  this.date_types == "update" && _selectDay.is_update
                    ? "update"
                    : _selectDay.active && _selectDay?.prices.adult.surcharge
                    ? "surcharge"
                    : _selectDay.active
                    ? "active"
                    : "de_active",
                prices:
                  _selectDay?.prices ||
                  JSON.parse(JSON.stringify(this.datesPricing))
              });
            } else {
              currentMonthDays.push({
                day: _day,
                className: "disabled",
                prices:
                  _selectDay?.prices ||
                  JSON.parse(JSON.stringify(this.datesPricing))
              });
            }
          } else {
            const _date = this.$moment(
              new Date(`${date.year()}/${date.month() + 1}/${i}`)
            );
            currentMonthDays.push({
              day: _date,
              className:
                this.$moment().isSame(_date, "month") &&
                this.$moment().isAfter(_date, "day")
                  ? "disabled"
                  : ["update", "disabled"].includes(this.date_types)
                  ? "disabled"
                  : "",
              prices: JSON.parse(JSON.stringify(this.datesPricing))
            });
          }
        } else {
          const _date = this.$moment(
            new Date(`${date.year()}/${date.month() + 1}/${i}`)
          );
          currentMonthDays.push({
            day: _date,
            className:
              this.date_types == "insert"
                ? this.$moment().isSame(_date, "month") &&
                  this.$moment().isAfter(_date, "day")
                  ? "disabled"
                  : ""
                : "disabled",
            prices: JSON.parse(JSON.stringify(this.datesPricing))
          });
        }
      }

      const daysAdded = prevMonthDays.length + currentMonthDays.length - 1;

      let nextMonthDays = [];
      let i = 1;
      while ((daysAdded + i) % 7 !== 0) {
        nextMonthDays.push({
          day: currentMonthDays[currentMonthDays.length - 1].day
            .clone()
            .add(i, "days"),
          className: "disabled"
        });
        i = i + 1;
      }

      return [...prevMonthDays, ...currentMonthDays, ...nextMonthDays];
    },
    is_honeymoon() {
      return this?.pkgDatesDetails?.options[this.active_option]?.is_honeymoon;
    }
  }
};
