<template>
  <container class="hotel-master" headTitle="Hotel List">
    <div slot="createbtn">
      <div class="d-flex justify-content-end">
        <div class="mr-3">
          <input
            type="text"
            class="form-control form-control-filter"
            placeholder="Enter Hotel Name"
            v-model="filter.searchName"
          />
        </div>

        <div class="mr-3">
          <select
            v-model="filter.selectedDest"
            class="form-control city-selector form-control-filter"
          >
            <option value="">Select City</option>
            <option
              v-for="city in renderCityList"
              :key="city.id"
              :value="city.id"
              >{{ city.destinationName }}</option
            >
          </select>
        </div>
        <!-- <div class="mr-3">
          <select v-model="filter.hotelType" class="form-control form-control-filter">
            <option value="">Select Type</option>
            <option v-for="item in hotelTypeList" :key="item._id" :value="item._id">{{ item.name }}</option>
          </select>
        </div> -->
        <div>
          <button
            @click.prevent="isNew = true"
            class="btn btn-sm btn-info float-right"
          >
            Add New Hotel
          </button>
        </div>
      </div>
    </div>

    <div slot="content">
      <div class="mb-2">
        Total :
        <span class="fnt-16 font-bold" style="color: #17a2b8">{{
          filterHotelList.length
        }}</span>
      </div>
      <div id="hotel_module">
        <table class="table">
          <thead class="table-teal">
            <tr>
              <th>S No.</th>
              <th>Name</th>
              <th>Rating</th>
              <th>Address</th>
              <th>City</th>
              <th>Updated By</th>
              <th>Created By</th>
              <th>Last Updated</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in filterHotelList" :key="item._id">
              <td>
                {{ index + 1 }}
              </td>
              <td>
                {{ item.name }}
              </td>
              <td>
                {{ item.rating }}
              </td>
              <td>
                {{ item.address }}
              </td>
              <td>
                {{ item.city.name }}
              </td>
              <td>
                {{ item.updatedBy }}
              </td>
              <td>
                {{ item.createdBy }}
              </td>
              <td>
                {{ $moment(item.updatedAt).format("DD MMM YYYY | hh:mm a") }}
              </td>
              <td>
                <a @click.prevent="handleEditData(item)" class="btn btn-submit"
                  >Edit</a
                >
              </td>
            </tr>
          </tbody>
        </table>
        <InsertHotel
          @onClose="handleCloseHotel"
          :isNew="isNew"
          :formItem="selectedItem"
          :isUpdate="isUpdate"
        />
      </div>
    </div>
  </container>
</template>

<script>
import { mapActions } from "vuex";
import InsertHotel from "./insert_hotel";
export default {
  name: "PkgHotel",
  components: { InsertHotel },
  data() {
    return {
      isNew: false,
      isUpdate: false,
      hotelTypeList: [],
      hotelList: [],
      selectedItem: {},
      filter: {
        hotelType: "",
        searchName: "",
        selectedDest: ""
      }
    };
  },
  computed: {
    filterHotelList() {
      let newHotel = this.hotelList;
      if (this.filter.hotelType !== "") {
        newHotel = newHotel.filter(data => data.type === this.filter.hotelType);
      }
      if (this.filter.searchName !== "") {
        newHotel = newHotel.filter(data =>
          data.name.toLowerCase().includes(this.filter.searchName.toLowerCase())
        );
      }
      if (this.filter.selectedDest && this.filter.selectedDest) {
        newHotel = newHotel.filter(
          data => data.city.id === this.filter.selectedDest
        );
      }
      return newHotel;
    },
    renderCityList() {
      if (this.hotelList && this.hotelList.length) {
        return this.hotelList.reduce((arr, data) => {
          const find = arr.findIndex(val => val.id == data.city.id);
          if (find === -1) {
            arr.push(data.city);
          }
          return arr;
        }, []);
      } else {
        return [];
      }
    }
  },
  created() {
    this.getAllPkgHotelsType().then(data => {
      this.hotelTypeList = data;
    });
    this.handleCloseHotel();
  },
  methods: {
    ...mapActions(["getAllPkgHotels", "getAllPkgHotelsType"]),
    handleEditData(item) {
      delete item.__typename;
      this.selectedItem = { ...item, type: item.type ? item.type : "" };
      this.isUpdate = true;
      this.isNew = true;
    },
    handleHotelType(value) {
      const find = this.hotelTypeList.find(data => data._id == value);
      return find ? find.name : "";
    },
    handleCloseHotel() {
      this.isUpdate = false;
      this.isNew = false;
      this.selectedItem = {};
      this.getAllPkgHotels().then(data => {
        this.hotelList = data;
      });
    }
  }
};
</script>

<style scoped>
.overflow {
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.btn-submit {
  border-radius: 0;
  padding: 0px 10px;
  background: #17a2b8;
  color: #fff !important;
  margin: 0 5px;
  font-size: 10px;
}

.btn-success {
  color: #fff !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
  cursor: default !important;
}
/* Table Filter */

.hotel-master >>> .form-control-filter {
  height: 30px;
  margin: 0;
  font-size: 12px;
  padding: 0 10px 0 10px;
}

.city-selector option > span {
  font-size: 12px;
}
.total_count {
  font-size: 12px;
  text-align: right;
  display: block;
  margin-bottom: 2px;
  font-weight: 600;
}
.hotel-master >>> hr {
  margin-bottom: 2px;
}
.table-head {
  max-height: 78vh !important;
}
</style>
