<template>
  <div id="bus_details" class="collapse" data-parent="#services_accordion">
    <div class="card-body">
      <div
        v-for="(item, index) in $v.packageBus.$each.$iter"
        :key="item.orderId.$model"
        class="row gutter14"
      >
        <div class="col-3 form-group" :class="{ invalid: item.from.$error }">
          <label class="form-para mb-2 required">From</label>
          <input
            @blur="item.from.$touch()"
            v-model.trim="item.from.$model"
            type="text"
            class="form-control"
          />
          <span
            v-if="item.from.$error && !item.from.required"
            class="text-muted"
            >from must be required</span
          >
        </div>
        <div class="col-3 form-group" :class="{ invalid: item.to.$error }">
          <label class="form-para mb-2 required">To</label>
          <input
            @blur="item.to.$touch()"
            v-model.trim="item.to.$model"
            type="text"
            class="form-control"
          />
          <span v-if="item.to.$error && !item.to.required" class="text-muted"
            >To must be required</span
          >
        </div>
        <div class="offset-sm-3 col-3">
          <p class="text-right mt-2">
            <a
              v-if="packageBus.length - 1 == index"
              @click.prevent="managePackageBus('add')"
              href="javascript:void(0)"
              class="btn btn-submit ripple btn-hotel"
              >Add Bus</a
            >
            <a
              v-if="packageBus.length > 1"
              @click.prevent="managePackageBus('remove', index)"
              href="javascript:void(0)"
              class="btn btn-submit btn-cancel ripple btn-hotel"
              >Remove</a
            >
          </p>
        </div>
        <div class="col-12 form-group">
          <label class="form-para mb-2">Details</label>
          <vue-editor
            :editor-toolbar="toolbarSettings"
            v-model.trim="item.details.$model"
          />
        </div>
      </div>
      <p class="text-center mt-4">
        <button
          :disabled="$v.$invalid"
          @click.prevent="handleSaveData"
          href="javascript:void(0)"
          class="btn btn-submit ripple mb-0"
        >
          Save
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  name: "Bus",
  computed: {
    ...mapGetters(["packageBus", "packageDetails"])
  },
  data() {
    return {
      toolbarSettings: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [{ header: 1 }, { header: 2 }],
        [{ script: "sub" }, { script: "super" }],
        [{ direction: "rtl" }],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ["link", "clean"]
      ]
    };
  },
  validations: {
    packageBus: {
      $each: {
        orderId: {},
        from: { required },
        to: { required },
        details: {}
      }
    }
  },
  methods: {
    ...mapActions(["updatePackage"]),
    ...mapMutations(["managePackageServices"]),
    handleSaveData() {
      this.updatePackage({
        type: "ADVANCE",
        data: {
          advance: {
            code: this.$route.query.code,
            data: { bus: this.packageBus }
          }
        }
      }).then(() => {
        this.$toasted.success(`update Bus Details`, {
          position: "bottom-center",
          duration: 5000
        });
      });
    },
    managePackageBus(type, index) {
      this.managePackageServices({
        type,
        index,
        service: "bus",
        value: {
          orderId: Math.random(),
          from: "",
          to: "",
          type: ""
        }
      });
    }
  }
};
</script>

<style scoped>
.form-control {
  margin: 0px !important;
}

.form-group.invalid {
  margin-bottom: 0px !important;
}
</style>
