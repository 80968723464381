<template>
  <div class="rc-group inclusion-checkbox theme-category">
    <label
      v-for="item in _.sortBy(pkgThemesList, 'name')"
      :key="item._id"
      class="rc-control rc-control-checkbox"
    >
      <input
        @change.prevent="$emit('changeTheme', item)"
        autocomplete="off"
        type="checkbox"
        :checked="packageData.themes.includes(item._id)"
      />
      <div class="rc-control-indicator"></div>
      <span id="Span14">{{ item.name | camel }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "HolidaysCategory",
  props: {
    pkgThemes: {
      type: Array
    },
    packageData: {
      type: Object
    }
  },
  computed: {
    pkgThemesList() {
      return this.pkgThemes;
    }
  }
};
</script>

<style scoped>
.theme-category {
  max-height: 250px;
  overflow-y: auto;
}
</style>
