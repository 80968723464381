<template>
  <div id="meals_details" class="collapse" data-parent="#services_accordion">
    <div class="card-body">
      <div class="means-section">
        <div class="row">
          <div class="col-3">
            <!-- v-if="!pkgOptions.isSingleHotel"  -->
            <p class="form-para mb-2">For Package Option</p>
            <!-- <p v-else class="form-para mb-2">For Package Hotel Categories</p> -->
          </div>
        </div>
        <!-- v-if="!pkgOptions.isSingleHotel" -->
        <div class="mb-3">
          <label v-for="item in availableOptions(pkgOptions.items)" :key="item.type" class="radio">
            <input v-model="selectedOptionType" :value="item.type" name="pkg-option" type="radio" />
            <span>{{ item.type }}</span>
          </label>
        </div>
        <!-- <div v-else class="mb-3">
          <label v-for="item in availableOptions(pkgOptions.items)" :key="item.type" class="radio">
            <span>{{ item.type }}</span>
          </label>
        </div>-->
      </div>
      <div class="meals_container">
        <div class="meals_item mr-2">
          <label class="rc-control rc-control-checkbox">
            <input v-model="selectOptionMeals.breakfast" name="pkg-option" type="checkbox" />
            <div class="rc-control-indicator"></div>
            <span>Breakfast</span>
          </label>
          <input
            :disabled="!selectOptionMeals.breakfast"
            v-model.trim.number="selectOptionMeals.breakfast_amount"
            type="text"
            placeholder="Amount"
            v-input-type="'number'"
            class="form-control form-control-sm"
          />
        </div>
        <div class="meals_item mr-2">
          <label class="rc-control rc-control-checkbox">
            <input v-model="selectOptionMeals.lunch" name="pkg-option" type="checkbox" />
            <div class="rc-control-indicator"></div>
            <span>Lunch</span>
          </label>
          <input
            :disabled="!selectOptionMeals.lunch"
            v-model.trim.number="selectOptionMeals.lunch_amount"
            type="text"
            placeholder="Amount"
            v-input-type="'number'"
            class="form-control form-control-sm"
          />
        </div>
        <div class="meals_item">
          <label class="rc-control rc-control-checkbox">
            <input v-model="selectOptionMeals.dinner" name="pkg-option" type="checkbox" />
            <div class="rc-control-indicator"></div>
            <span>Dinner</span>
          </label>
          <input
            :disabled="!selectOptionMeals.dinner"
            v-model.trim.number="selectOptionMeals.dinner_amount"
            type="text"
            placeholder="Amount"
            v-input-type="'number'"
            class="form-control form-control-sm"
          />
        </div>
      </div>
      <!--  <div v-if="selectedOptionType != ''" class="row gutter14 text-center">
        <div class="col-sm-12">
          <div class="rc-group inclusion-checkbox">
            <label class="rc-control rc-control-checkbox">
              <input v-model="selectOptionMeals.breakfast" name="pkg-option" type="checkbox" />
              <div class="rc-control-indicator"></div>
              <span>Breakfast</span>
            </label>
            <label class="rc-control rc-control-checkbox">
              <input v-model="selectOptionMeals.lunch" name="pkg-option" type="checkbox" />
              <div class="rc-control-indicator"></div>
              <span>Lunch</span>
            </label>
            <label class="rc-control rc-control-checkbox">
              <input v-model="selectOptionMeals.dinner" name="pkg-option" type="checkbox" />
              <div class="rc-control-indicator"></div>
              <span>Dinner</span>
            </label>
          </div>
        </div>
      </div> -->
      <hr class="my-2" />
      <div class="text-center">
        <button @click.prevent="handleSaveData" class="btn btn-submit ripple mb-0">Save</button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Meals",
  computed: {
    ...mapGetters(["pkgOptions", "packageMeals", "availableHotels"]),
    selectOptionMeals: {
      get: function () {
        if (this.packageMeals.length > 0) {
          /* if (!this.pkgOptions.isSingleHotel) { */
          const exists = this.packageMeals.find((data) => data.type == this.selectedOptionType);
          if (!this._.isEmpty(exists)) {
            return exists.meals;
          } else {
            this.handleOptionItems();
            return this.packageMeals.find((data) => data.type == this.selectedOptionType).meals;
          }
          /*  } else {
            return this.mealsData;
          } */
        } else {
          return {};
        }
      },
      set: function (value) {
        this.packageMeals[this.selectedOptionType] = value;
      }
    }
  },
  data() {
    return {
      selectedOptionType: "",
      mealsData: {
        breakfast: false,
        breakfast_amount: "",
        lunch: false,
        lunch_amount: "",
        dinner: false,
        dinner_amount: ""
      }
    };
  },
  watch: {
    "selectOptionMeals.breakfast": function (value) {
      if (!value) this.selectOptionMeals.breakfast_amount = "";
    },
    "selectOptionMeals.lunch": function (value) {
      if (!value) this.selectOptionMeals.lunch_amount = "";
    },
    "selectOptionMeals.dinner": function (value) {
      if (!value) this.selectOptionMeals.dinner_amount = "";
    }
  },
  created() {
    this.handleOptionItems();
  },
  methods: {
    ...mapActions(["updatePackage"]),
    handleSaveData() {
      let newOptions = JSON.parse(JSON.stringify(this.pkgOptions));
      for (const data of newOptions.items) {
        const availableOpt = this.packageMeals.findIndex((val) => val.type === data.type);
        if (availableOpt != -1) {
          data.meals = this.packageMeals[availableOpt].meals;
          //data.hotels = this.handleHotelData(JSON.stringify(data.hotels));
        }
      }
      newOptions = {
        ...newOptions,
        items: newOptions.items.filter((data) => !data.isNew)
      };
      /* if (this.pkgOptions.isSingleHotel) {
        newOptions = {
          ...newOptions,
          items: newOptions.items.map(data => ({
            ...data,
            meals: this.mealsData
          }))
        };
      } */
      this.updatePackage({ type: "ADVANCE", data: { advance: { code: this.$route.query.code, data: { options: newOptions } } } }).then(() => {
        this.$toasted.success("Meals Data Is Updated", {
          position: "bottom-center",
          duration: 5000
        });
      });
    },
    /*  handleHotelData(hotels) {
      let newListItem = JSON.parse(hotels);
      if (hotels) {
        for (let hotel of newListItem) {
          if (hotel.item) {
            hotel.item = hotel.item.map(data => ({
              ...data,
              name: data.name && data.name?._id ? data?.name._id : data.name
            }));
          }
        }
      }
      return newListItem;
    }, */
    handleOptionItems() {
      if (this.packageMeals.length > 0) {
        this.selectedOptionType = this._.head(this.packageMeals).type;
        this.mealsData = this._.head(this.packageMeals).meals;
      }
    },
    availableOptions(opt) {
      return opt.filter((val) => !val.isNew);
    }
  }
};
</script>
<style scoped>
.meals_container {
  display: flex;
  justify-content: center;
}
</style>
