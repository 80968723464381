<template>
  <div>
    <div class="row gutter14">
      <div class="col-md-9">
        <div class="pkg-card pb-0">
          <div class="row gutter14">
            <div class="col-7">
              <div class="form-group" :class="{ invalid: $v.packages.name.$error }">
                <label class="form-para mb-2 required">Package Name</label>
                <input id="packagenamefeild" type="text" class="form-control" @blur="$v.packages.name.$touch()" v-model.trim="$v.packages.name.$model" />
                <span class="text-muted" v-if="$v.packages.name.$error && !$v.packages.name.required">Package Name is Required</span>
              </div>
            </div>
            <div class="col-2">
              <div class="form-group" :class="{ invalid: $v.packages.code.$error }">
                <label class="form-para mb-2 required">Package Code</label>
                <input v-if="!updateMode" :disabled="updateMode" v-model.trim="code" @blur="$v.packages.code.$touch()" type="text" id="code" class="form-control" />
                <span v-else style="background-color: #e9ecef" class="form-control">{{ code }}</span>
                <span class="text-muted" v-if="$v.packages.code.$error && !$v.packages.code.required">Required</span>
                <span class="text-muted" v-if="!updateMode && $v.packages.code.$error && !$v.packages.code.isUnique">Package Is Already Exists</span>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group" :class="{ invalid: $v.packages.total_nights.$error }">
                <label class="form-para mb-2 required">
                  Total Nights
                  <span class="font-normal">(Duration)</span>
                </label>
                <input v-model.number="packages.total_nights" min="0" @blur="$v.packages.total_nights.$touch()" type="number" class="form-control" />

                <span class="text-muted" v-if="$v.packages.total_nights.$error && !$v.packages.total_nights.greaterThenZero">Must be greater then zero</span>
              </div>
            </div>
            <div class="col-12">
              <div v-if="packages.booking_amount" class="row gutter14">
                <div class="col-3 form-group" :class="{ invalid: $v.packages.booking_amount.adult.$error }">
                  <label class="form-para required">Adult Booking Amount</label>
                  <input @blur="$v.packages.booking_amount.adult.$touch()" v-model.number="packages.booking_amount.adult" min="0" type="number" class="form-control" />
                  <span class="text-muted" v-if="$v.packages.booking_amount.adult.$error && !$v.packages.booking_amount.adult.required">Adult Booking Amount Is Required</span>
                  <span class="text-muted" v-if="$v.packages.booking_amount.adult.$error && $v.packages.booking_amount.adult.required && !$v.packages.booking_amount.adult.greaterThenZero"
                    >Amount Must be greater then zero</span
                  >
                </div>
                <div class="col-3">
                  <p class="form-para">CWB Booking Amount</p>
                  <input v-model.number="packages.booking_amount.cwb" min="0" type="number" class="form-control" />
                </div>
                <div class="col-3">
                  <p class="form-para">CNB Booking Amount</p>
                  <input v-model.number="packages.booking_amount.cnb" min="0" type="number" class="form-control" />
                </div>
                <div class="col-3">
                  <p class="form-para">Infant Booking Amount</p>
                  <input v-model.number="packages.booking_amount.infant" min="0" type="number" class="form-control" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pkg-card">
          <div class="row gutter14 mb-1">
            <div v-if="!updateMode" class="col-md-12">
              <div class="form-group">
                <label class="radio">
                  <input v-model="isorigin" value="origin" name="pkg-origin" type="radio" />
                  <span>Package Origin</span>
                </label>
                <label class="radio">
                  <input v-model="isorigin" value="" name="pkg-origin" type="radio" />
                  <span>Land Package</span>
                </label>
              </div>
            </div>
            <div v-if="isorigin == 'origin' && packages.pkg_origin != null" class="col-md-12">
              <div class="form-group" :class="{ invalid: isOriginValid }">
                <label class="form-para mb-2 required">Origin City/Country</label>
                <!-- Origin City Selection -->
                <div v-if="!updateMode">
                  <destSelectorVue
                    :selectInput="true"
                    v-on:changeValue="changeoriginValue"
                    :selectedData="selectedOrigin"
                    v-on:onSelectItem="(data) => onSelected('origin', data)"
                    :asyncData="getCityListByName"
                    placeholder="Enter Origin Name"
                  />
                </div>
                <div v-else-if="updateMode && packageDetails.pkg_origin">
                  <input type="text" class="form-control" disabled :value="packageDetails.pkg_origin.destinationName" />
                </div>
                <span class="text-muted" v-if="isOriginValid">origin is valid</span>
              </div>
            </div>

            <div class="col-md-6" :class="{ 'col-12': !updateMode }">
              <div class="form-group" :class="{ invalid: isDestinationValid }">
                <label class="form-para mb-2 required">Package Destinations</label>
                <!-- Destination AutoSuggest -->
                <destSelectorVue v-on:onSelectItem="(data) => onSelected('dest', data)" :asyncData="getCityListByName" placeholder="Enter Destination Name" />
                <span class="text-muted" v-if="packages.pkg_destinations && packages.pkg_destinations.length >= 0 && isDestinationValid">origin is valid</span>
              </div>
              <!-- Destinations Chips Items -->
              <div class="chips">
                <span v-for="(item, index) in selectDest" :key="item.id" class="dest_chips d-inline-flex align-items-center">
                  {{ item.name || item.destinationName }}
                  <small @click.prevent="removeDestinations(index)">&times;</small>
                </span>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group" :class="{ invalid: $v.packages.destinations_covered.$error }">
                <label class="form-para mb-2 required">
                  Destinations Covered
                  <small>(STAY PLAN)</small>
                </label>
                <input
                  v-model.trim="packages.destinations_covered"
                  @blur="$v.packages.destinations_covered.$touch()"
                  type="text"
                  class="form-control"
                  placeholder="eg: 1N Singapore, 2N Malaysia, 2N Cruise"
                />
                <span class="text-muted" v-if="$v.packages.destinations_covered.$error && !$v.packages.destinations_covered.required">destinations covered is required</span>
              </div>
            </div>
          </div>

          <div class="form-group vue-editor m-0" :class="{ invalid: $v.packages.overview_long.$error }">
            <label class="form-para mb-2 required">Package Overview</label>
            <vue-editor :editor-toolbar="toolbarSettings" @blur="$v.packages.overview_long.$touch()" v-model.trim="packages.overview_long" />
            <span class="text-muted" v-if="$v.packages.overview_long.$error && !$v.packages.overview_long.required">package overview is required</span>
          </div>
        </div>

        <div class="pkg-card">
          <div class="row gutter14 justify-content-between">
            <div class="col-md-5" v-for="item in $v.packages.ageBands.$each.$iter" :key="item.type.$model">
              <div class="row gutter14">
                <div class="col-4">
                  <label class="form-para">{{ _.upperCase(item.type.$model) }} Age Band</label>
                </div>
                <div class="col-4 form-group d-inline-flex align-items-center" :class="{ invalid: item.ageFrom.$error }">
                  <label class="form-para font-normal labelWidth">From</label>
                  <select class="form-control form-control-sm" v-model="item.ageFrom.$model" @blur="item.ageFrom.$touch()">
                    <option :value="item" v-for="item in (3, 15)" :key="item">{{ item }}</option>
                  </select>
                  <span class="text-muted" v-if="item.ageFrom.$error && !item.ageFrom.required">Age From Is Required</span>
                </div>
                <div class="col-4 form-group d-inline-flex align-items-center" :class="{ invalid: item.ageTo.$error }">
                  <label class="form-para font-normal labelWidth">To</label>
                  <select class="form-control form-control-sm" v-model="item.ageTo.$model" @blur="item.ageTo.$touch()">
                    <option :value="item" v-for="item in (3, 15)" :key="item">{{ item }}</option>
                  </select>
                  <span class="text-muted" v-if="item.ageTo.$error && !item.ageTo.required">Age To Is Required</span>
                </div>
              </div>
            </div>
          </div>

          <p class="text-center m-0">
            <button :disabled="$v.$invalid" @click.prevent="onSubmitSave('continue')" href="javascript:void(0)" class="btn btn-submit ripple">
              Save & Continue
              <span class="font-bold">➝</span>
            </button>
            <button :disabled="$v.$invalid" @click.prevent="onSubmitSave('save')" href="javascript:void(0)" class="btn btn-submit ripple">Save & Exit</button>
            <button @click.prevent="$router.push({ name: 'packagelisting' })" href="javascript:void(0)" class="btn btn-submit btn-cancel ripple">Cancel</button>
          </p>
        </div>
      </div>

      <div class="col-md-3">
        <div class="position-sticky pb-0">
          <div class="pkg-card" style="top: 0">
            <div class="row gutter14">
              <div class="col-12">
                <div class="row">
                  <div class="col-12">
                    <p class="form-para mb-2">Status</p>
                    <div class="d-flex flex-wrap mb-2">
                      <div class="rc-group">
                        <label for="active" class="rc-control rc-control-checkbox mb-1">
                          <input id="active" v-model="packages.active" type="checkbox" />
                          <div class="rc-control-indicator"></div>
                          <span id="spnagr">Is Active</span>
                        </label>
                      </div>
                      <div class="rc-group">
                        <label for="ims_pricing" class="rc-control rc-control-checkbox mb-1">
                          <input id="ims_pricing" v-model="packages.ims_pricing" type="checkbox" />
                          <div class="rc-control-indicator"></div>
                          <span id="spnagr">Ims Pricing</span>
                        </label>
                      </div>
                      <div class="rc-group">
                        <label for="for_b2c" class="rc-control rc-control-checkbox mb-1">
                          <input id="for_b2c" v-model="packages.is_b2c" type="checkbox" />
                          <div class="rc-control-indicator"></div>
                          <span id="spnagr">B2c</span>
                        </label>
                      </div>
                      <div class="rc-group">
                        <label for="for_b2b" class="rc-control rc-control-checkbox mb-1">
                          <input id="for_b2b" v-model="packages.is_b2b" type="checkbox" />
                          <div class="rc-control-indicator"></div>
                          <span id="spnagr">B2b</span>
                        </label>
                      </div>
                      <div class="rc-group">
                        <label for="for_wl" class="rc-control rc-control-checkbox mb-1">
                          <input id="for_wl" v-model="packages.is_wl" type="checkbox" />
                          <div class="rc-control-indicator"></div>
                          <span id="spnagr">Wl</span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <!-- <div v-if="packages.ims_pricing" class="col-12">
                  <div class="row no-gutters">
                    <div class="col-6">
                      <div class="form-group mb-0 mr-3">
                        <label class="form-para mb-2 required">Ims Airlines</label>
                        <select v-model="packages.ims_airline" class="form-control form-control-sm">
                          <option value="">All</option>
                          <option v-for="_airline in ims_airlines" :key="_airline._id" :value="_airline.code">{{ _airline.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-0">
                        <label class="form-para mb-2 required">Markup / Discount</label>
                        <input v-model.trim.number="packages.ims_markup_discount" type="text" class="form-control form-control-sm" placeholder="Ims Markup" />
                      </div>
                    </div>
                  </div>
                </div> -->

                  <div class="col-12">
                    <div class="rc-group mt-2">
                      <select v-model="packages.pkg_type" class="form-control">
                        <option value="available">Available</option>
                        <option value="sold">Sold Out</option>
                        <option value="request">On Request</option>
                        <option value="static">Static</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="form-group" :class="{ invalid: $v.packages.slug.$error }">
                  <label class="form-para mb-2 required">Slug</label>
                  <input v-model.trim="packages.slug" @blur="$v.packages.slug.$touch()" type="text" class="form-control" placeholder="enter slug name" />
                  <span class="text-muted" v-if="$v.packages.slug.$error && !$v.packages.slug.required">Slug is required</span>
                </div>
              </div>

              <div class="col-12">
                <div class="form-group">
                  <label class="form-para mb-2">Redirect Url</label>
                  <input v-model.trim="packages.redirect_url" type="text" class="form-control" placeholder="Enter Redirect Url" />
                </div>
              </div>

              <div class="col-12">
                <div class="form-group" :class="{ invalid: $v.packages.booking_window.$error }">
                  <label class="form-para mb-2 required">Booking Window</label>
                  <input v-model.number="packages.booking_window" type="number" @blur="$v.packages.booking_window.$touch()" min="0" class="form-control" placeholder="Number Of Days" />
                  <span class="text-muted" v-if="$v.packages.booking_window.$error && !$v.packages.booking_window.greaterThenZero">booking window must be grtr then 0</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="updateMode" class="pkg-card file_upload mt-3">
            <div class="title">File Upload</div>
            <hr class="my-2" />
            <div class="content">
              <input :disabled="selected_file.name != ''" id="image_choose" accept="application/pdf" @change.prevent="onSelectImage" type="file" class="d-none image_input" />
              <div class="image_choose">
                <label for="image_choose" class="mb-0">
                  <svg height="35px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 56 56" xml:space="preserve" fill="#000000">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                      <g>
                        <path
                          style="fill: #e9e9e0"
                          d="M36.985,0H7.963C7.155,0,6.5,0.655,6.5,1.926V55c0,0.345,0.655,1,1.463,1h40.074 c0.808,0,1.463-0.655,1.463-1V12.978c0-0.696-0.093-0.92-0.257-1.085L37.607,0.257C37.442,0.093,37.218,0,36.985,0z"
                        ></path>
                        <polygon style="fill: #d9d7ca" points="37.5,0.151 37.5,12 49.349,12 "></polygon>
                        <path
                          style="fill: #cc4b4c"
                          d="M19.514,33.324L19.514,33.324c-0.348,0-0.682-0.113-0.967-0.326 c-1.041-0.781-1.181-1.65-1.115-2.242c0.182-1.628,2.195-3.332,5.985-5.068c1.504-3.296,2.935-7.357,3.788-10.75 c-0.998-2.172-1.968-4.99-1.261-6.643c0.248-0.579,0.557-1.023,1.134-1.215c0.228-0.076,0.804-0.172,1.016-0.172 c0.504,0,0.947,0.649,1.261,1.049c0.295,0.376,0.964,1.173-0.373,6.802c1.348,2.784,3.258,5.62,5.088,7.562 c1.311-0.237,2.439-0.358,3.358-0.358c1.566,0,2.515,0.365,2.902,1.117c0.32,0.622,0.189,1.349-0.39,2.16 c-0.557,0.779-1.325,1.191-2.22,1.191c-1.216,0-2.632-0.768-4.211-2.285c-2.837,0.593-6.15,1.651-8.828,2.822 c-0.836,1.774-1.637,3.203-2.383,4.251C21.273,32.654,20.389,33.324,19.514,33.324z M22.176,28.198 c-2.137,1.201-3.008,2.188-3.071,2.744c-0.01,0.092-0.037,0.334,0.431,0.692C19.685,31.587,20.555,31.19,22.176,28.198z M35.813,23.756c0.815,0.627,1.014,0.944,1.547,0.944c0.234,0,0.901-0.01,1.21-0.441c0.149-0.209,0.207-0.343,0.23-0.415 c-0.123-0.065-0.286-0.197-1.175-0.197C37.12,23.648,36.485,23.67,35.813,23.756z M28.343,17.174 c-0.715,2.474-1.659,5.145-2.674,7.564c2.09-0.811,4.362-1.519,6.496-2.02C30.815,21.15,29.466,19.192,28.343,17.174z M27.736,8.712c-0.098,0.033-1.33,1.757,0.096,3.216C28.781,9.813,27.779,8.698,27.736,8.712z"
                        ></path>
                        <path style="fill: #cc4b4c" d="M48.037,56H7.963C7.155,56,6.5,55.345,6.5,54.537V39h43v15.537C49.5,55.345,48.845,56,48.037,56z"></path>
                        <g>
                          <path
                            style="fill: #ffffff"
                            d="M17.385,53h-1.641V42.924h2.898c0.428,0,0.852,0.068,1.271,0.205 c0.419,0.137,0.795,0.342,1.128,0.615c0.333,0.273,0.602,0.604,0.807,0.991s0.308,0.822,0.308,1.306 c0,0.511-0.087,0.973-0.26,1.388c-0.173,0.415-0.415,0.764-0.725,1.046c-0.31,0.282-0.684,0.501-1.121,0.656 s-0.921,0.232-1.449,0.232h-1.217V53z M17.385,44.168v3.992h1.504c0.2,0,0.398-0.034,0.595-0.103 c0.196-0.068,0.376-0.18,0.54-0.335c0.164-0.155,0.296-0.371,0.396-0.649c0.1-0.278,0.15-0.622,0.15-1.032 c0-0.164-0.023-0.354-0.068-0.567c-0.046-0.214-0.139-0.419-0.28-0.615c-0.142-0.196-0.34-0.36-0.595-0.492 c-0.255-0.132-0.593-0.198-1.012-0.198H17.385z"
                          ></path>
                          <path
                            style="fill: #ffffff"
                            d="M32.219,47.682c0,0.829-0.089,1.538-0.267,2.126s-0.403,1.08-0.677,1.477s-0.581,0.709-0.923,0.937 s-0.672,0.398-0.991,0.513c-0.319,0.114-0.611,0.187-0.875,0.219C28.222,52.984,28.026,53,27.898,53h-3.814V42.924h3.035 c0.848,0,1.593,0.135,2.235,0.403s1.176,0.627,1.6,1.073s0.74,0.955,0.95,1.524C32.114,46.494,32.219,47.08,32.219,47.682z M27.352,51.797c1.112,0,1.914-0.355,2.406-1.066s0.738-1.741,0.738-3.09c0-0.419-0.05-0.834-0.15-1.244 c-0.101-0.41-0.294-0.781-0.581-1.114s-0.677-0.602-1.169-0.807s-1.13-0.308-1.914-0.308h-0.957v7.629H27.352z"
                          ></path>
                          <path style="fill: #ffffff" d="M36.266,44.168v3.172h4.211v1.121h-4.211V53h-1.668V42.924H40.9v1.244H36.266z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </label>
                <div class="d-flex align-items-center">
                  <div class="title" v-if="is_image_uploading">Uploading...</div>
                  <div v-else-if="!is_image_uploading" :class="{ title: !selected_file.name }">
                    <span v-if="is_image_uploading"> Uploading...</span>
                    <a target="_blank" class="title selected" v-else-if="selected_file.name" :href="file_url">{{ selected_file.name }}</a>
                    <span v-else>Select File</span>
                  </div>
                  <span v-if="selected_file.name && !is_image_uploading" @click.prevent="delete_content = selected_file" class="clr-red ml-2 close_icon">&times;</span>
                </div>
              </div>
              <hr v-if="progress != 0" class="my-2" />
              <div v-if="progress != 0" class="file_uploader mb-2">
                <div class="loading_bar" :style="{ width: `${progress}%` }"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <importantVue /> -->
    </div>
    <popup-model class="document_model" :openModel="file_content" :closeBtn="false">
      <div slot="content">
        <div v-if="file_content">
          <embed style="width: 100%; height: 580px" :src="file_content" />
        </div>
        <hr class="my-2" />
        <div class="d-flex justify-content-center">
          <button @click.prevent="closeImageView" class="btn btn-danger">Close</button>
        </div>
      </div>
    </popup-model>
    <popup-model class="delete_model" :openModel="delete_content != null" :closeBtn="false">
      <div slot="content">
        <div class="delete_title">Are you want to delete ?</div>
        <hr class="my-2" />
        <div class="d-flex justify-content-center">
          <button @click.prevent="delete_content = null" class="btn btn-danger mr-2">Close</button>
          <button @click.prevent="removeImage" class="btn btn-info">Submit</button>
        </div>
      </div>
    </popup-model>
  </div>
</template>

<script>
import { mapActions } from "vuex";
//import importantVue from "./important.vue";
import destSelectorVue from "./dest-selector.vue";
import { required, numeric } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
const greaterThenZero = (value) => value >= 0;
import { head } from "lodash";
export default {
  name: "packagename",
  components: {
    //importantVue,
    destSelectorVue
  },
  props: {
    updateMode: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters(["packageDetails"]),
    code: {
      get: function () {
        return this.packages.code;
      },
      set: function (value) {
        this.packages.code = value.toUpperCase();
      }
    },
    checkOriginInput() {
      if (document.querySelector("#EnterOriginName")) {
        return (document.querySelector("#EnterOriginName").value == "" && (this.isorigin === "origin" || this.packages.pkg_origin == null)) || this.packages.pkg_origin == "";
      }
      return null;
    },
    file_url() {
      return "https://services.dpauls.com/mediaserver/" + this.selected_file.path + "/" + this.selected_file.name;
    }
  },
  data() {
    return {
      ims_airlines: [],
      value: "packagename",
      isorigin: "origin",
      selectDest: [],
      selectedOrigin: {},
      isOriginValid: false,
      progress: 0,
      is_image_uploading: false,
      isDestinationValid: false,
      file_content: null,
      delete_content: null,
      uploading_file_percentage: 0,
      selected_file: { name: "", path: "" },
      packages: {
        name: "",
        code: "",
        total_nights: 0,
        booking_amount: {
          adult: 0,
          cnb: 0,
          cwb: 0,
          infant: 0
        },
        pkg_origin: "",
        redirect_url: "",
        pkg_destinations: [],
        destinations_covered: "",
        overview_long: "",
        active: true,
        ims_pricing: true,
        is_b2c: true,
        is_b2b: true,
        is_wl: true,
        ims_airline: "",
        ims_markup_discount: 0,
        slug: "",
        pkg_type: "available",
        file: { name: "", path: "" },
        booking_window: 7,
        ageBands: [
          {
            type: "CWB",
            ageFrom: 7,
            ageTo: 11
          },
          {
            type: "CNB",
            ageFrom: 3,
            ageTo: 6
          }
        ]
      },
      toolbarSettings: [
        ["bold", "italic", "underline", "strike"],
        [{ script: "sub" }, { script: "super" }],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        ["link", "clean"]
      ]
    };
  },
  async mounted() {
    const packagename = document.querySelector("#packagenamefeild");
    if (packagename) {
      packagename.focus();
    }
    this.ims_airlines = await this.fetchImsAirlines();
  },
  validations: {
    packages: {
      name: { required },
      code: {
        required,
        isUnique: async function (val) {
          if (!this.updateMode) {
            if (val === "") return true;
            return await this.getPackageByCode(val).then((data) => {
              return !data.code;
            });
          } else {
            return true;
          }
        }
      },
      booking_amount: {
        adult: { required, numeric, greaterThenZero }
      },
      total_nights: { required, greaterThenZero, numeric },
      destinations_covered: { required },
      overview_long: { required },
      slug: { required },
      ageBands: {
        $each: {
          type: {},
          ageFrom: { required },
          ageTo: { required }
        }
      },
      booking_window: { required, greaterThenZero }
    }
  },
  watch: {
    "packages.slug": function (value) {
      if (value && value != "") {
        this.packages.slug = this._.kebabCase(value.toLowerCase());
      }
    },
    isorigin() {
      this.packages.pkg_origin = "";
    },
    updateMode() {
      for (let item in this.packages) {
        if (item == "file") {
          this.selected_file = this.packageDetails.file;
        }
        if (item == "pkg_destinations" && this.packageDetails.pkg_destinations) {
          this.selectDest = this.packageDetails.pkg_destinations;
        } else if (item == "pkg_origin" && this.packageDetails.pkg_origin) {
          this.packages.pkg_origin = this.packageDetails.pkg_origin.id;
          this.selectedOrigin = {
            ...this.packageDetails.pkg_origin,
            name: this.packageDetails.pkg_origin.destinationName
          };
        } else if (item !== "ageBands") {
          this.packages[item] = this.packageDetails[item];
        }
        if (this.packageDetails.pkg_origin == null) {
          this.isorigin = "";
        }
        if (this.packageDetails.booking_amount) {
          delete this.packageDetails.booking_amount.__typename;
        }
        this.packages.booking_amount = this.packageDetails.booking_amount;
        if (this.packageDetails.ageBands) {
          this.packages.ageBands = this.packageDetails.ageBands.length > 0 ? this.packageDetails.ageBands : this.packages.ageBands;
        }
      }
    },
    "packages.pkg_origin": function (New) {
      if (New !== "") {
        this.isOriginValid = false;
      } else {
        this.isOriginValid = true;
      }
    },
    selectDest: function (New) {
      if (New.length <= 0) {
        this.isDestinationValid = true;
      } else {
        this.isDestinationValid = false;
      }
    }
  },
  created() {
    window.addEventListener("beforeunload", this.preventNavigation);
  },
  destroyed() {
    window.removeEventListener("beforeunload", this.preventNavigation);
  },
  methods: {
    ...mapActions(["getCityListByName", "createPackage", "getPackageByCode", "getPackageByCode", "fetchImsAirlines", "uploadPackageFile", "updatePackage"]),
    /* Auto Suggest Methods */
    preventNavigation(event) {
      if (this.is_image_uploading) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    getConvertedImage(_file) {
      return new Promise(async (resolve) => {
        var reader = new FileReader();
        reader.onload = (() => {
          return async ({ currentTarget }) => {
            const _image = currentTarget?.result?.replace(`data:${_file?.type};base64,`, "") || "";
            resolve(_image);
          };
        })(_file);
        reader.readAsDataURL(_file);
      });
    },
    async onSelectImage(event) {
      this.progress = 0;
      const _file = head(event?.currentTarget?.files);
      const _image_data = await this.getConvertedImage(_file);
      const _is_file_new = this.packages?.file?.name == "";
      const _file_name = _file?.name?.replace(/\s/g, "-")?.toLowerCase();
      this.selected_file.name = _file_name;
      this.selected_file.path = _image_data;
      let _uploaded_image = null;
      this.is_image_uploading = true;
      for (let i = 0; i < _image_data?.length; i += 40000) {
        const chunk = _image_data?.substring(i, i + 40000);
        await this.uploadPackageFile(JSON.parse(JSON.stringify({ name: this?.selected_file?.name, data: chunk, code: this.packageDetails.code, is_new: _is_file_new }))).then((data) => {
          _uploaded_image = data;
          this.progress += 100 / (_image_data.length / 40000);
        });
      }
      this.selected_file = _uploaded_image;
      this.is_image_uploading = false;
      this.progress = 0;
      this.updatePackage({
        type: "DETAILS",
        data: { details: { code: this?.packageDetails?.code, data: { file: _uploaded_image } } }
      }).then(() => {
        this.$toasted.success(_uploaded_image?.success ? "File uploaded successfully" : "something went wrong", { position: "bottom-center", duration: 3000 });
      });
      //this.document.querySelector(".image_input").value = "";
    },
    removeImage() {
      this.uploadPackageFile({ code: this.packageDetails.code, type: "remove" }).then((data) => {
        this.packages.file = { name: "", path: "" };
        this.selected_file = { name: "", path: "" };
        this.delete_content = null;
        this.updatePackage({
          type: "DETAILS",
          data: { details: { code: this?.packageDetails?.code, data: { file: { name: "", path: "" } } } }
        }).then(() => {
          this.$toasted.success("File removed successfully", { position: "bottom-center", duration: 3000 });
        });
      });
    },
    closeImageView() {
      this.file_content = null;
    },
    onSelected(type, value) {
      if (type == "origin") {
        this.selectedOrigin = value;
        this.packages.pkg_origin = value.id;
      } else {
        if (this.selectDest.findIndex((data) => data.id == value.id) == -1) {
          this.selectDest.push(value);
        } else {
          this.$toasted.info("item is already selected", { position: "bottom-center", duration: 3000 });
        }
      }
    },
    removeDestinations(index) {
      this.selectDest.splice(index, 1);
    },
    onSubmitSave(type) {
      const thiss = this;
      let newDest = [];
      for (const item of this.selectDest) {
        newDest.push(item.id);
      }
      this.packages.ageBands.filter((val) => delete val.__typename);
      this.packages.pkg_destinations = newDest;
      if (this.checkOriginInput) {
        this.isOriginValid = true;
      } else if (this.selectDest.length <= 0) {
        this.isDestinationValid = true;
      } else {
        this.packages.pkg_origin = this.selectedOrigin.id ? this.packages.pkg_origin : null;
        if (this.updateMode) {
          this.$store
            .dispatch("updatePackage", {
              type: "BASIC",
              data: { basic: this.packages }
            })
            .then((data) => {
              if (type == "continue") {
                thiss.$emit("continueBtn", 1);
              } else {
                thiss.$toasted.success(`${data.name} (${data.code}) is Updated successfully`, {
                  position: "bottom-center",
                  duration: 5000
                });
                this.$router.push({ name: "packagelisting" });
              }
              this.getPackageByCode(data.code);
            })
            .catch(() => {
              thiss.$toasted.error(`something went wrong...`, {
                position: "bottom-center",
                duration: 5000
              });
            });
        } else if (!this.updateMode && type == "continue" && thiss.packages.code != "") {
          this.getPackageByCode(thiss.packages.code)
            .then((data) => {
              if (data.code) {
                thiss.$emit("continueBtn", 1);
                this.$router
                  .push({
                    name: "createpackage",
                    query: { code: thiss.packages.code }
                  })
                  .then((data) => {
                    thiss.packages = data;
                  });
              } else {
                this.createNewPackage();
              }
            })
            .catch((err) => {
              const newData = JSON.parse(err.graphQLErrors[0].message);
              if (newData && newData.code == 404) {
                this.createNewPackage();
              }
            });
        } else if (!this.updateMode && type == "save" && thiss.packages.code != "") {
          this.$store
            .dispatch("createPackage", this.packages)
            .then((data) => {
              this.$router.push({ name: "packagelisting" });
              thiss.$toasted.success(`${data.name} (${data.code}) is created successfully`, {
                position: "bottom-center",
                duration: 2000
              });
            })
            .catch((err) => {
              thiss.$toasted.error(`${err}`, {
                position: "bottom-center",
                duration: 2000
              });
            });
        }
      }
    },
    createNewPackage() {
      this.$store
        .dispatch("createPackage", this.packages)
        .then((data) => {
          this.$emit("continueBtn", 1);
          this.packages = data;
          this.$router.push({
            name: "createpackage",
            query: { code: data.code }
          });

          this.$toasted.success(`${data.name} (${data.code}) is created successfully`, {
            position: "bottom-center",
            duration: 2000
          });
        })
        .catch((err) => {
          this.$toasted.error(`${err}`, {
            position: "bottom-center",
            duration: 2000
          });
        });
    },
    checkBlackData() {
      for (var key in this.packages) {
        if (this.isorigin == "" && key == "pkg_origin") {
          return false;
        } else {
          if (this.packages[key].length == 0) return key;
        }
      }
      return false;
    },
    changeoriginValue() {
      this.packages.pkg_origin = "";
    }
  }
};
</script>

<style scoped>
.labelWidth {
  margin: 0;
  width: 30%;
}
.form-para {
  font-size: 12px;
}
/* .form-group {
  margin: 0 0 10px;
} */

.form-control,
.form-para {
  margin: 0 0 5px;
}

.chips {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
}

.dest_chips {
  border-radius: 50px;
  padding: 1px 10px;
  background: transparent;
  border: 1px solid #17a2b8;
  color: #17a2b8;
  cursor: pointer;
  font-size: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.dest_chips:last-child {
  margin-right: 0;
}

.dest_chips > small {
  font-size: 16px;
  text-align: right;
  position: relative;
  right: -4px;
  cursor: pointer;
  margin-top: -3px;
}

.dest_chips > small:hover {
  color: #f56d6d;
}

.rc-control {
  padding-left: 0.75rem;
  margin-right: 1rem;
}

.rc-control-indicator {
  top: 2px;
}

.rc-control > input[type="checkbox"] {
  display: none;
}
.btn-submit:disabled {
  cursor: not-allowed;
}
.font-normal {
  font-weight: normal;
}

.vue-editor >>> .ql-editor {
  min-height: 150px;
}

.pkg-card.file_upload {
  padding: 5px 10px;
}

.pkg-card.file_upload > .title {
  font-size: 14px;
  font-weight: 600;
}

.pkg-card.file_upload .content .image_choose {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  user-select: none;
}

.pkg-card.file_upload .content .title,
.image_link {
  margin-left: 8px;
  font-size: 12px;
  font-weight: 600;
  cursor: normal;
}

.pkg-card.file_upload .content .title.selected {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  color: #39c9bb;
  text-decoration: underline;
}
.pkg-card.file_upload .content .close_icon {
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
}

.pkg-card.file_upload .content .image_choose svg {
  cursor: pointer;
}

.file_uploader {
  height: 10px;
  width: 100%;
  background-color: #bdbdbd;
}

.file_uploader .loading_bar {
  position: relative;
  background-color: #39c9bb;
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.document_model >>> .popup_body {
  max-width: 1200px;
  width: 1200px;
  height: 640px;
  max-height: 640px;
}

.delete_model >>> .popup_body {
  max-width: 250px;
  width: 250px;
  padding: 0;
  border-radius: 0;
}

.delete_title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  margin-top: 5px;
}

.delete_model >>> button {
  width: 70px;
  height: 25px;
  line-height: 5px;
  font-size: 12px;
  margin-bottom: 10px;
  border-radius: 0;
}
</style>
