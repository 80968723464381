<template>
  <container headTitle="Manage Users">
    <div slot="createbtn">
      <menu-item pageUrl="/users/create">
        <router-link class="btn btn-sm btn-info float-right" to="/users/create"
          >Add New User</router-link
        >
      </menu-item>
    </div>
    <div slot="content">
      <table class="table">
        <thead class="table-teal">
          <tr>
            <th>Sr. No.</th>
            <th><i class="icons sort point"></i> Name</th>
            <th>User Email</th>
            <th>User Name</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody id="alluser" v-if="users.length > 0">
          <tr v-for="(user, index) in users" :key="user.id">
            <td>{{ index + 1 }}</td>
            <td>{{ user.name }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.username }}</td>
            <td>
              <div class="checkbox">
                <input
                  disabled
                  readonly
                  type="checkbox"
                  :checked="user.isActive"
                />
              </div>
            </td>
            <td>
              <a class="btn btn-sm list-btn" @click="viewUser(user.username)"
                >view</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "users",
  created() {
    this.handleGetUsers();
  },
  computed: {
    ...mapGetters(["users"])
  },
  methods: {
    handleGetUsers() {
      // reach out to vuex store and fire action that gets users.
      this.$store.dispatch("getSupplierUsers");
    },
    viewUser(user) {
      this.$router.push({ path: "/users/create", query: { user } });
    }
  }
};
</script>
