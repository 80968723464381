export default {
  methods: {
    showImage() {
      const viewer = this.$el.querySelector(".selected-images").$viewer;
      viewer.show();
    },
    getHtmlContent(content) {
      let divElem = document.createElement("div");
      divElem.innerHTML = content;
      let inputText = divElem.textContent || divElem.innerText || "";
      return inputText.trim();
    },
    /* Add Inclusions And Exclusions */
    addPkgIncAndExlu(type) {
      const findIndex = this.packageData[type].findIndex(val => {
        const availText = this.getHtmlContent(this[type + "Text"]);
        const newText = this.getHtmlContent(val);
        return availText == newText;
      });
      if (this.getHtmlContent(this[type + "Text"]) !== "" && findIndex == -1) {
        this.packageData[type].push(this[type + "Text"]);
        this[type + "Text"] = "";
      }
    },
    /* Remove Inclusions And Exclusions */
    removePkgIncAndExlu(type, index) {
      this.packageData[type].splice(index, 1);
    },
    /* Move Item In Todo Lists Up And Down */
    moveItemUpDown(array, side, index) {
      if (side == "up") {
        this.moveitems(array, +index, -1);
      } else {
        this.moveitems(array, +index, 1);
      }
    },
    moveOldToNew(array, oldIndex, newIndex) {
      if (newIndex >= array.length) {
        var k = newIndex - array.length + 1;
        while (k--) {
          array.push(undefined);
        }
      }
      array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
      return array;
    },
    /* Handle Items In Array To Move Positions */
    moveitems(array, index, delta) {
      var newIdx = index + delta;
      if (newIdx <= 0 || newIdx == array.length) return;
      var indexes = [index, newIdx].sort();
      const newData = this.moveOldToNew(array, indexes[1], indexes[0]);
      array = newData;
    },
    /* Manage Themes For Package */
    manageThemesAndService(type, value) {
      if (this.packageData[type].includes(value._id)) {
        const index = this.packageData[type].indexOf(value._id);
        if (type == "services_included") {
          const detailIndex = this.packageDetails[type].findIndex(
            data => data._id == value._id
          );
          this.packageDetails[type].splice(detailIndex, 1);
        }
        this.packageData[type].splice(index, 1);
      } else {
        if (type == "services_included") {
          const service = this.packageServices.find(
            data => data._id == value._id
          );
          delete service.__typename;
          this.packageDetails[type].push(service);
        }
        this.packageData[type].push(value._id);
      }
    },
    addItinerary() {
      this.packageData.itinerary.push({
        order: Math.random(),
        heading: "",
        details: ""
      });
    },
    manageTncAndVisaInfo(type, data) {
      if (this.packageData[type].others.includes(data)) {
        const _index = this.packageData[type].others.indexOf(data);
        this.packageData[type].others.splice(_index, 1);
      } else {
        this.packageData[type].others.push(data);
      }
    },
    /* Check if Visa Info Destination Is all ready Selected */
    checkVisaAndTncSelected(type, data) {
      return this.packageData[type].others.includes(data);
    },
    /* Update Package All Details */
    updatePkgDetails(type) {
      let data = this.packageData[type];
      if (type == "itinerary") {
        for (let i = 0; i < data.length; i++) {
          data[i].order = i + 1;
        }
        const newData = data.filter(
          val => val.heading != "" && this.getHtmlContent(val.details) != ""
        );
        data = newData;
        this.packageData[type] = newData;
      }
      if (typeof data == "string") {
        if (this.getHtmlContent(data) == "") {
          data = "";
        }
      }
      if (typeof data == "object" && !Array.isArray(data)) {
        if (this.getHtmlContent(data.text) == "") {
          data.text = "";
        }
      }
      this.updatePackage({
        type: "DETAILS",
        data: {
          details: { code: this.$route.query.code, data: { [type]: data } }
        }
      }).then(() => {
        if (type == "images") {
          this.isImageModelOpen = false;
        }
        this.$toasted.success(`${this.toastMessages[type].success}`, {
          position: "bottom-center",
          duration: 5000
        });
      });
    },
    handlePublishPackage() {
      this.makePackagePublishRequest({
        code: this.packageDetails.code,
        isPublishRequest: true
      }).then(data => {
        this.$router.push({ name: "packagelisting" });
        this.$toasted.success(
          `Package Code ${data.code} ${
            data.message == "publish" ? "Published" : "Request Send"
          }  Successfully`,
          {
            position: "bottom-center",
            duration: 5000
          }
        );
      });
    },
    manageAdvancePackage() {
      this.$router
        .push({
          query: { code: this.$route.query.code, tabName: "package-advance" }
        })
        .then(data => {
          this.$router.go();
          //this.$emit("continueBtn", 2);
        });
    },
    async handlePkgMedia(id) {
      const dest_ids = this.packageDetails.pkg_destinations.map(
        data => data.id
      );
      await this.getPackageMediaList({ dest_ids, type: "package" }).then(
        data => {
          if (!id) {
            this.selectedMediaTab = this._.head(data)
              ? this._.head(data).dest_id
              : "";
          } else {
            this.selectedMediaTab = id;
          }
          this.isImageModelOpen = true;
        }
      );
    },
    handleSelectedMedia(data, type) {
      const mediaIndex = this.packageData.images.findIndex(
        val => val.path == data.path
      );
      if (type == "details") {
        if (mediaIndex == -1) {
          delete data.__typename;
          this.packageData.images.push({ ...data, is_search: false });
        } else {
          this.packageData.images.splice(mediaIndex, 1);
        }
      } else {
        if (mediaIndex != -1) {
          this.packageData.images[mediaIndex].is_search = !this.packageData
            .images[mediaIndex].is_search;
        }
      }
    },
    checkImageAvailable(data, type) {
      if (type == "details") {
        return (
          this.packageData.images.findIndex(val => val.path == data.path) != -1
        );
      } else {
        return (
          this.packageData.images.findIndex(
            val => val.path == data.path && val.is_search
          ) != -1
        );
      }
    }
  }
};
