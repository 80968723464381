<template>
  <div class="card">
    <div class="card-header">
      <button @click.prevent="$emit('onClickTab')" :class="{ collapsed: activeTabs }" class="btn btn-link" type="button">
        Package Options
        <!-- Accordian Arrow -->
        <v-arrowicon />
      </button>
    </div>
    <div id="packageOptions" class="collapse" :class="{ show: activeTabs }" data-parent="#services_accordion" style>
      <div class="card-body">
        <div class="rc-radio-group mt-0">
          <label class="radio" :class="{ 'not-allowed': disabledOptions }">
            <input
              v-if="!disabledOptions"
              @change.prevent="managePackageType(true)"
              name="pkg-options"
              type="radio"
              :checked="pkgOptions.isSingleHotel"
            />
            <input v-else type="radio" :checked="pkgOptions.isSingleHotel" />
            <span>Package with Single Hotel &amp; Multiple Room Categories</span>
          </label>
          <label class="radio" :class="{ 'not-allowed': disabledOptions }">
            <input
              v-if="!disabledOptions"
              @change.prevent="managePackageType(false)"
              name="pkg-options"
              type="radio"
              :checked="!pkgOptions.isSingleHotel"
            />
            <input v-else type="radio" :checked="!pkgOptions.isSingleHotel" />
            <span>Package with Multiple Hotels</span>
          </label>
        </div>
        <div>
          <div class="pkg-option-parent d-flex">
            <div class="pkg-option-div br">
              <p class="form-para mb-2">
                Package
                {{ pkgOptions.isSingleHotel ? "Room Categories" : "Options" }}
                Name
              </p>
              <input
                v-on:keyup.enter="saveAndRemoveOptionsName('save', $event.currentTarget.value)"
                v-model.trim="optionName"
                type="text"
                class="form-control mb-2"
                :placeholder="`Enter ${pkgOptions.isSingleHotel ? 'Room Categories' : 'Options'} Name`"
              />
              <div class="ml-2 d-flex justify-content-end align-items-center">
                <label for="honeymoon" class="rc-control rc-control-checkbox"
                  ><input v-model="isHoneymoon" id="honeymoon" type="checkbox" checked="checked" />
                  <div class="rc-control-indicator"></div>
                </label>
                <label class="ml-3" for="honeymoon">Is Honeymoon</label>
              </div>
              <p class="text-center mb-0">
                <button
                  v-if="selectIndex == null"
                  @click="handleSaveData('pkgOptions')"
                  :disabled="pkgOptions.items <= 0"
                  href="javascript:void(0)"
                  class="btn btn-submit ripple btn-hotel m-0 mr-2"
                >
                  Save option
                </button>
                <button
                  v-else
                  @click="handleCancelUpdate"
                  :disabled="pkgOptions.items <= 0"
                  href="javascript:void(0)"
                  class="btn btn-danger ripple btn-hotel m-0 mr-2 br-0"
                >
                  Cancel
                </button>
                <button
                  :disabled="optionName == ''"
                  href="javascript:void(0)"
                  @click.prevent="saveAndRemoveOptionsName('save')"
                  class="btn btn-submit ripple btn-hotel m-0"
                >
                  {{ selectIndex == null ? "Add" : "Update" }} Option
                </button>
              </p>
            </div>
            <div class="pkg-option-div">
              <span
                @click.prevent="saveAndRemoveOptionsName('remove', option, $event, index)"
                v-for="(option, index) in pkgOptions.items"
                :key="option.type"
                class="chips"
                :class="{
                  disabled: !option.active,
                  'honeymoon-pink': option.is_honeymoon
                }"
              >
                {{ option.type }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
export default {
  name: "Options",
  props: {
    getOptions: { type: Function },
    onClickTab: { type: Function },
    activeTabs: { type: Boolean }
  },
  data() {
    return {
      optionName: "",
      selectIndex: null,
      disabledOptions: false,
      isHoneymoon: false,
      toastMessages: {
        options: {
          success: `Options Is Updated`
        }
      }
    };
  },
  computed: {
    ...mapGetters(["pkgOptions", "selectedOptionType", "packageDetails"]),
    checkIfHotelAdded() {
      let disabled = false;
      if (this.pkgOptions.items.length > 0) {
        for (let item of this.pkgOptions.items) {
          if (item.hotels.length > 0) {
            disabled = true;
            continue;
          } else {
            disabled = false;
            break;
          }
        }
      } else {
        disabled = false;
      }
      return disabled;
    }
  },
  watch: {
    "packageDetails.options.items": function(data) {
      if (data.length > 0) {
        this.disabledOptions = true;
      } else {
        this.disabledOptions = false;
      }
    },
    packageDetails(packages) {
      if (packages.options) {
        for (let items of packages.options.items) {
          if (!items.dates) {
            items.dates = new Object();
          } else {
            for (let month in items.dates) {
              let momentDates = [];
              for (let date of items.dates[month]) {
                if (!date.prices) {
                  date.prices = new Object();
                  //date.value = this.$moment(date.value);
                  momentDates.push(date);
                } else {
                  //date.value = this.$moment(date.value);
                  momentDates.push(date);
                }
              }
              items.dates[month] = momentDates;
            }
          }
        }
        this.isFieldsDataExists({ value: packages.options, type: "options" });
        if (packages.options.items.length > 0) {
          this.changeOptionType(this._.head(packages.options.items).type);
        }
      }
    }
  },
  methods: {
    ...mapMutations(["addNewOptions", "removeOptionsType", "changeOptionType", "managePackageType", "isFieldsDataExists", "makeOptionsOld"]),
    ...mapActions(["updatePackage"]),
    saveAndRemoveOptionsName(type, value, evt, index) {
      if (type == "save") {
        if (this.optionName) {
          this.addNewOptions({
            name: this.optionName,
            is_honeymoon: this.isHoneymoon,
            selectIndex: this.selectIndex
          });
          /* Selected Option Type */
          if (this.selectedOptionType == "") {
            this.changeOptionType(this.optionName || value.type);
          }
          this.optionName = "";
          this.selectIndex = null;
        } else {
          this.$toasted.info("please enter value", {
            position: "bottom-center",
            duration: 2000
          });
        }
      } else {
        if (!evt.ctrlKey) {
          const optIndex = this.pkgOptions.items.findIndex(val => val.type == value.type);
          if (optIndex != -1) {
            const ifDatesExitis = this._.isEmpty(this.pkgOptions.items[optIndex].dates);
            const firstItem = this._.head(this.pkgOptions.items);
            if (!ifDatesExitis) {
              this.pkgOptions.items[optIndex].active = !this.pkgOptions.items[optIndex].active;
            } else {
              this.removeOptionsType(optIndex);
              if (firstItem) {
                this.changeOptionType(firstItem.type);
              } else {
                this.changeOptionType("");
              }
            }
            this.optionName = "";
            this.isHoneymoon = false;
            this.selectIndex = null;
          }
        } else {
          this.optionName = value.type;
          this.isHoneymoon = value.is_honeymoon;
          this.selectIndex = index;
        }
      }
    },
    handleCancelUpdate() {
      this.optionName = "";
      this.isHoneymoon = false;
      this.selectIndex = null;
    },
    handleSaveData(type) {
      let data = this[type];
      if (type == "pkgOptions") {
        type = "options";
      }
      let newOptions = JSON.parse(JSON.stringify(data));
      /* for (let value of newOptions.items) {
        value.hotels = this.handleHotelData(JSON.stringify(value.hotels));
      } */
      newOptions = {
        ...newOptions,
        items: newOptions.items.filter(data => !data.isNew)
      };
      this.updatePackage({
        type: "ADVANCE",
        data: {
          advance: {
            code: this.$route.query.code,
            data: { [type]: newOptions }
          }
        }
      }).then(() => {
        if (type == "options") {
          this.disabledOptions = true;
          this.makeOptionsOld();
        }
        this.$toasted.success(`${this.toastMessages[type].success}`, {
          position: "bottom-center",
          duration: 5000
        });
      });
    }
    /* handleHotelData(hotels) {
      let newListItem = JSON.parse(hotels);
      if (hotels) {
        for (let hotel of newListItem) {
          if (hotel.type && hotel.type._id) {
            hotel.type = hotel.type._id;
          }
          if (hotel.item) {
            hotel.item = hotel.item.map(data => ({
              ...data,
              name: data.name && data.name._id ? data.name._id : data.name
            }));
          }
        }
      }
      return newListItem;
    } */
  }
};
</script>

<style scoped>
.pkg-option-div span.chips {
  background-color: rgb(23, 162, 184);
  padding: 3px 10px;
  display: inline-block;
  cursor: default;
  font-size: 11px;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 5px;
}

.pkg-option-div span.chips button {
  border: none;
  background: transparent !important;
  padding: 1px 5px;
  color: inherit;
  cursor: pointer;
  outline: none;
  border-left: 1px solid rgba(255, 255, 255, 0.44);
  margin-left: 5px;
}
.not-allowed {
  cursor: not-allowed;
  pointer-events: none;
}

.not-allowed span {
  color: #595c5d !important;
}

.not-allowed span::after {
  background: #595c5d !important;
}

.not-allowed span::before {
  border-color: #595c5d !important;
}
.chips.disabled {
  opacity: 0.65;
}
.br-0 {
  border-radius: 0px;
}
.honeymoon-pink {
  background-color: hotpink !important;
}
</style>
