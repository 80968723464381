<template>
  <div id="taxes_details" class="collapse" data-parent="#services_accordion">
    <div class="card-body p-2">
      <div v-for="(train, index) in trains" :key="train.orderId" class="row no-gutters mb-3">
        <div class="offset-4 col-2">
          <div class="form-group mr-3 mb-0">
            <label>From</label>
            <input v-model.trim="train.origin" type="text" class="form-control-sm form-control" />
          </div>
        </div>
        <div class="col-2">
          <div class="form-group mb-0">
            <label>To</label>
            <input v-model.trim="train.destination" type="text" class="form-control-sm form-control" />
          </div>
        </div>
        <div class="col-1 d-flex align-items-end">
          <button @click.prevent="trains.splice(index, 1)" class="btn btn-danger btn-sm package_btn">Remove</button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="d-flex justify-content-center">
            <button @click.prevent="addNewTrain" class="btn btn-info btn-sm package_btn">Add New Item</button>
            <button @click.prevent="handleSaveData" class="btn btn-info btn-sm package_btn">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Trains",
  data() {
    return {
      trains: []
    };
  },
  computed: {
    ...mapGetters(["packageTrains"])
  },
  created() {
    this.trains = this?.packageTrains || [];
  },
  methods: {
    ...mapActions(["updatePackage"]),
    addNewTrain() {
      this.trains.push({
        orderId: Math.floor(Math.random() * 9999) + 1000,
        origin: "",
        destination: ""
      });
    },
    handleSaveData() {
      this.updatePackage({
        type: "ADVANCE",
        data: {
          advance: {
            code: this.$route.query.code,
            data: { trains: this.trains }
          }
        }
      }).then(() => {
        this.$toasted.success("Trains Details Is Updated", {
          position: "bottom-center",
          duration: 5000
        });
      });
    }
  }
};
</script>

<style scoped>
.package_btn {
  border-radius: 0;
  margin-left: 10px;
}
</style>
