<template>
  <container headTitle="Packages Dates and Pricing">
    <div slot="createbtn">
      <div class="row">
        <div class="col-12 d-flex justify-content-end">
          <div class="form-group m-0 search_package_btn">
            <input v-model="package_code" type="text" @keydown.enter.prevent="findPackage" class="form-control form-control-sm" placeholder="Code" />
            <button :disabled="package_code == '' || isPkgDatesLoading" @click.prevent="findPackage" class="btn btn-info btn-sm">Search</button>
          </div>
        </div>
      </div>
    </div>

    <div slot="content">
      <div class="container-fluid px-1">
        <div v-if="!isPkgDatesLoading && pkgDatesDetails && pkgDatesDetails.options && pkgDatesDetails.options.length" class="row">
          <div class="col-12">
            <div class="card option_container">
              <div class="card-header p-2">
                Dates & Pricing :-
                <b
                  >{{ pkgDatesDetails.name }} <span style="color: #f56d6d; font-size: 12px">( {{ pkgDatesDetails.code }} )</span></b
                >
              </div>
              <div class="card-body p-2">
                <!-- Package Options Sections -->
                <div class="row">
                  <div class="col-10 col-md-10">
                    <div class="d-flex flex-wrap">
                      <div class="form-group mr-2 option_item" v-for="(option, index) in pkgDatesDetails.options" :key="option.name">
                        <label class="d-flex align-items-center">
                          <input v-model="active_option" :value="index" class="mr-2" type="radio" name="option_details" />
                          {{ option.name }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-2 col-md-2 d-flex justify-content-end">
                  <button class="btn btn-info btn-sm" href="javascript:void(0)">
                    View Dates
                  </button>
                </div> -->
                </div>
              </div>
            </div>
            <div class="row mt-3 d-flex justify-content-center">
              <div class="col-4">
                <div class="card">
                  <div class="card-header p-2">Pricing Section</div>
                  <div class="card-body p-2">
                    <div class="row no-gutters">
                      <div class="col-6">
                        <div class="form-group mr-2">
                          <label for="adult_single_person" class="d-flex align-items-center">
                            <input
                              v-model="pricing.adult.singleallowed"
                              :disabled="['', 'disabled'].includes(date_types) || (date_types == 'insert' && is_honeymoon)"
                              type="checkbox"
                              name="adult_single_person"
                              id="adult_single_person"
                              class="mr-2"
                            />
                            Adult
                            <span>( pp with single )</span>
                          </label>
                          <input
                            v-model.trim.number="pricing.adult.single"
                            :disabled="!pricing.adult.singleallowed || date_types != 'insert' || is_honeymoon"
                            v-input-type="'number'"
                            type="text"
                            class="form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="d-flex align-items-center">Adult Surcharge</label>
                          <input
                            v-model.trim.number="pricing.adult.surcharge"
                            :disabled="['', 'disabled'].includes(date_types)"
                            v-input-type="'number'"
                            type="text"
                            class="form-control form-control-sm"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row no-gutters">
                      <div class="col-6">
                        <div class="form-group mr-2">
                          <label for="adult_twin_sharing" class="d-flex align-items-center"> Adult <span> ( pp on twin sharing )</span></label>
                          <input
                            v-model.trim.number="pricing.adult.twin"
                            :disabled="!pricing.adult.twinallowed || date_types != 'insert'"
                            v-input-type="'number'"
                            type="text"
                            class="form-control form-control-sm"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row no-gutters">
                      <div class="col-6">
                        <div class="form-group mr-2">
                          <label for="adult_extra_bed" class="d-flex align-items-center"
                            ><input
                              v-model="pricing.adult.extrabedallowed"
                              :disabled="date_types != 'insert' || is_honeymoon"
                              type="checkbox"
                              name="adult_extra_bed"
                              id="adult_extra_bed"
                              class="mr-2"
                            />
                            Adult <span>( pp With Extra Bed )</span></label
                          >
                          <input
                            v-model.trim.number="pricing.adult.extrabed"
                            :disabled="!pricing.adult.extrabedallowed || date_types != 'insert' || is_honeymoon"
                            v-input-type="'number'"
                            type="text"
                            class="form-control form-control-sm"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row no-gutters">
                      <div class="col-6">
                        <div class="form-group mr-2">
                          <label for="child_with_bed" class="d-flex align-items-center"
                            ><input
                              v-model="pricing.child.cwballowed"
                              :disabled="['', 'disabled'].includes(date_types) || is_honeymoon"
                              type="checkbox"
                              name="child_with_bed"
                              id="child_with_bed"
                              class="mr-2"
                            />
                            CWB <span>( child with bed )</span></label
                          >
                          <input
                            v-model.trim.number="pricing.child.cwb"
                            :disabled="!pricing.child.cwballowed || date_types != 'insert' || is_honeymoon"
                            v-input-type="'number'"
                            type="text"
                            class="form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="d-flex align-items-center">CWB Surcharge</label>
                          <input
                            v-model.trim.number="pricing.child.cwbsurcharge"
                            :disabled="!pricing.child.cwballowed || ['', 'disabled'].includes(date_types) || is_honeymoon"
                            v-input-type="'number'"
                            type="text"
                            class="form-control form-control-sm"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row no-gutters">
                      <div class="col-6">
                        <div class="form-group mr-2">
                          <label for="child_with_no_bed" class="d-flex align-items-center"
                            ><input
                              v-model="pricing.child.cnballowed"
                              :disabled="['', 'disabled'].includes(date_types) || is_honeymoon"
                              type="checkbox"
                              name="child_with_no_bed"
                              id="child_with_no_bed"
                              class="mr-2"
                            />
                            CNB <span>( child with no bed )</span></label
                          >
                          <input
                            v-model.trim.number="pricing.child.cnb"
                            :disabled="!pricing.child.cnballowed || date_types != 'insert' || is_honeymoon"
                            v-input-type="'number'"
                            type="text"
                            class="form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="d-flex align-items-center">CNB Surcharge</label>
                          <input
                            v-model.trim.number="pricing.child.cnbsurcharge"
                            :disabled="!pricing.child.cnballowed || ['', 'disabled'].includes(date_types) || is_honeymoon"
                            v-input-type="'number'"
                            type="text"
                            class="form-control form-control-sm"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row no-gutters">
                      <div class="col-6">
                        <div class="form-group mr-2">
                          <label for="infant_pricing" class="d-flex align-items-center"
                            ><input
                              v-model="pricing.infantallowed"
                              :disabled="date_types != 'insert' || is_honeymoon"
                              type="checkbox"
                              name="infant_pricing"
                              id="infant_pricing"
                              class="mr-2"
                            />
                            Infant
                          </label>
                          <input
                            v-model.trim.number="pricing.infant"
                            :disabled="!pricing.infantallowed || date_types != 'insert' || is_honeymoon"
                            v-input-type="'number'"
                            type="text"
                            class="form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="d-flex align-items-center">Surcharge Description</label>
                          <select
                            :disabled="['', 'disabled'].includes(date_types)"
                            v-model.trim="pricing['surcharge-description']"
                            class="form-control form-control-sm"
                          >
                            <option value="">Choose</option>
                            <option value="Hotel">Hotel</option>
                            <option value="Flight">Flight</option>
                            <option value="Weekend">Weekend</option>
                            <option value="Long Weekend">Long Weekend</option>
                            <option value="Season">Season</option>
                            <option value="Peak Season">Peak Season</option>
                            <option value="Gala Dinner">Gala Dinner</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 d-flex justify-content-end">
                        <button
                          v-if="date_types != ''"
                          @click.prevent="submitFormData"
                          :disabled="isSubmitting || $v.$invalid || isSubmitValid"
                          class="btn btn-info btn-sm submit-btn mt-2"
                        >
                          <spinner v-if="isSubmitting" />
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="card">
                  <div class="card-header p-2">Packages Dates</div>
                  <div class="card-body p-2">
                    <div class="dates_container">
                      <div class="dates_header">
                        <div @click.prevent="handleMonthChange('prev')" v-if="$moment().isBefore(currentDate)" class="prev_btn">
                          <a href="javascript:void()"> ‹ </a>
                        </div>
                        <div v-else></div>
                        <div class="month_info">
                          <label>
                            <input
                              :checked="AllDatesSelected"
                              v-if="hideSelectAllInput"
                              @change.prevent="handleSelectAllDates"
                              class="mr-2"
                              type="checkbox"
                            />
                            {{ currentDate.format("MMMM YYYY") }}
                          </label>
                        </div>
                        <div @click.prevent="handleMonthChange('next')" v-if="currentDate.isBefore($moment().add(11, 'month'))" class="next_btn">
                          <a href="javascript:void()"> › </a>
                        </div>
                        <div v-else></div>
                      </div>
                      <div class="dates_weeks">
                        <ul class="pkg-calendar-week">
                          <li v-for="(week, index) in weeks" :key="week">
                            <label class="d-flex align-items-center">
                              <input
                                v-if="hideSelectAllInput"
                                :checked="checkIfWeekSelected(index)"
                                @change.prevent="handleWeekSelect(index, $event)"
                                type="checkbox"
                                class="mr-1"
                              />
                              {{ week }}</label
                            >
                          </li>
                        </ul>
                        <ul class="pkg-calendar-days">
                          <li
                            @click.prevent="handleSelectDates(item)"
                            :class="[item.className]"
                            v-for="item in calendarDates"
                            :key="$moment(item.day).format('DDMMYYYY')"
                          >
                            <div class="d-flex justify-content-center align-items-center flex-column">
                              <span>{{ $moment(item.day).format("DD") }}</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <hr class="my-1" />
                    <div class="d-flex justify-content-center">
                      <div class="form-group mr-2">
                        <label class="d-flex align-items-center mb-0">
                          <input v-model="date_types" value="insert" type="radio" class="mr-1" name="calendar_type" id="insert_new_date" />
                          Insert New
                        </label>
                      </div>

                      <div class="form-group mr-2">
                        <label class="d-flex align-items-center mb-0">
                          <input v-model="date_types" value="update" type="radio" class="mr-1" name="calendar_type" id="insert_new_date" />
                          Update Surcharge
                        </label>
                      </div>

                      <div class="form-group">
                        <label class="d-flex align-items-center mb-0">
                          <input v-model="date_types" value="disabled" type="radio" class="mr-1" name="calendar_type" id="insert_new_date" />
                          Disable Dates
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div v-if="!isPkgDatesLoading && !pkgDatesDetails" class="d-flex justify-content-center align-items-center clr-red" style="height: 300px">
              <h4 v-if="!pkgDatesDetails">No Package Found</h4>
              <h4 v-if="pkgDatesDetails && pkgDatesDetails.options && pkgDatesDetails.options.length == 0">No Options Found</h4>
            </div>
            <div v-if="isPkgDatesLoading" class="d-flex justify-content-center align-items-center clr-red" style="height: 300px">
              <spinner />
            </div>
          </div>
        </div>
      </div>
    </div>
  </container>
</template>

<script>
import methods from "./helpers/methods";
import computed from "./helpers/computed";
import { required } from "vuelidate/lib/validators";
export default {
  mixins: [methods, computed],
  name: "PackagesDates",
  data() {
    return {
      package_code: this?.$route?.query?.pkg_code || "",
      active_option: 0,
      currentDate: this.$moment(),
      date_types: "",
      weeks: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      modifiedDates: [],
      selectedDates: [],
      pricing: {
        adult: {
          single: "",
          singleallowed: true,
          twin: "",
          twinallowed: true,
          extrabed: "",
          extrabedallowed: true,
          surcharge: ""
        },
        child: {
          cwb: "",
          cwballowed: true,
          cnb: "",
          cnballowed: true,
          cwbsurcharge: "",
          cnbsurcharge: ""
        },
        "surcharge-description": "",
        infant: "",
        infantallowed: true
      },
      isSubmitting: false
    };
  },
  watch: {
    date_types() {
      this.modifiedDates = [];
      this.pricing = JSON.parse(JSON.stringify(this.datesPricing));
      this.currentDate = this.$moment();
      if (["update", "disabled"].includes(this.date_types)) {
        this.modifiedDates = JSON.parse(JSON.stringify(this.selectedDates));
      }
    },
    active_option(value) {
      this.date_types = "";
      this.selectedDates = [];
      this.modifiedDates = [];
      this.pricing = JSON.parse(JSON.stringify(this.datesPricing));
      this.currentDate = this.$moment();
      const selectOption = this.pkgDatesDetails?.options[value];
      for (const dates of selectOption?.dates || {}) {
        const month = this.$moment(dates.month);
        for (const data of dates?.items) {
          const date = this.$moment(`${month.format("YYYY-MM")}-${data.value}`);
          this.bindDatesOnPageLoad({ ...data, day: date });
        }
      }
    },
    pkgDatesDetails: {
      deep: true,
      immediate: true,
      handler(value) {
        this.date_types = "";
        this.selectedDates = [];
        this.modifiedDates = [];
        if (value?.options?.length) {
          const selectOption = value?.options[this.active_option];
          for (const dates of selectOption?.dates || {}) {
            const month = this.$moment(dates.month);
            for (const data of dates?.items) {
              const date = this.$moment(`${month.format("YYYY-MM")}-${data.value}`);
              this.bindDatesOnPageLoad({ ...data, day: date });
            }
          }
        }
      }
    }
  },
  created() {
    if (this.package_code) {
      this.fetchPackageDatesPricing(this.package_code);
    }
  },
  computed: {
    renderDates() {
      if (this.date_types == "") {
        return this.selectedDates;
      }

      return this.modifiedDates;
    },
    AllDatesSelected() {
      let isChecked = true;
      for (const item in this.weeks) {
        if (!this.checkIfWeekSelected(item)) {
          isChecked = false;
          break;
        }
      }
      return isChecked;
    },
    hideSelectAllInput() {
      const _monthIndex = this.modifiedDates.findIndex((data) => data.month == this.currentDate.format("MM-YYYY"));
      if (this.date_types == "") {
        return false;
      }
      if (this.date_types == "insert") {
        return true;
      }
      if (this.date_types != "insert") {
        return _monthIndex != -1;
      }
      return false;
    },
    isSubmitValid() {
      let checked = true;
      if (this.date_types == "insert") {
        checked = this.modifiedDates.length == 0;
      }
      if (["update", "disabled"].includes(this.date_types)) {
        for (const month of this.modifiedDates) {
          for (const date of month.items) {
            if (date?.is_update) {
              checked = false;
              break;
            }
          }
        }
      }
      return checked;
    }
  },
  methods: {
    findPackage() {
      this.$router.push({
        name: "AvailableDates",
        query: {
          pkg_code: this.package_code
        }
      });
      this.fetchPackageDatesPricing(this.package_code);
    },
    handleWeekSelect(week, event) {
      if (this.date_types != "") {
        const _days = this.currentDate.daysInMonth();
        for (let _d = 1; _d <= _days; _d++) {
          const _monthIndex = this.modifiedDates.findIndex((data) => data.month == this.currentDate.format("MM-YYYY"));
          const date = this.$moment(`${this.currentDate.format("YYYY-MM")}-${_d <= 9 ? `0${_d}` : _d}`);
          if (this.$moment().isSame(date, "months") && this.$moment().isAfter(date, "days")) {
            continue;
          }
          if (date.format("d") == week) {
            const _matchDate = this.modifiedDates[_monthIndex]?.items.findIndex((_md) => this.$moment(_md.day).isSame(date, "days"));
            if (event.target.checked) {
              if (_monthIndex != -1) {
                if (_matchDate == -1) {
                  if (this.date_types == "insert") {
                    this.modifiedDates[_monthIndex]?.items.push({
                      day: date,
                      active: true,
                      prices: { ...this.pricing }
                    });
                  }
                } else {
                  let _upObj = {
                    ...this.modifiedDates[_monthIndex]?.items[_matchDate],
                    is_update: true
                  };
                  if (this.date_types == "disabled") {
                    _upObj.active = false;
                  }
                  this.modifiedDates[_monthIndex].items.splice(_matchDate, 1, _upObj);
                }
              } else {
                this.modifiedDates.push({
                  month: this.currentDate.format("MM-YYYY"),
                  items: [{ day: date, active: true, prices: { ...this.pricing } }]
                });
              }
            } else {
              if (_monthIndex != -1) {
                if (_matchDate != -1) {
                  if (this.date_types == "insert") {
                    this.modifiedDates[_monthIndex]?.items.splice(_matchDate, 1);
                  }
                  if (this.date_types == "update") {
                    this.modifiedDates[_monthIndex].items.splice(_matchDate, 1, {
                      ...this.modifiedDates[_monthIndex]?.items[_matchDate],
                      is_update: false
                    });
                  }
                  if (this.date_types == "disabled") {
                    this.modifiedDates[_monthIndex].items.splice(_matchDate, 1, {
                      ...this.modifiedDates[_monthIndex]?.items[_matchDate],
                      is_update: true,
                      active: true
                    });
                  }
                }
              }
            }
          }
        }
      }
    },
    checkIfWeekSelected(index) {
      const _days = this.currentDate.daysInMonth();
      let _weekCount = 0;
      let _monthWeekCount = 0;
      for (let _d = 1; _d <= _days; _d++) {
        const date = this.$moment(`${this.currentDate.format("YYYY-MM")}-${_d <= 9 ? `0${_d}` : _d}`);
        if (this.$moment().isSameOrBefore(date, "day") && date.format("d") == index) _weekCount++;
      }
      const _monthIndex = this.modifiedDates.findIndex((data) => data.month == this.currentDate.format("MM-YYYY"));
      if (_monthIndex != -1) {
        _monthWeekCount =
          this.modifiedDates[_monthIndex]?.items?.reduce((count, data) => {
            if (this.$moment().isSameOrBefore(this.$moment(data?.day), "day") && this.$moment(data?.day)?.format("d") == index) {
              if (this.date_types == "insert") {
                count++;
              }
              if (this.date_types == "update") {
                if (data.is_update) {
                  count++;
                }
              }
              if (this.date_types == "disabled") {
                if (!data.active) {
                  count++;
                }
              }
            }
            return count;
          }, 0) || 0;

        return _weekCount == _monthWeekCount;
      }
    },

    handleSelectAllDates(evt) {
      for (const week in this.weeks) {
        this.handleWeekSelect(week, evt);
      }
    },
    submitFormData() {
      const formData = this.modifiedDates;
      this.updatePackageDatesPricing({
        _id: this?.pkgDatesDetails?._id,
        options_id: this.active_option,
        options_name: this.pkgDatesDetails?.options[this.active_option]?.name,
        data: formData,
        pricing: this.pricing,
        action_type: this.date_types
      }).then((data) => {
        if (data.status) {
          this.$toasted.clear();
          this.$toasted.success(data.message, {
            duration: 1500,
            position: "bottom-center"
          });
          if (this.date_types == "insert") {
            this.modifiedDates = [];
          }
          this.pricing = JSON.parse(JSON.stringify(this.datesPricing));
          this.fetchPackageDatesPricing(this?.$route?.query?.pkg_code).then((data) => {
            this.modifiedDates = JSON.parse(JSON.stringify(this.selectedDates));
            this.date_types = "";
          });
        }
        if (!data.status) {
          this.$toasted.clear();
          this.$toasted.error(data.message, {
            duration: 1500,
            position: "bottom-center"
          });
        }
      });
    },
    manageActionType(evt) {
      return true;
    }
  },
  validations() {
    let pricing = {
      adult: {},
      child: {}
    };

    if (this.date_types == "insert") {
      for (const adult in this.pricing.adult) {
        if (!this.is_honeymoon && adult.includes("allowed") && this.pricing.adult[adult]) {
          const value = adult.replace("allowed", "");
          pricing = {
            ...pricing,
            adult: {
              ...pricing.adult,
              [value]: { required }
            }
          };
        } else {
          pricing = {
            ...pricing,
            adult: {
              ...pricing.adult,
              twin: { required }
            }
          };
        }
      }
      if (!this.is_honeymoon && this.pricing.child.cnballowed) {
        pricing = {
          ...pricing,
          child: {
            ...pricing.child,
            cnb: { required }
          }
        };
      }
      if (!this.is_honeymoon && this.pricing.child.cwballowed) {
        pricing = {
          ...pricing,
          child: {
            ...pricing.child,
            cwb: { required }
          }
        };
      }
      if (!this.is_honeymoon && this.pricing.infantallowed) {
        pricing = {
          ...pricing,
          infant: { required }
        };
      }
    }

    if (this.date_types == "update") {
      if (!this.is_honeymoon && this.pricing.adult.singleallowed) {
        pricing = {
          ...pricing,
          adult: {
            ...pricing.adult,
            surcharge: {}
          }
        };
      }
      if (!this.is_honeymoon && this.pricing.child.cnballowed) {
        pricing = {
          ...pricing,
          child: {
            ...pricing.child,
            cnbsurcharge: {}
          }
        };
      }
      if (!this.is_honeymoon && this.pricing.child.cwballowed) {
        pricing = {
          ...pricing,
          child: {
            ...pricing.child,
            cwbsurcharge: {}
          }
        };
      }
    }

    return {
      pricing
    };
  }
};
</script>

<style scoped>
.option_container .option_item label {
  cursor: pointer;
}

.dates_container .dates_header {
  display: flex;
  justify-content: space-between;
}

.dates_container .dates_header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #17a2b8;
  padding-bottom: 5px;
}

.dates_container .dates_header .month_info {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  word-spacing: 4px;
  letter-spacing: 1px;
  color: #3c3c3c;
}

.dates_container .dates_header .month_info label {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dates_container .dates_header .prev_btn,
.dates_container .dates_header .next_btn {
  background-color: #e5e5e5;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.dates_container .dates_header .prev_btn a,
.dates_container .dates_header .next_btn a {
  color: #8f8f8f;
  font-size: 32px;
  position: absolute;
  bottom: -5px;
  text-decoration: none;
  user-select: none;
}

.pkg-calendar-week,
.dates_container >>> .pkg-calendar-days {
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 0;
}

.pkg-calendar-week > li {
  display: flex;
  align-content: center;
  justify-content: center;
  cursor: pointer;
}

.pkg-calendar-week > li,
.dates_container >>> .pkg-calendar-days > li {
  float: left;
  margin-right: 1%;
  margin-bottom: 6px;
  width: 13.4285%;
  font-size: 14px;
  text-align: center;
  padding: 15px 0;
  /* border-radius: 50%; */
  user-select: none;
}
.pkg-calendar-week > li:last-child,
.dates_container >>> .pkg-calendar-days > li:nth-of-type(7n) {
  margin-right: 0;
}

.pkg-calendar-week > li {
  text-transform: uppercase;
  font-weight: bold;
  color: #3c3c3c;
}

.pkg-calendar-week > li label {
  cursor: pointer;
}

.dates_container >>> .pkg-calendar-days > li {
  color: #3c3c3c;
  cursor: pointer;
}

.pkg-calendar-days li {
  width: 15px;
}

.dates_container >>> .pkg-calendar-days > li.disabled {
  background: #e5e5e5;
  pointer-events: none;
  color: #8f8f8f;
}
.dates_container >>> .pkg-calendar-days > li.active {
  background: #17a2b8;
  color: #fff;
}

.dates_container >>> .pkg-calendar-days > li.update {
  background: #17b82c;
  color: #fff;
}
.dates_container >>> .pkg-calendar-days > li.de_active {
  background: #929292;
  color: #fff;
}
.dates_container >>> .pkg-calendar-days > li.surcharge {
  background: #5457ad;
  color: #fff;
}
.dates_container >>> .pkg-calendar-days > li.update {
  background: #46a96d;
  color: #fff;
}

.form-group {
  margin: 0px;
}

.submit-btn {
  transition: 0.5s all ease-in-out;
}

.submit-btn >>> .spinner-border {
  width: 1rem;
  height: 1rem;
}

.search_package_btn {
  display: flex;
}

.search_package_btn input {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  margin: 0px;
}

.search_package_btn button {
  border-radius: 0px;
  border: 0px;
}
</style>
