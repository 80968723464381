<template>
  <div class="container-fluid px-0">
    <div class="row">
      <div class="col-12">
        <!-- Holidays Categories -->
        <div class="pkg-card">
          <div>
            <p class="form-para mb-2">Holiday Category</p>
            <div class="mb-3">
              <holidaysCategory
                @changeTheme="item => manageThemesAndService('themes', item)"
                :pkgThemes="renderCategory"
                :packageData="packageData"
              />
            </div>
          </div>
          <div>
            <p class="form-para mb-2">Holiday Theme</p>
            <div class="mb-3">
              <holidaysCategory
                @changeTheme="item => manageThemesAndService('themes', item)"
                :pkgThemes="renderTheme"
                :packageData="packageData"
              />
            </div>
          </div>
          <div v-if="updateMode" class="text-right">
            <a
              @click.prevent="updatePkgDetails('themes')"
              href="javascript:void(0)"
              class="btn btn-submit ripple"
              >Update</a
            >
          </div>
        </div>
        <!-- Images And Videos Galary -->
        <div class="mb-3 pkg-card">
          <div class="media-popup">
            <p class="form-para mb-2">Add Media</p>
            <!-- <button
              :disabled="mediaLoading"
              @click.prevent="handlePkgMedia"
              class="btn btn-submit"
            >
              <div v-if="mediaLoading" class="data-loader">
                <Loader />
              </div>
              Select
            </button> -->
            <button
              style="width: 110px"
              v-for="item in packageDetails.pkg_destinations"
              :key="item.destinationName"
              :disabled="mediaLoading"
              @click.prevent="handlePkgMedia(item.id)"
              class="btn btn-submit mb-2 px-1"
            >
              <div v-if="mediaLoading" class="data-loader">
                <Loader />
              </div>
              {{ item.destinationName }}
            </button>

            <popup-model
              @close-model="isImageModelOpen = false"
              :openModel="isImageModelOpen"
            >
              <div slot="content">
                <div class="row px-1">
                  <div class="col-sm-6">
                    <ul class="nav nav-pills">
                      <li
                        @click.prevent="selectedMediaTab = item.id"
                        class="nav-item"
                        v-for="item in packageDetails.pkg_destinations"
                        :key="item.id"
                      >
                        <a
                          :class="{ active: item.id == selectedMediaTab }"
                          class="nav-link"
                          href="javascript:void(0)"
                          >{{ item.destinationName }}</a
                        >
                      </li>
                    </ul>
                  </div>
                  <div class="offset-sm-2 col-sm-4">
                    <input
                      @keydown.enter="handleFilterByText('add')"
                      placeholder="Search Tag Or Name"
                      class="form-control form-control-sm"
                      :class="{ 'mb-1': filterList.length > 0 }"
                      type="text"
                      v-model.trim="filterText"
                    />
                    <span
                      @click="handleFilterByText('remove', index)"
                      class="chips"
                      v-for="(item, index) in filterList"
                      :key="item"
                    >
                      {{ item }}
                    </span>
                  </div>
                </div>
                <div
                  v-if="renderMediaList && renderMediaList.images"
                  class="row mt-4 px-1"
                >
                  <div class="col-sm-12">
                    <ul class="selected-images" v-viewer="{ movable: false }">
                      <li
                        v-for="item in renderMediaList.images"
                        :key="item.path"
                      >
                        <label
                          :for="item.path"
                          class="rc-control rc-control-checkbox"
                        >
                          <input
                            @change.prevent="
                              handleSelectedMedia(item, 'details')
                            "
                            :id="item.path"
                            :checked="checkImageAvailable(item, 'details')"
                            type="checkbox"
                            class="d-none"
                          />
                          <div class="rc-control-indicator"></div>
                        </label>
                        <img
                          @click.prevent="showImage"
                          style="margin-bottom: 5px"
                          class="img-responsive"
                          :title="filterImagesName(item.name)"
                          :src="mediaServerPath + '/' + item.path"
                        />
                        <p>{{ filterImagesName(item.name) }}</p>
                        <label
                          v-if="checkImageAvailable(item, 'details')"
                          :for="item.path + 'search'"
                          class="rc-control rc-control-checkbox search-input-photo"
                        >
                          <input
                            @change.prevent="
                              handleSelectedMedia(item, 'search')
                            "
                            :id="item.path + 'search'"
                            :checked="checkImageAvailable(item, 'search')"
                            type="checkbox"
                            class="d-none"
                          />
                          <div class="rc-control-indicator"></div>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="row px-1">
                  <div class="mt-2 offset-sm-7 col-sm-5">
                    <div class="d-flex float-right">
                      <button
                        @click.prevent="updatePkgDetails('images')"
                        :disabled="packageData.images.length == 0"
                        class="btn btn-primary"
                      >
                        <!-- <div class="data-loader">
                          <Loader />
                        </div>-->
                        Submit
                      </button>
                      <button
                        @click.prevent="isImageModelOpen = false"
                        class="btn btn-danger ml-3"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </popup-model>
          </div>
        </div>

        <!-- Service Included -->
        <div class="mb-3 pkg-card">
          <p class="form-para mb-2">Services Included</p>
          <div class="services">
            <label
              v-for="item in packageServices"
              :key="item._id"
              class="services_label"
            >
              <input
                @change.prevent="
                  manageThemesAndService('services_included', item)
                "
                type="checkbox"
                class="services_checkbox"
                :checked="packageData.services_included.includes(item._id)"
              />
              <span class="services_span">{{ item.name }}</span>
            </label>
          </div>
          <div v-if="updateMode" class="text-right">
            <a
              @click.prevent="updatePkgDetails('services_included')"
              href="javascript:void(0)"
              class="btn btn-submit ripple"
              >Update</a
            >
          </div>
        </div>

        <div class="mb-3 pkg-card">
          <!-- Inclusions -->
          <todoListVue
            :toolbarSettings="toolbarSettings"
            @onMoveUp="(data, index) => moveItemUpDown(data, 'up', index)"
            @onMoveDown="(data, index) => moveItemUpDown(data, 'Down', index)"
            @onAdd="addPkgIncAndExlu('inclusions')"
            @onRemove="index => removePkgIncAndExlu('inclusions', index)"
            name="Inclusions"
            v-model.trim="inclusionsText"
            :items="packageData.inclusions"
          />
          <div v-if="updateMode" class="text-right">
            <a
              @click.prevent="updatePkgDetails('inclusions')"
              href="javascript:void(0)"
              class="btn btn-submit ripple"
              >Update</a
            >
          </div>
        </div>
        <div class="mb-3 pkg-card">
          <!-- Exclusions -->
          <todoListVue
            :toolbarSettings="toolbarSettings"
            @onMoveUp="(data, index) => moveItemUpDown(data, 'up', index)"
            @onMoveDown="(data, index) => moveItemUpDown(data, 'Down', index)"
            @onAdd="addPkgIncAndExlu('exclusions')"
            @onRemove="index => removePkgIncAndExlu('exclusions', index)"
            name="Exclusions"
            v-model.trim="exclusionsText"
            :items="packageData.exclusions"
          />
          <div v-if="updateMode" class="text-right">
            <a
              @click.prevent="updatePkgDetails('exclusions')"
              href="javascript:void(0)"
              class="btn btn-submit ripple"
              >Update</a
            >
          </div>
        </div>

        <div class="mb-3 pkg-card">
          <p class="form-para mb-2">Package Itinerary</p>
          <ul
            v-if="packageData.itinerary"
            class="service-details pkg-iten-section"
          >
            <li
              v-for="(item, index) in $v.packageData.itinerary.$each.$iter"
              :key="item.order.$model"
              class="row gutter14"
            >
              <div
                class="col-2 form-group"
                :class="{ invalid: item.heading.$error }"
              >
                <label class="form-para mb-2 required">Heading</label>
                <input
                  @blur="item.heading.$touch()"
                  v-model.trim="item.heading.$model"
                  type="text"
                  class="form-control"
                />
                <span
                  class="text-muted"
                  v-if="item.heading.$error && !item.heading.required"
                  >Heading is Required</span
                >
              </div>
              <div class="col-9" style="margin-bottom: 10px">
                <p class="form-para mb-2">Details</p>
                <vue-editor
                  :editor-toolbar="toolbarSettings"
                  v-model.trim="item.details.$model"
                />
              </div>
              <div class="col-1">
                <div class="pkg-iten">
                  <!-- Arrow Button Up Direction -->
                  <v-arrowbtn
                    v-on:onclick="
                      moveItemUpDown(packageData.itinerary, 'up', index)
                    "
                    type="up"
                  />

                  <!-- Arrow Button Down Direction -->
                  <v-arrowbtn
                    type="down"
                    v-on:onclick="
                      moveItemUpDown(packageData.itinerary, 'down', index)
                    "
                  />
                  <span
                    @click.prevent="removePkgIncAndExlu('itinerary', index)"
                    class="li-close"
                    >×</span
                  >
                </div>
              </div>
            </li>
          </ul>
          <div class="text-right">
            <a
              @click.prevent="addItinerary"
              href="javascript:void(0)"
              class="btn btn-submit ripple"
              >Add Itinerary</a
            >
            <button
              :disabled="$v.$invalid"
              v-if="updateMode"
              @click.prevent="updatePkgDetails('itinerary')"
              href="javascript:void(0)"
              class="btn btn-submit ripple"
            >
              Update
            </button>
          </div>
          <hr v-if="updateMode" />
        </div>
      </div>
    </div>
    <div class="mb-3 pkg-card">
      <div class="my-4">
        <!-- Package Offer -->
        <todoListVue
          :toolbarSettings="toolbarSettings"
          @onMoveUp="(data, index) => moveItemUpDown(data, 'up', index)"
          @onMoveDown="(data, index) => moveItemUpDown(data, 'Down', index)"
          @onAdd="addPkgIncAndExlu('package_offers')"
          @onRemove="index => removePkgIncAndExlu('package_offers', index)"
          name="Offers"
          v-model.trim="package_offersText"
          :items="packageData.package_offers"
        />
        <p v-if="updateMode" class="text-right">
          <a
            @click.prevent="updatePkgDetails('package_offers')"
            href="javascript:void(0)"
            class="btn btn-submit ripple"
            >Update</a
          >
        </p>
      </div>
      <!-- Important Info Insert -->
      <p class="form-para mb-2 text-uppercase">Important Info</p>
      <div class="important-info-editor">
        <vue-editor
          :editor-toolbar="toolbarSettings"
          v-model.trim="packageData.important_info"
        />
        <a
          @click.prevent="updatePkgDetails('important_info')"
          href="javascript:void(0)"
          style="width: 70px"
          class="btn btn-submit ripple btn-hotel mt-2 float-right"
          >Update</a
        >
        <div class="clearfix"></div>
      </div>
    </div>
    <div class="mb-3 pkg-card">
      <p class="form-para mb-2 text-uppercase">Additional Info</p>
      <vue-editor
        :editor-toolbar="toolbarSettings"
        v-model.trim="packageData.additional_information"
      />
      <div v-if="updateMode" class="text-right mt-2">
        <a
          @click.prevent="updatePkgDetails('additional_information')"
          href="javascript:void(0)"
          class="btn btn-submit ripple"
          >Update</a
        >
      </div>
    </div>
    <div class="mb-3 pkg-card">
      <div class="row">
        <div class="col-sm-3">
          <p class="form-para mb-2 text-uppercase">
            Package Terms &amp; Conditions
          </p>
        </div>
        <div class="col-sm-9">
          <div class="rc-group inclusion-checkbox text-right">
            <label
              v-for="item in packageData.extraData"
              :key="item.dest_id"
              class="rc-control rc-control-checkbox checkbox-margin"
            >
              <input
                v-if="item.isValid"
                @change.prevent="
                  manageTncAndVisaInfo('terms_conditions', item.dest_id)
                "
                autocomplete="off"
                :checked="
                  checkVisaAndTncSelected('terms_conditions', item.dest_id)
                "
                type="checkbox"
              />
              <div v-if="item.isValid" class="rc-control-indicator"></div>
              <span v-if="item.isValid" id="Span14">{{ item.name }}</span>
              <span
                v-else
                style="cursor: not-allowed"
                title="terms and conditions is not available"
                id="Span14"
                >{{ item.name }}</span
              >
            </label>
          </div>
        </div>
      </div>
      <vue-editor
        :editor-toolbar="toolbarSettings"
        v-model.trim="packageData.terms_conditions.text"
      />
      <div v-if="updateMode" class="text-right mt-2">
        <a
          @click.prevent="updatePkgDetails('terms_conditions')"
          href="javascript:void(0)"
          class="btn btn-submit ripple"
          >Update</a
        >
      </div>
    </div>
    <div class="mb-3 pkg-card">
      <div class="row">
        <div class="col-sm-3">
          <p class="form-para mb-2 text-uppercase">Visa Information</p>
        </div>
        <div class="col-sm-9">
          <div class="rc-group inclusion-checkbox text-right">
            <label
              v-for="item in packageData.extraData"
              :key="item.dest_id"
              class="rc-control rc-control-checkbox checkbox-margin"
            >
              <input
                v-if="item.isValid"
                @change.prevent="
                  manageTncAndVisaInfo('visa_information', item.dest_id)
                "
                autocomplete="off"
                :checked="
                  checkVisaAndTncSelected('visa_information', item.dest_id)
                "
                type="checkbox"
              />
              <div v-if="item.isValid" class="rc-control-indicator"></div>
              <span v-if="item.isValid" id="Span14">{{ item.name }}</span>
              <span
                v-else
                style="cursor: not-allowed"
                title="visa information is not available"
                id="Span14"
                >{{ item.name }}</span
              >
            </label>
          </div>
        </div>
      </div>
      <vue-editor
        :editor-toolbar="toolbarSettings"
        v-model.trim="packageData.visa_information.text"
      />
      <p v-if="updateMode" class="text-right mt-2">
        <a
          @click.prevent="updatePkgDetails('visa_information')"
          href="javascript:void(0)"
          class="btn btn-submit ripple"
          >Update</a
        >
      </p>
    </div>
    <div class="mb-3 pkg-card">
      <div class="row">
        <div class="col-sm-3">
          <p class="form-para mb-2 text-uppercase">Documents Information</p>
        </div>
        <div class="col-sm-9">
          <div class="rc-group inclusion-checkbox text-right">
            <label
              v-for="item in packageData.extraData"
              :key="item.dest_id"
              class="rc-control rc-control-checkbox checkbox-margin"
            >
              <input
                v-if="item.isValid"
                @change.prevent="
                  manageTncAndVisaInfo('documents_required', item.dest_id)
                "
                autocomplete="off"
                :checked="
                  checkVisaAndTncSelected('documents_required', item.dest_id)
                "
                type="checkbox"
              />
              <div v-if="item.isValid" class="rc-control-indicator"></div>
              <span v-if="item.isValid" id="Span14">{{ item.name }}</span>
              <span
                v-else
                style="cursor: not-allowed"
                title="douments is not available"
                id="Span14"
                >{{ item.name }}</span
              >
            </label>
          </div>
        </div>
      </div>
      <vue-editor
        :editor-toolbar="toolbarSettings"
        v-model.trim="packageData.documents_required.text"
      />
      <div v-if="updateMode" class="text-right mt-2">
        <a
          @click.prevent="updatePkgDetails('documents_required')"
          href="javascript:void(0)"
          class="btn btn-submit ripple"
          >Update</a
        >
      </div>
    </div>
    <div class="text-center mt-4">
      <a
        @click.prevent="handlePublishPackage"
        href="javascript:void(0)"
        class="btn btn-submit ripple"
        >Publish Package</a
      >
      <a
        @click.prevent="manageAdvancePackage"
        href="javascript:void(0)"
        class="btn btn-submit ripple"
      >
        Continue to Advance Package Builder
        <span class="font-bold">➝</span>
      </a>
      <a
        @click.prevent="$router.push({ name: 'packagelisting' })"
        style="color: white"
        class="btn btn-submit btn-cancel ripple"
        >Cancel</a
      >
    </div>
    <p class="text-center mt-4 fnt-12 clr-red">
      <b>Important:</b>Your package creation is done you can publish it OR
      proceed to "ADVANCE PACKAGE BUILDER". <br />You have to fill all details
      about services mentioned above eg: flights &amp; hotels etc. in Advance
      package.
    </p>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import todoListVue from "./components/todo-list.vue";
import holidaysCategory from "./components/holidays-category.vue";
import Data from "./helpers/data";
import Methods from "./helpers/methods";
import { required } from "vuelidate/lib/validators";
export default {
  mixins: [Data, Methods],
  name: "PackageDetauls",
  components: {
    todoListVue,
    holidaysCategory
  },
  props: {
    updateMode: {
      type: Boolean
    }
  },
  watch: {
    packageDetails(packages) {
      if (packages.code) {
        this.packageData.inclusions = packages.inclusions
          ? packages.inclusions
          : [];
        this.packageData.exclusions = packages.exclusions
          ? packages.exclusions
          : [];
        this.packageData.itinerary = packages.itinerary
          ? packages.itinerary
          : [];
        this.packageData.images = packages.images ? packages.images : [];

        this.packageData.documents_required = packages.documents_required
          ? packages.documents_required
          : {
              text: "",
              items: [],
              others: []
            };
        this.packageData.terms_conditions = packages.terms_conditions
          ? packages.terms_conditions
          : {
              text: "",
              others: []
            };
        this.packageData.visa_information = packages.visa_information
          ? packages.visa_information
          : {
              text: "",
              others: []
            };
        this.packageData.additional_information = packages.additional_information
          ? packages.additional_information
          : "";
        this.packageData.important_info = packages.important_info
          ? packages.important_info
          : "";
        this.packageData.package_offers = packages.package_offers
          ? packages.package_offers
          : [];
        this.packageData.themes = this.packageDetails.themes.map(
          data => data._id
        );
        this.packageData.services_included = this.packageDetails.services_included.map(
          data => data._id
        );
        let extraData = [];
        for (const item of this.packageDetails.pkg_destinations) {
          const index = this.packageDetails.extraData.findIndex(
            val => val.dest_id == item.countryId
          );
          if (extraData.findIndex(val => val.dest_id == item.countryId) == -1) {
            if (index != -1) {
              extraData.push({
                dest_id: item.countryId,
                name: item.countryName,
                isValid: true
              });
            } else {
              extraData.push({
                dest_id: item.countryId,
                name: item.countryName,
                isValid: false
              });
            }
          }
        }
        this.packageData.extraData = extraData;
      }
    }
  },
  computed: {
    ...mapGetters([
      "packageServices",
      "packageDetails",
      "pkgThemes",
      "mediaLoading",
      "mediaList"
    ]),
    renderMediaList() {
      const mediaList = this.mediaList.find(
        data => data.dest_id == this.selectedMediaTab
      );
      if (mediaList) {
        if (this.filterList.length > 0) {
          const images = mediaList.images.reduce((initArr, data) => {
            const findItem = this.filterList.findIndex(item => {
              const regex = new RegExp(item, "i");
              return (
                data.tags.findIndex(val => regex.test(val)) != -1 ||
                regex.test(this.filterImagesName(data.name))
              );
            });
            if (findItem != -1) {
              initArr.push(data);
            }
            return initArr;
          }, []);
          return {
            ...mediaList,
            images
          };
        } else {
          return mediaList;
        }
      } else {
        return {
          images: []
        };
      }
    },
    mediaServerPath() {
      return process.env.VUE_APP_MEDIA_SERVER_URL;
    },
    renderTheme() {
      return this.pkgThemes.filter(data => data.icon_type == "Theme");
    },
    renderCategory() {
      return this.pkgThemes.filter(data => data.icon_type == "Category");
    }
  },
  methods: {
    ...mapMutations(["activeService"]),
    ...mapActions([
      "updatePackage",
      "makePackagePublishRequest",
      "getPackageMediaList"
    ]),
    filterImagesName(name) {
      return name.replace(/-/g, " ");
    },
    handleFilterByText(type, index) {
      if (
        this.filterText != "" &&
        type == "add" &&
        this.filterList.length < 5 &&
        !this.filterList.includes(this.filterText.toLowerCase())
      ) {
        this.filterList.push(this.filterText.toLowerCase());
        this.filterText = "";
        this.filterMediaList();
      } else if (type == "remove") {
        this.filterList.splice(index, 1);
        this.filterMediaList();
      } else if (
        this.filterText != "" &&
        this.filterList.includes(this.filterText.toLowerCase())
      ) {
        this.$toasted.clear();
        this.$toasted.info("Item Already Exists", {
          position: "bottom-center",
          duration: 1500
        });
      } else if (this.filterText != "") {
        this.$toasted.clear();
        this.$toasted.info("Only 5 Items Allowed", {
          position: "bottom-center",
          duration: 1500
        });
      }
    },
    filterMediaList() {
      const mediaList = this.mediaList.find(
        data => data.dest_id == this.selectedMediaTab
      );
      if (mediaList) {
        if (this.filterList.length > 0) {
          const images = mediaList.images.reduce((initArr, data) => {
            const findItem = this.filterList.findIndex(item => {
              const regex = new RegExp(item, "i");
              return (
                data.tags.findIndex(val => regex.test(val)) != -1 ||
                regex.test(data.name)
              );
            });
            if (findItem != -1) {
              initArr.push(data);
            }
            return initArr;
          }, []);
          return {
            ...mediaList,
            images
          };
        } else {
          return mediaList;
        }
      } else {
        return {
          images: []
        };
      }
    }
  },
  validations: {
    packageData: {
      itinerary: {
        $each: {
          order: {},
          heading: { required },
          details: { required }
        }
      }
    }
  }
};
</script>

<style scoped>
.rc-control-indicator {
  height: 13px;
  width: 13px;
}
.rc-control-indicator::after {
  left: 4px;
  top: 0px;
}
.checkbox-margin {
  margin: 0 10px 5px 0 !important;
}
.data-loader {
  position: relative;
}
.data-loader >>> .lds-spinner {
  height: 0px;
  width: 0px;
  display: unset;
  position: absolute;
  right: 50px;
  top: -18px;
}

.data-loader >>> .lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 23px;
  left: 31px;
  width: 2px;
  height: 6px;
  border-radius: 20%;
  background: black;
}

.media-popup >>> .popup_body {
  max-width: 100%;
  max-height: 100%;
  height: 95%;
}

.selected-images {
  margin-bottom: 0px;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  padding: 0px;
  height: 415px;
  overflow: hidden;
  overflow-y: auto;
}
.selected-images li {
  margin-bottom: 8px;
  position: relative;
  margin-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 145px;
  text-align: center;
  line-height: 1;
}
.selected-images li label {
  position: absolute;
}
.selected-images li p {
  font-size: 11px;
  text-transform: capitalize;
}

.service-details >>> .ql-editor {
  min-height: 125px !important;
}

.img-responsive {
  height: auto;
  width: 100%;
}

.important-info-editor >>> .ql-editor {
  min-height: 50px;
  padding: 10px;
  font-size: 13px;
}

.search-input-photo {
  top: 1px;
  right: 15px;
}

span.chips {
  background-color: rgb(23, 162, 184);
  padding: 1px 5px 1px 5px;
  display: inline-block;
  cursor: default;
  font-size: 12px;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 5px;
}
</style>
