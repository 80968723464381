<template>
  <container headTitle="Supplier Extra Details">
    <div slot="content">
      <div class="row justify-content-center" style="width: 99%">
        <div class="col-md-10">
          <div class="form-group">
            <label for="visainfo">Terms and Conditions</label>
            <vue-editor
              :editor-toolbar="toolbarSettings"
              v-model="formdata.terms_conditions"
              class="mb-3"
            />
            <button
              @click.prevent="handleUpdateDetails"
              type="submit"
              class="btn btn-info btnwidth"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ExtraDetails",
  data() {
    return {
      formdata: {
        terms_conditions: ""
      },
      toolbarSettings: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [{ header: 1 }, { header: 2 }],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ]
      ]
    };
  },
  computed: {
    ...mapGetters(["user"])
  },
  created() {
    this.agencyTermsCondition(this.user.agencyid).then(data => {
      if (data) {
        delete data.__typename;
        this.formdata = {
          ...this.formdata,
          ...data
        };
      }
    });
  },
  methods: {
    ...mapActions(["updateAgencyTerms", "agencyTermsCondition"]),
    handleUpdateDetails() {
      this.updateAgencyTerms({
        ...this.formdata,
        agencyid: this.user.agencyid
      }).then(data => {
        this.$toasted.success("Terms And Conditions Updated", {
          position: "bottom-center",
          duration: 1500
        });
      });
    }
  }
};
</script>
