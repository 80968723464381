<template>
  <vue-autosuggest
    :suggestions="filteredOptions"
    @selected="onSelected"
    :limit="50"
    @input="onInputChange"
    :input-props="inputProps"
    :get-suggestion-value="getSuggestionValue"
  >
    <div slot-scope="{ suggestion }" style="display:flex;alignItems:center">
      <span style="color: navyblue">
        {{ suggestion.item.name }}
        <span
          v-if="suggestion.item.countryName"
          style="fontSize: 10;color:var(--red)"
          >({{ suggestion.item.stateName }},
          {{ suggestion.item.countryName }})</span
        >
      </span>
    </div>
  </vue-autosuggest>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest";
import { mapActions } from "vuex";
export default {
  name: "Autosuggest",
  components: {
    VueAutosuggest
  },
  props: {
    placeholder: {
      type: String,
      default: "Default Text"
    },
    asyncData: {
      type: Function
    },
    selectedData: {
      type: Object
    },
    updateMode: {
      type: Boolean
    },
    selectInput: {
      type: Boolean,
      default: false
    },
    className: {
      type: String
    }
  },
  watch: {
    /* selectedData(New) {
      document.querySelector(
        `#${this.placeholder.replace(/ /g, "")}`
      ).value = New.destinationName ? New.destinationName : "";
    } */
  },
  async created() {
    this.destinations = await this.getPackageDestinationsCities();
  },
  data() {
    return {
      destinations: [],
      selected: "",
      filteredOptions: [],
      inputProps: {
        disabled: this.updateMode,
        id: `${this.placeholder.replace(/ /g, "")}`,
        style: "margin:0",
        class: "form-control" + " " + this.className,
        type: "text",
        placeholder: this.placeholder
      },
      limit: 50
    };
  },
  methods: {
    ...mapActions(["getPackageDestinationsCities"]),
    /* Auto Suggest Methods */
    onSelected(option, clear) {
      if (clear != -1) {
        this.selected = option.item;
        this.$emit("onSelectItem", option.item);
      } else {
        return clear;
      }
    },
    getSuggestionValue: function(data) {
      if (!this.selectInput) {
        return "";
      } else {
        return data.item.destinationName;
      }
    },
    async onInputChange(text) {
      this.selected = "";
      this.$emit("changeValue", text);
      if (text === "" || text === undefined || text.length < 3) {
        return;
      }
      // Full customizability over filtering
      const filteredData = this.destinations
        .filter(option => {
          return option.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);

      this.filteredOptions = [
        {
          data: filteredData.sort((a, v) => {
            return v.destinationName.length >= text.length ? 0 : 1;
          })
        }
      ];
    }
  }
};
</script>

<style></style>
