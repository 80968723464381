<template>
  <div id="cruise_details" class="collapse" data-parent="#services_accordion">
    <div class="card-body">
      <div v-for="(item, index) in $v.packageCruise.$each.$iter" :key="item.orderId.$model" class="row gutter14 border-btms">
        <div class="col-3 form-group" :class="{ invalid: item.boarding.city.$error }">
          <label class="form-para mb-2 required">Boarding City/Country</label>
          <select @blur="item.boarding.city.$touch()" v-model.trim="item.boarding.city.$model" class="form-control">
            <option value>Choose</option>
            <option v-for="city in destinations" :key="city.id" :value="city.id">{{ city.destinationName }}</option>
          </select>
          <span v-if="item.boarding.city.$error && !item.boarding.city.required" class="text-muted">boarding city must be required</span>
        </div>
        <div class="col-3 form-group" :class="{ invalid: item.deboarding.city.$error }">
          <label class="form-para mb-2 required">Deboarding City/Country</label>
          <select @blur="item.deboarding.city.$touch()" v-model.trim="item.deboarding.city.$model" class="form-control">
            <option value>Choose</option>
            <option v-for="city in destinations" :key="city.id" :value="city.id">{{ city.destinationName }}</option>
          </select>
          <span v-if="item.deboarding.city.$error && !item.deboarding.city.required" class="text-muted">deboarding city must be required</span>
        </div>
        <div class="col-3">
          <label class="form-para mb-2">Boarding Time</label>
          <input v-model.trim="item.boarding.time.$model" type="time" class="form-control" />
        </div>
        <div class="col-3">
          <label class="form-para mb-2">Deboarding Time</label>
          <input v-model.trim="item.deboarding.time.$model" type="time" class="form-control" />
        </div>
        <div class="col-2 form-group">
          <label class="form-para mb-2">Duration of Stay</label>
          <select v-model.trim="item.stay.$model" class="form-control">
            <option value>Choose</option>
            <option v-for="number in (0, packageDetails.total_nights)" :key="number" :value="number">{{ number }} Night</option>
          </select>
        </div>
        <div class="col-2">
          <label class="form-para mb-2">Cruise Cabin Type</label>
          <input v-model.trim="item.cabintype.$model" type="text" class="form-control" />
        </div>
        <div class="col">
          <p class="text-right">
            <a
              v-if="index >= 1"
              @click.prevent="managePackageCruise('remove', index)"
              href="javascript:void(0)"
              class="btn btn-submit ripple btn-hotel"
              >Remove Cruise</a
            >
          </p>
        </div>
        <div class="col-12">
          <label class="form-para mb-2">Details</label>
          <vue-editor :editor-toolbar="toolbarSettings" v-model.trim="item.details.$model" />
        </div>
      </div>
      <div class="col">
        <p class="text-right">
          <a @click.prevent="managePackageCruise('add')" href="javascript:void(0)" class="btn btn-submit ripple btn-hotel">Add Cruise</a>
        </p>
      </div>
      <p v-if="packageCruise.length > 0" class="text-center mt-4">
        <button :disabled="$v.$invalid" @click="handleSaveData" href="javascript:void(0)" class="btn btn-submit ripple mb-0">
          Save
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  name: "Cruise",
  computed: {
    ...mapGetters(["packageCruise", "packageDetails"])
  },
  data() {
    return {
      destinations: [],
      toolbarSettings: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [{ header: 1 }, { header: 2 }],
        [{ script: "sub" }, { script: "super" }],
        [{ direction: "rtl" }],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        ["link", "clean"]
      ]
    };
  },
  validations: {
    packageCruise: {
      $each: {
        orderId: {},
        boarding: { city: { required }, time: {} },
        deboarding: { city: { required }, time: {} },
        stay: {},
        cabintype: {},
        details: {}
      }
    }
  },
  async created() {
    this.destinations = await this.getPackageDestinationsCities();
  },
  methods: {
    ...mapActions(["updatePackage", "getPackageDestinationsCities"]),
    ...mapMutations(["managePackageServices"]),
    handleSaveData() {
      this.updatePackage({
        type: "ADVANCE",
        data: {
          advance: {
            code: this.$route.query.code,
            data: { cruise: this.packageCruise }
          }
        }
      }).then(() => {
        this.$toasted.success(`update Cruise Details`, {
          position: "bottom-center",
          duration: 5000
        });
      });
    },
    managePackageCruise(type, index) {
      this.managePackageServices({
        type,
        index,
        service: "cruise",
        value: {
          orderId: Math.random(),
          boarding: { city: "", time: "" },
          deboarding: { city: "", time: "" },
          stay: "",
          cabintype: "",
          details: ""
        }
      });
    }
  }
};
</script>

<style scoped>
.border-btms {
  border-bottom: 1px solid #e9ebee;
  margin-bottom: 10px;
}

.form-control {
  margin: 0px !important;
}

.form-group.invalid {
  margin-bottom: 0px !important;
}
</style>
