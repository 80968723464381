<template>
  <div>
    <h6 class="mt-2">Add Discount :</h6>
    <div class="row gutter14">
      <div class="col-10">
        <div class="row gutter14">
          <div class="col-3">
            <div class="row no-gutters">
              <div class="col-4">
                <label class="form-para font-normal mt-2">Adult : </label>
              </div>
              <div class="col-8">
                <input
                  v-model.number="discountComp.adult"
                  type="number"
                  min="0"
                  pattern="[0-9]"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row no-gutters">
              <div class="col-4">
                <label class="form-para font-normal mt-2">CNB : </label>
              </div>
              <div class="col-8">
                <input
                  v-model.number="discountComp.cnb"
                  type="number"
                  min="0"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row no-gutters">
              <div class="col-4">
                <label class="form-para font-normal mt-2">CWB : </label>
              </div>
              <div class="col-8">
                <input
                  v-model.number="discountComp.cwb"
                  type="number"
                  min="0"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row no-gutters">
              <div class="col-4">
                <label class="form-para font-normal mt-2">Infant : </label>
              </div>
              <div class="col-8">
                <input
                  v-model.number="discountComp.infant"
                  type="number"
                  min="0"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2">
        <button
          @click="handleOnSave"
          type="button"
          :class="{ loading: updatingDiscount }"
          :disabled="CheckSaveVales || updatingDiscount"
          class="btn btn-info px-4 mx-2 submit-btn"
        >
          Save
          <div v-if="updatingDiscount" class="data-loader">
            <Loader />
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AddDiscount",
  props: {
    CheckSaveVales: { type: Boolean },
    discount: { type: Object }
  },
  computed: {
    ...mapGetters(["updatingDiscount"]),
    discountComp() {
      return this.discount;
    }
  },
  methods: {
    handleOnSave() {
      this.$emit("handleOnSave");
    }
  }
};
</script>

<style scoped>
.submit-btn {
  position: relative;
  transition: 0.7s all;
}
.submit-btn.loading {
  width: 80px;
  text-align: left;
  padding-left: 10px !important;
  padding-right: 5px !important;
}
.data-loader {
  position: absolute;
  top: 2px;
  right: -2px;
}
.data-loader >>> .lds-spinner {
  height: 0px;
  width: 0px;
  display: unset;
  position: absolute;
  right: 50px;
  top: -18px;
}

.data-loader >>> .lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 23px;
  left: 31px;
  width: 2px;
  height: 6px;
  border-radius: 20%;
  background: black;
}
</style>
