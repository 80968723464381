<template>
  <container headTitle="Create User">
    <div slot="content">
      <form @submit.prevent="onSubmit">
        <div class="row justify-content-center" style="width: 99%">
          <div class="col-md-4">
            <div class="form-group" :class="{ invalid: $v.name.$error }">
              <label for="name">Name</label>
              <input
                type="text"
                id="name"
                class="form-control"
                v-model.trim="name"
                @blur="$v.name.$touch()"
              />
              <span
                class="text-muted"
                v-if="$v.name.$error && !$v.name.required"
                >Name is required.</span
              >
            </div>
            <div class="form-group" :class="{ invalid: $v.email.$error }">
              <label for="email">Email Address</label>
              <input
                type="email"
                id="email"
                class="form-control"
                v-model.trim="email"
                @blur="$v.email.$touch()"
              />
              <span
                class="text-muted"
                v-if="$v.email.$error && !$v.email.required"
                >Email is required.</span
              >
              <span v-if="!$v.email.email" class="text-muted"
                >Please provide a valid email address.</span
              >
            </div>
            <div class="form-group" :class="{ invalid: $v.username.$error }">
              <label for="username">User Name</label>
              <input
                type="text"
                id="username"
                class="form-control"
                v-model.trim="username"
                :disabled="updatedata"
                @blur="$v.username.$touch()"
              />
              <span class="text-muted" v-if="!$v.username.minLen"
                >User Name is required and must be greater than 6
                characters.</span
              >
              <span
                class="text-muted"
                v-if="!updatedata && !$v.username.isUnique"
                >User Name Already Exists</span
              >
            </div>
            <div
              v-if="!updatedata"
              class="form-group"
              :class="{ invalid: $v.password.$error }"
            >
              <label for="password">Password</label>
              <input
                type="password"
                id="password"
                class="form-control"
                v-model.trim="password"
                @blur="$v.password.$touch()"
              />
              <span class="text-muted" v-if="!$v.password.minLen"
                >Must be greater than or equal to 7 characters long.</span
              >
            </div>
            <div
              v-if="!updatedata"
              class="form-group"
              :class="{ invalid: $v.confirmpassword.$error }"
            >
              <label for="confirmpass">Confirm Password</label>
              <input
                type="password"
                id="confirmpassword"
                class="form-control"
                v-model.trim="confirmpassword"
                @blur="$v.confirmpassword.$touch()"
              />
              <span class="text-muted">Must be same as password.</span>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-check">
                    <input
                      type="checkbox"
                      id="isactive"
                      class="form-check-input"
                      v-model="isActive"
                    />
                    <label for="isactive" class="form-check-label"
                      >Is Active</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <menu-item pageUrl="/users/create">
              <button
                type="submit"
                class="btn btn-submit ripple m-0"
                :disabled="$v.$invalid"
              >
                {{ updatedata ? "Save" : "Submit" }}
              </button>
              <button
                @click="cancel()"
                class="btn btn-submit btn-cancel ripple ml-3 m-0"
              >
                Cancel
              </button>
            </menu-item>
          </div>
        </div>
      </form>
    </div>
  </container>
</template>

<script>
import {
  required,
  requiredIf,
  email,
  minLength,
  sameAs
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
export default {
  name: "createuser",
  data() {
    return {
      name: "",
      username: "",
      email: "",
      password: "",
      confirmpassword: "",
      createdBy: "",
      updatedBy: "",
      isActive: true,
      updatedata: false
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["userExists", "user"])
  },
  validations: {
    name: {
      required
    },
    username: {
      required: requiredIf(function() {
        return !this.updatedata;
      }),
      minLen: minLength(7),
      isUnique: function(val) {
        if (!this.updatedata) {
          if (val === "") return true;
          this.$store.dispatch("existingUser", val);
          return !this.userExists;
        } else {
          return true;
        }
      }
    },
    email: {
      required,
      email
    },
    password: {
      required: requiredIf(function() {
        return !this.updatedata;
      }),
      minLen: minLength(7)
    },
    confirmpassword: {
      sameAs: sameAs("password")
    }
  },
  created() {
    if (this.$route.query.user) {
      this.$store
        .dispatch("getSupplierUserById", this.$route.query.user)
        .then(data => {
          for (let item in data) {
            this[item] = data[item];
          }
        });
      this.updatedata = true;
    }
  },
  methods: {
    onSubmit() {
      const formData = {
        name: this.name,
        username: this.username,
        email: this.email,
        password: this.password,
        isActive: this.isActive
        // userType: this.userType,
        // createdBy: this.user.id,
        // updatedBy: this.user.id,
      };
      if (!this.updatedata) {
        this.$store.dispatch("createSupplierUser", formData);
      } else {
        this.$store.dispatch("updateSupplierUser", {
          name: this.name,
          email: this.email,
          isActive: formData.isActive,
          // updatedBy: this.user.id,
          id: this.id
        });
      }
    },
    cancel() {
      this.$router.push({ path: "/users/listing" });
    }
  }
};
</script>
<style scoped>
.btnwidth {
  width: 100px;
}

.btn-submit {
  border-radius: 0;
  padding: 6px 25px;
  background: #17a2b8;
  color: #fff;
  margin: 0 5px;
}

.btn-cancel {
  background: #f56d6d;
}
</style>
