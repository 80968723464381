<template>
  <div>
    <p class="form-para mb-2 text-uppercase">Package {{ name }}</p>
    <div class="vue-editor">
      <vue-editor :editor-toolbar="toolbarSettings" v-model.trim="inputText" />
    </div>
    <a
      @click.prevent="handleCancelData"
      v-if="selectedIndex != null"
      href="javascript:void(0)"
      style="width: 70px;border-radius: 0;"
      class="btn btn-danger ripple btn-hotel mt-3 float-right"
    >
      Cancel</a
    >
    <a
      @click.prevent="handleAddData"
      href="javascript:void(0)"
      style="width: 70px;"
      class="btn btn-submit ripple btn-hotel mt-3 float-right"
    >
      {{ selectedIndex == null ? "Add" : "Update" }}</a
    >
    <div class="clearfix"></div>
    <ul class="pkg-inc-list">
      <li v-for="(item, index) in itemsComp" :key="item">
        <p
          @click.passive="handleEditData(item, index)"
          class="m-0"
          v-html="item"
        ></p>
        <!-- Arrow Button Down Direction -->
        <v-arrowbtn type="down" v-on:onclick="onMoveDown(index)" />

        <!-- Arrow Button Up Direction -->
        <v-arrowbtn v-on:onclick="onMoveUp(index)" type="up" />
        <span @click.prevent="onRemove(index)" class="li-close">×</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TodoList",
  data() {
    return {
      isEditMode: false,
      selectedIndex: null,
      inputText: ""
    };
  },
  model: {
    prop: "text",
    event: "input"
  },
  watch: {
    inputText(value) {
      this.$emit("input", value);
    }
  },
  computed: {
    itemsComp() {
      return this.items;
    }
  },
  props: {
    toolbarSettings: { type: Array },
    name: {
      type: String
    },
    items: {
      type: Array
    },
    text: {
      type: String
    }
  },
  methods: {
    onMoveUp(index) {
      this.$emit("onMoveUp", this.itemsComp, index);
    },
    onMoveDown(index) {
      this.$emit("onMoveDown", this.itemsComp, index);
    },
    onRemove(index) {
      this.$emit("onRemove", index);
    },
    getHtmlContent(content) {
      let divElem = document.createElement("div");
      divElem.innerHTML = content;
      let inputText = divElem.textContent || divElem.innerText || "";
      return inputText.trim();
    },
    handleAddData() {
      if (this.selectedIndex == null) {
        this.$emit("onAdd");
        this.selectedIndex = null;
        this.isEditMode = false;
        this.inputText = "";
      } else {
        if (this.getHtmlContent(this.inputText) != "") {
          this.itemsComp[this.selectedIndex] = this.inputText;
          this.selectedIndex = null;
          this.isEditMode = false;
          this.inputText = "";
        }
      }
    },
    handleEditData(data, index) {
      this.isEditMode = true;
      this.inputText = data;
      this.selectedIndex = index;
    },
    handleCancelData() {
      this.selectedIndex = null;
      this.isEditMode = false;
      this.inputText = "";
    }
  }
};
</script>

<style scoped>
.vue-editor >>> .ql-editor {
  min-height: 50px;
  padding: 10px;
  font-size: 13px;
}
</style>
