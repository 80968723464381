<template>
  <container headTitle="Manage Information">
    <menu-item slot="createbtn" pageUrl="/masters/info/create">
      <router-link
        class="btn btn-sm btn-info float-right"
        to="/masters/info/create"
        >Add New Info</router-link
      >
    </menu-item>
    <div slot="content">
      <table class="table">
        <thead class="table-teal">
          <tr>
            <th style="width: 120px">Destination</th>
            <th>Visa Info</th>
            <th>Documents</th>
            <th>Terms$Conditions</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody class="info" v-if="information.length > 0">
          <tr v-for="info in information" :key="info.dest_id">
            <td title="Unlimited Memories at Bangkok">
              {{ info.destName }}
            </td>
            <td
              :title="info.visa_info ? info.visa_info.text : ''"
              v-html="info.visa_info ? info.visa_info.text : ''"
            ></td>
            <td
              :title="info.documents ? info.documents.text : ''"
              v-html="info.documents ? info.documents.text : ''"
            >
              {{}}
            </td>
            <td
              :title="info.terms_conditions"
              v-html="info.terms_conditions"
            ></td>
            <td title="Unlimited Memories at Bangkok">
              <a
                style="height: 20px; line-height: 1"
                class="btn btn-sm bg-info text-white"
                @click="viewDestInfo(info.dest_id)"
                >view</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "users",
  created() {
    this.handleDestInfo();
  },
  computed: {
    ...mapGetters(["information"])
  },
  methods: {
    handleDestInfo() {
      // reach out to vuex store and fire action that gets users.
      this.$store.dispatch("getDestInfo");
    },

    viewDestInfo(dest_id) {
      this.$router.push({ path: "/masters/info/create", query: { dest_id } });
    }
  }
};
</script>

<style scoped>
.overflow {
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.info >>> ul {
  padding-left: 15px;
  list-style-position: outside;
}
</style>
