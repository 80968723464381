<template>
  <container headTitle="Create Information">
    <div slot="content">
      <div class="container-fluid px-1">
        <div class="row justify-content-center" style="width: 99%">
          <div class="col-md-10">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="destination">Destination</label>
                  <select v-model="formdata.dest_id" :disabled="this.updatedata" id="parentid" class="form-control form-control-sm mb-3">
                    <option value>Choose Type</option>
                    <option v-for="item in country" :key="item.destinationId" :value="item.id">{{ item.destinationName }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-10">
            <div class="form-group">
              <label for="visainfo">Terms and Conditions</label>
              <vue-editor :editor-toolbar="toolbarSettings" v-model.trim="formdata.terms_conditions" class="mb-3" />
              <menu-item class="text-right" pageUrl="/masters/info/create">
                <button :disabled="formdata.dest_id == ''" type="submit" @click.prevent="saveinfo('terms_conditions')" class="btn btn-info btnwidth">
                  {{ this.updatedata ? "Save" : "Submit" }}
                </button>
                <button @click="$router.push('/masters/info/listing')" class="btn btn-info ml-5 btnwidth">
                  Cancel
                </button>
              </menu-item>

              <label for="visainfo">Visa Information</label>
              <vue-editor :editor-toolbar="toolbarSettings" v-model.trim="formdata.visa_info.text" class="mb-3" />

              <h6>Visa Documents</h6>
              <ul class="list-inline documents">
                <li v-for="item in formdata.visa_info.files" :key="item._id">
                  <label :for="item.filename" class="rc-control rc-control-checkbox">
                    <input :id="item.filename" v-model="item.is_package" type="checkbox" />
                    <div class="rc-control-indicator"></div>
                  </label>
                  <label class="m-0" :for="item.filename" style="text-transform: capitalize">{{ item.filename }}</label>
                </li>
              </ul>

              <menu-item class="text-right" pageUrl="/masters/info/create">
                <button :disabled="formdata.dest_id == ''" type="submit" @click.prevent="saveinfo('visa_info')" class="btn btn-info btnwidth">
                  {{ this.updatedata ? "Save" : "Submit" }}
                </button>
                <button @click="$router.push('/masters/info/listing')" class="btn btn-info ml-5 btnwidth">
                  Cancel
                </button>
              </menu-item>

              <label for="visainfo">Documents</label>
              <vue-editor :editor-toolbar="toolbarSettings" v-model.trim="formdata.documents.text" class="mb-3" />
              <ul class="list-inline documents">
                <li v-for="item in formdata.documents.files" :key="item._id">
                  <label :for="item.filename" class="rc-control rc-control-checkbox">
                    <input :id="item.filename" v-model="item.is_package" type="checkbox" />
                    <div class="rc-control-indicator"></div>
                  </label>
                  <label class="m-0" :for="item.filename" style="text-transform: capitalize">{{ item.filename }}</label>
                </li>
              </ul>

              <menu-item class="text-right" pageUrl="/masters/info/create">
                <button :disabled="formdata.dest_id == ''" type="submit" @click.prevent="saveinfo('documents')" class="btn btn-info btnwidth">
                  {{ this.updatedata ? "Save" : "Submit" }}
                </button>
                <button @click="$router.push('/masters/info/listing')" class="btn btn-info ml-5 btnwidth">
                  Cancel
                </button>
              </menu-item>
            </div>
          </div>
        </div>
      </div>
    </div>
  </container>
</template>

<script>
import { mapActions } from "vuex";
import lodash from "lodash";
export default {
  name: "createinfo",
  data() {
    return {
      formdata: {
        visa_info: {
          text: "",
          files: []
        },
        terms_conditions: "",
        documents: {
          text: "",
          files: []
        },
        dest_id: ""
      },
      messages: {
        visa_info: "Visa Info",
        terms_conditions: "Terms And Conditions",
        documents: "Doucments"
      },
      updatedata: false,
      country: [],
      toolbarSettings: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [{ header: 1 }, { header: 2 }],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }]
      ]
    };
  },
  watch: {
    "formdata.dest_id": function(New) {
      if (!this.updatedata) {
        this.$store.dispatch("getDestInfoById", New).then(data => {
          if (data.dest_id) {
            delete data.destName;
            delete data.__typename;
            this.formdata = {
              ...data,
              documents: data.documents ? data.documents : { text: "", files: [] },
              visa_info: data.visa_info ? data.visa_info : { text: "", files: [] }
            };
            this.updatedata = true;
            this.$router.push({
              path: "/masters/info/create",
              query: { dest_id: data.dest_id }
            });
          }
        });
      }
    }
  },
  async created() {
    await this.$store.dispatch("getDestinations", { type: "CONTINENT" }).then(data => {
      this.country.push(...lodash.orderBy(data.getDestinations, "destinationName"));
    });
    await this.$store.dispatch("getDestinations", { type: "COUNTRY" }).then(data => {
      this.country.push(...lodash.orderBy(data.getDestinations, "destinationName"));
    });

    if (this.$route.query.dest_id) {
      this.$store.dispatch("getDestInfoById", this.$route.query.dest_id).then(data => {
        delete data.destName;
        delete data.__typename;
        this.formdata = {
          ...data,
          documents: data.documents ? data.documents : { text: "", files: [] },
          visa_info: data.visa_info ? data.visa_info : { text: "", files: [] }
        };
      });
      this.updatedata = true;
    }
  },
  methods: {
    ...mapActions(["createAndUpdateInfo"]),
    saveinfo(type) {
      this.createAndUpdateInfo({
        type,
        [type]: this.formdata[type],
        dest_id: this.formdata.dest_id
      }).then(data => {
        if (data) {
          this.$toasted.success(`${this.messages[data.type]} of ${data.destName} is Updated successfully`, {
            position: "bottom-center",
            duration: 5000
          });
        }
      });
    }
  }
};
</script>
<style scoped>
.btnwidth {
  width: 100px;
}

.documents {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}

.documents > li {
  min-width: 145px;
  margin-right: 1rem;
  margin-bottom: 0.875rem;
}

.rc-control {
  margin: 0;
  margin-right: 1rem;
}

/* .ql-editor ul {
  padding-left: 0 !important;
}

.ql-editor ol li:not(.ql-direction-rtl),
.ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 15px !important;
}

.ql-editor li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: center !important;
} */
</style>
