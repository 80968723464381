<template>
  <div class="tab-content">
    <div class="tab-pane active" id="security">
      <div class="mb-4">
        <div class="menu-chips">
          <span
            @click="removeActiveMenu(item, index)"
            v-for="(item, index) in menuDataComp.active"
            :key="item._id"
            class="chips"
          >
            {{ item.name }}
            <button>&times;</button>
          </span>
        </div>
        <a
          v-if="menuDataComp.active.length > 0"
          @click="deSelectAllMenu"
          class="btn btn-outline-secondary btn-sm"
          >Deselect All</a
        >
      </div>
      <div class="mb-4">
        <div class="menu-chips">
          <span
            v-for="(item, index) in menuDataComp.notactive"
            @click="addActiveMenus(item, index)"
            :key="item._id"
            class="chips-disable"
            >{{ item.name }}</span
          >
        </div>
        <a
          v-if="!menuDataComp.notactive.length == 0"
          @click="selectAllMenu"
          class="btn btn-outline-secondary btn-sm"
          >Select All</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "rightmenu",
  computed: {
    ...mapGetters(["selectedMenus"]),
    menuDataComp() {
      return this.menuData;
    }
  },
  props: {
    parent: String,
    menuData: {
      type: Object,
      default: () => ({
        active: Array,
        notactive: Array
      })
    }
  },
  methods: {
    addActiveMenus(item, index) {
      this.$store.commit("setSelectedMenuItem", {
        parent: this.parent,
        childId: item._id
      });
      this.menuDataComp.active.push(item);
      this.menuDataComp.notactive.splice(index, 1);
    },
    removeActiveMenu(item, index) {
      this.$store.commit("removeSelectedMenuItem", {
        parent: this.parent,
        index
      });
      this.menuDataComp.notactive.push(item);
      this.menuDataComp.active.splice(index, 1);
    },
    selectAllMenu() {
      this.$store.commit("selectAllMenu", {
        parent: this.parent,
        items: this.menuDataComp.notactive
      });
      this.menuDataComp.active = [
        ...this.menuDataComp.active,
        ...this.menuDataComp.notactive
      ];
      this.menuDataComp.notactive = [];
    },
    deSelectAllMenu() {
      this.$store.commit("deSelectAllMenu", {
        parent: this.parent
      });
      this.menuDataComp.notactive = [
        ...this.menuDataComp.notactive,
        ...this.menuDataComp.active
      ];
      this.menuDataComp.active = [];
    }
  }
};
</script>

<style scoped>
.menu-chips {
  border: 1px solid #e0e0e0;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  overflow: hidden;
  max-height: 150px;
  min-height: 150px;
}

.tab-content span.chips {
  background-color: rgb(23, 162, 184);
  padding: 1px 5px 1px 5px;
  display: inline-block;
  cursor: default;
  font-size: 11px;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 5px;
}

.tab-content span.chips button {
  border: none;
  background: transparent !important;
  padding: 1px 5px;
  color: inherit;
  cursor: pointer;
  outline: none;
  border-left: 1px solid rgba(255, 255, 255, 0.44);
  margin-left: 5px;
}

.tab-content span.chips-disable {
  background-color: #ddd;
  padding: 2px 5px;
  display: inline-block;
  color: #73879c;
  cursor: pointer;
  font-size: 11px;
  border-radius: 4px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.select-btn {
  color: #73879c !important;
  cursor: pointer;
}
</style>
