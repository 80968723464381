<template>
  <div id="services_accordion" class="services_accordion">
    <!-- Package Options -->

    <optionsVue @onClickTab="activeTabs = 'options'" :activeTabs="activeTabs == 'options'" />

    <!-- Package Availability -->
    <availabilityVue @onClickTab="activeTabs = 'availability'" :activeTabs="activeTabs == 'availability'" v-if="selectedOptionType != ''" />

    <div class="card" v-for="item in availableTabs" :key="item">
      <div class="card-header">
        <button class="btn btn-link" :class="{ collapsed: activeTabs == item }" type="button" @click.prevent="activeTabs = item">
          {{ packageDetails && packageDetails.ims_pricing && item == "ims_setting" ? "Ims Setting" : `${item} Details` }}
          <!-- Accordian Arrow -->
          <v-arrowicon />
        </button>
      </div>
      <components :activeTabs="activeTabs" :class="{ show: activeTabs == item }" :is="item" />
    </div>
  </div>
</template>

<script>
import optionsVue from "./components/options.vue";
import availabilityVue from "./components/availability";
import flights from "./components/flights";
import hotels from "./components/hotels.vue";
import sightseeing from "./components/sightseeing.vue";
import transfers from "./components/transfer.vue";
import bus from "./components/bus.vue";
import cruise from "./components/cruise.vue";
import meals from "./components/meals.vue";
import taxes from "./components/taxes.vue";
import visa from "./components/visa.vue";
import trains from "./components/trains.vue";
import ims_setting from "./components/flights/ims_setting.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";

let flightData = {
  origin: "",
  destination: "",
  airline: "",
  departuretime: "",
  arrivaltime: "",
  stops: []
};

export default {
  name: "Advance",
  props: {
    activeStep: { type: String }
  },
  components: {
    optionsVue,
    availabilityVue,
    flights,
    hotels,
    sightseeing,
    transfers,
    bus,
    meals,
    cruise,
    taxes,
    visa,
    trains,
    ims_setting
  },
  data() {
    return {
      activeTabs: "options",
      services: {
        hotels: {},
        flights: { onward: JSON.parse(JSON.stringify([flightData])), interconnect: [], return: JSON.parse(JSON.stringify([flightData])) },
        sightseeing: [],
        transfers: [],
        bus: [{ orderId: 345375, from: "", to: "", type: "" }],
        cruise: [{ orderId: 452354, boarding: { city: "", time: "" }, deboarding: { city: "", time: "" }, stay: "", cabintype: "" }],
        trains: [],
        meals: {},
        visa: "",
        taxes: "",
        ims_setting: {}
      },
      availableTabs: []
    };
  },
  computed: {
    ...mapGetters(["selectedOptionType", "packageServices", "packageDetails", "packageCruise"])
  },
  watch: {
    activeStep() {
      this.availableTabs = [];
      if (this.packageDetails.services_included && this.packageDetails.services_included.length > 0 && this.packageDetails.services_included.length != this.availableTabs.length) {
        for (const service in this.services) {
          const find = this.packageDetails.services_included.findIndex((data) => this._.lowerCase(service) == this._.lowerCase(data.name));
          if (find != -1) {
            this.availableTabs.push(this._.lowerCase(service));
          }
        }
      }
    },
    packageDetails(packages) {
      let _tabs = [];
      if (this.packageDetails.services_included && this.packageDetails.services_included.length > 0) {
        for (const service in this.services) {
          const find = this.packageDetails.services_included.findIndex((data) => this._.lowerCase(service) == this._.lowerCase(data.name));
          if (find != -1) {
            if (service == "flights") {
              _tabs.push(this._.lowerCase(service));
              if (this.packageDetails && this.packageDetails?.ims_pricing) {
                _tabs.push("ims_setting");
              }
            } else {
              _tabs.push(this._.lowerCase(service));
            }
          }
        }
      }
      this.availableTabs = _tabs;
      for (let value of this.availableTabs) {
        let newList = [];
        if (packages.hasOwnProperty(value)) {
          if (Array.isArray(packages[value]) && packages[value].length > 0) {
            for (let data of packages[value]) {
              newList.push({
                ...data,
                orderId: Math.floor(Math.random() * 9999) + 1000
              });
            }
            this.managePackageServices({
              service: value,
              existsData: newList
            });
          } else if (value === "flights") {
            if (packages[value]) {
              let flightItem = {
                onward: [],
                interconnect: [],
                return: []
              };
              for (const flight in packages[value]) {
                if (packages[value][flight]?.length) {
                  for (let data of packages[value][flight]) {
                    flightItem[flight].push({
                      ...data,
                      stops: Array.isArray(data.stops) ? data.stops : [],
                      orderId: Math.floor(Math.random() * 9999) + 1000
                    });
                  }
                }
              }
              this.managePackageServices({
                service: value,
                existsData: {
                  ...packages[value],
                  ...flightItem
                }
              });
            } else {
              this.managePackageServices({
                service: value,
                existsData: this.services[value]
              });
            }
          } else if (typeof packages[value] == "string" || (packages[value] != null && typeof packages[value] == "object" && !Array.isArray(packages[value]))) {
            this.managePackageServices({
              service: value,
              existsData: packages[value]
            });
          } else {
            this.managePackageServices({
              service: value,
              existsData: this.services[value]
            });
          }
        }
      }

      if (packages.options) {
        let newOption = [],
          newData = [],
          newMeals = [],
          newHotels = [];
        for (let item of packages.options.items) {
          let newDates = {};
          let count = 0;
          item.isNew = false;
          newData.push({
            type: item.type,
            isNew: false,
            active: item.active,
            dates: {}
          });
          newHotels.push({
            type: item.type,
            isNew: false,
            hotels: item.hotels
              ? item.hotels.filter((data) => {
                  data.id = count++;
                  return data;
                })
              : []
          });
          newMeals.push({
            type: item.type,
            isNew: false,
            meals: item.meals ? item.meals : { breakfast: false, breakfast_amount: "", lunch: false, lunch_amount: "", dinner: false, dinner_amount: "" }
          });
          for (let month in item.dates) {
            newDates[month] = [];
            for (let date of item.dates[month]) {
              newDates[month].push({
                ...date
              });
            }
          }
          item.dates = newDates;
          newOption.push(item);
        }
        this.isFieldsDataExists({ value: packages.options, type: "options" });
        this.isFieldsDataExists({ value: newData, type: "newSelectedDates" });
        this.isFieldsDataExists({ value: newMeals, type: "meals" });
        this.isFieldsDataExists({ value: newHotels, type: "hotels" });
      } else {
        this.changeOptionType("");
        this.isFieldsDataExists({
          value: { isSingleHotel: false, items: [] },
          type: "options"
        });
        this.isFieldsDataExists({ value: [], type: "newSelectedDates" });
        this.isFieldsDataExists({ value: [], type: "meals" });
        this.isFieldsDataExists({ value: [], type: "hotels" });
      }
    }
  },
  created() {
    if (this.$route.query.code && this.$route.query.code != "") {
      this.getPackageByCode(this.$route.query.code);
    }
    this.getAirlinesList();
  },
  methods: {
    ...mapMutations(["isFieldsDataExists", "managePackageServices", "changeOptionType"]),
    ...mapActions(["getPackageByCode", "getAirlinesList"])
  }
};
</script>

<style>
#services_accordion .card .card-header > button {
  padding: 10px;
  font-size: 12px;
}
</style>
