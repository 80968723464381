<template>
  <div v-if="!is_loading_hotels" class="hotels_container" :class="{ remove_height: is_single_hotel }">
    <div class="hotel_wrapper">
      <!-- <div class="hotel_parent">
      </div> -->
      <div class="hotel_items" v-for="hotel in filterHotelList" :key="hotel.id">
        <label class="mb-0">
          <input
            v-if="is_single_hotel"
            v-model.trim="selected_hotel._id"
            :value="hotel._id"
            @change.prevent="handleHotelSelect(hotel)"
            name="hotels"
            type="radio"
          />
          <input v-else :checked="selectedHotel(hotel, 'checkbox')" @change.prevent="handleHotelSelect(hotel)" type="checkbox" />
          <span>{{ hotel.name }}</span>
        </label>
      </div>
    </div>
  </div>
  <div v-else class="loading_hotels">Loading...</div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "HotelSelection",
  props: {
    is_single_hotel: { type: Boolean },
    selected_hotels: { type: Array },
    destination_id: { type: String },
    search_hotel: { type: String },
    hotel_id: { type: Object }
  },
  data() {
    return {
      is_loading_hotels: false,
      hotelList: [],
      selectedHotels: []
    };
  },
  methods: {
    ...mapActions(["updatePackage", "getAllPkgHotels"]),
    handleHotelSelect(hotel) {
      this.$emit("on-select", hotel);
    },
    selectedHotel(hotel, type) {
      if (type == "radio") {
        hotel._id == this?.hotel_id?._id;
      } else {
        const _htData = this?.selected_hotels || [];
        const ifExists = _htData.findIndex(data => data._id == hotel._id);
        return ifExists != -1;
      }
    }
  },
  computed: {
    filterHotelList() {
      if (this.search_hotel) {
        return this?.hotelList?.filter(data => {
          return data.name.toLowerCase().includes(this.search_hotel.toLowerCase());
        });
      } else {
        return this?.hotelList;
      }
    },
    selected_hotel() {
      return this?.hotel_id || {};
    }
  },
  created() {
    this.is_loading_hotels = true;
    this.selectedHotels = JSON.parse(JSON.stringify(this.selected_hotels));
    this.getAllPkgHotels({ city_id: this.destination_id }).then(data => {
      this.hotelList = data;
      this.is_loading_hotels = false;
    });
  }
};
</script>

<style scoped>
.hotels_container {
  display: flex;
  justify-content: space-between;
  height: 575px;
  overflow: hidden;
  overflow-y: auto;
  user-select: none;
}

.remove_height {
  height: unset;
}

.hotel_wrapper {
  display: flex;
  height: 100%;
  min-height: 100%;
  width: 100%;
  padding: 10px;
  flex-wrap: wrap;
  align-content: flex-start;
}

.hotel_wrapper .hotel_items {
  display: flex;
  margin: 0;
  width: 25%;
  margin-bottom: 5px;
  align-items: center;
}

.hotel_wrapper .hotel_items label {
  display: flex;
  cursor: pointer;
  width: fit-content;
}

.hotel_wrapper .hotel_items input {
  margin-right: 5px;
}

.hotel_wrapper .hotel_items span {
  display: block;
  width: 90%;
}

.hotels_container .hotel_left {
  width: 69.5%;
  border-right: 1px solid #666;
  padding-right: 10px;
  display: flex;
  flex-wrap: wrap;
}
.hotels_container .hotel_right {
  width: 30%;
}

.loading_hotels {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  font-size: 20px;
  font-weight: 600;
  color: #f56d6d;
}
</style>
