<template>
  <tr>
    <td class="overflow" :title="itemComp.name">
      {{ itemComp.name }}
    </td>
    <td>
      {{ itemComp.code }}
    </td>
    <td class="overflow" :title="itemComp.destinations_covered">
      {{ itemComp.destinations_covered }}
    </td>
    <td>
      {{ itemComp.total_nights }}
    </td>
    <td>
      {{ itemComp.booking_amount.adult }}
    </td>
    <td>
      {{ PkgType }}
    </td>
    <td>
      <input type="checkbox" :checked="itemComp.active" disabled readonly />
    </td>
    <td>
      <input
        type="checkbox"
        :checked="itemComp.ims_pricing"
        disabled
        readonly
      />
    </td>
    <td>
      <input type="checkbox" v-model="itemComp.is_feature" />
      <div v-if="featureLoading" class="data-loader">
        <Loader />
      </div>
    </td>
    <td
      v-if="itemComp.createUser && itemComp.createUser.name"
      :title="itemComp.createUser.name"
    >
      {{ itemComp.createUser ? itemComp.createUser.name : "N/A" }}
    </td>
    <td v-else>N/A</td>
    <td
      v-if="itemComp.updateUser && itemComp.updateUser.name"
      :title="itemComp.updateUser.name"
    >
      {{ itemComp.updateUser ? itemComp.updateUser.name : "N/A" }}
    </td>
    <td v-else>N/A</td>
    <td>
      <a
        target="_blank"
        :href="`/packages/available-dates?pkg_code=${itemComp.code}`"
        class="btn btn-submit btn-info m-0"
        >Dates</a
      >
    </td>
    <td>
      <a
        v-if="
          !itemComp.isPublishRequest &&
            !itemComp.isPublished &&
            !itemComp.isRejected
        "
        @click.prevent="
          $emit('package-publish', { code: itemComp.code, value: true })
        "
        class="btn btn-submit"
        >Send To Publish</a
      >
      <a
        v-else-if="!itemComp.isPublishRequest && itemComp.isPublished"
        class="btn btn-submit btn-success"
        >Approved</a
      >
      <a
        v-else-if="!itemComp.isPublishRequest && itemComp.isRejected"
        :title="itemComp.rejectedMessage"
        class="btn btn-submit btn-danger"
        >Rejected !</a
      >
      <a
        v-else-if="
          (itemComp.isPublishRequest && !itemComp.isPublished) ||
            itemComp.isRejected
        "
        class="btn btn-submit btn-warning"
        >Pending</a
      >
      <a
        target="_blank"
        v-if="!itemComp.isPublishRequest"
        :href="`/package/create?code=${itemComp.code}&tabName=package-basic`"
        class="btn btn-submit"
        >Edit</a
      >
      <a
        v-else
        style="padding: 0 4px"
        @click.prevent="
          $emit('package-publish', { code: itemComp.code, value: false })
        "
        class="btn btn-submit btn-danger"
        >Cancel</a
      >
    </td>
  </tr>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "PkgListItems",
  props: {
    item: { type: Object }
  },
  data() {
    return {
      featureLoading: false
    };
  },
  watch: {
    "item.is_feature": function(is_feature) {
      this.featureLoading = true;
      this.updatePackageFeature({ _id: this.itemComp._id, is_feature }).then(
        val => {
          this.featureLoading = false;
        }
      );
    }
  },
  computed: {
    PkgType() {
      switch (this.itemComp.pkg_type) {
        case "available":
          return "Available";
        case "sold":
          return "Sold Out";
        case "request":
          return "On Request";
        case "static":
          return "Static";
        default:
          return "";
      }
    },
    itemComp() {
      return this.item;
    }
  },
  methods: {
    ...mapActions(["updatePackageFeature"])
  }
};
</script>

<style scoped>
.t-row {
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
  border-bottom: unset !important;
}

.overflow {
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.btn-submit {
  border-radius: 0;
  padding: 0px 10px;
  background: #17a2b8;
  color: #fff !important;
  margin: 0 4px;
  font-size: 10px;
}

.btn-success {
  color: #fff !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
  cursor: default !important;
}

.btn-danger {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
  cursor: default !important;
}

.btn-warning {
  color: #fff !important;
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
  cursor: default !important;
}

.data-loader {
  position: absolute;
  top: 0px;
  right: -5px;
}
.data-loader >>> .lds-spinner {
  height: 0px;
  width: 0px;
  display: unset;
  position: absolute;
  right: 82px;
  top: -18px;
}

.data-loader >>> .lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 25px;
  left: 31px;
  width: 2px;
  height: 4px;
  border-radius: 20%;
  background: black;
}
</style>
