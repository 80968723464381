export default {
  data() {
    return {
      inclusionsText: "",
      exclusionsText: "",
      isImageModelOpen: false,
      selectedMediaTab: "",
      filterText: "",
      filterList: [],
      filterImagesList: [],
      package_offersText: "",
      packageData: {
        themes: [],
        images: [],
        videos: [],
        services_included: [],
        exclusions: [],
        inclusions: [],
        itinerary: [],
        package_offers: [],
        terms_conditions: "",
        visa_information: {
          text: "",
          others: []
        },
        documents_required: {
          text: "",
          others: []
        },
        additional_information: "",
        important_info: "",
        extraData: []
      },
      toastMessages: {
        themes: {
          success: "Holidays Categories And Theme Is Updated"
        },
        services_included: {
          success: "Services Is Updated"
        },
        itinerary: {
          success: "All Itinerary Is Updated"
        },
        package_offers: {
          success: "Package Offers is Updated"
        },
        exclusions: {
          success: "Exclusion is Updated"
        },
        inclusions: {
          success: "Inclusion is Updated"
        },
        images: {
          success: "Images is Updated"
        },
        terms_conditions: { success: "Terms Conditions Is Updated" },
        visa_information: { success: "Visa Information Is Updated" },
        documents_required: { success: "Documents Information Is Updated" },
        additional_information: {
          success: "Additional Information Is Updated"
        },
        important_info: { success: "Important Info Is Updated" }
      },
      toolbarSettings: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [{ header: 1 }, { header: 2 }],
        [{ script: "sub" }, { script: "super" }],
        [{ direction: "rtl" }],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ["link", "clean"]
      ]
    };
  }
};
