<template>
  <container headTitle="Sightseeing / Transfers Master">
    <div slot="createbtn">
      <button @click.prevent="isNew = true" class="btn btn-sm btn-info float-right">Add New Sightseeing</button>
    </div>

    <div slot="content" id="sightseeing_module">
      <table class="table">
        <thead class="table-teal">
          <tr>
            <th style="width: 45px"></th>
            <th style="width: 250px">Name</th>
            <th style="width: 120px">Service</th>
            <th style="width: 120px">Seat Type</th>
            <th>Description</th>
            <th style="width: 150px">City</th>
            <th style="width: 110px">Timing</th>
            <th style="width: 110px">Duration</th>
            <th style="width: 45px">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in sightList" :key="item._id">
            <td>{{ index + 1 }}</td>
            <td>{{ item.name }}</td>
            <td class="text-capitalize">{{ item.service }}</td>
            <td class="text-capitalize">{{ item.seat_type.replace(/_/g, " ") }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.city.name }}</td>
            <td class="text-capitalize">{{ (item.timing == "day" ? "All Day" : item.timing) || "-" }}</td>
            <td class="text-capitalize">{{ item.duration || "-" }}</td>
            <td>
              <a @click.prevent="handleEditData(item)" class="btn btn-submit">Edit</a>
            </td>
          </tr>
        </tbody>
      </table>
      <insert-sightseeing @onClose="handleCloseSightseeing" :isNew="isNew" :formItem="selectedItem" :isUpdate="isUpdate" />
    </div>
  </container>
</template>

<script>
import { mapActions } from "vuex";
import InsertSightseeing from "./insert_sightseeing";
export default {
  name: "PkgSightseeing",
  components: { InsertSightseeing },
  data() {
    return {
      isNew: false,
      isUpdate: false,
      sihgtseeingTypeList: [],
      sightList: [],
      selectedItem: {}
    };
  },
  created() {
    this.handleCloseSightseeing();
  },
  methods: {
    ...mapActions(["getAllPkgSightseeing"]),
    handleEditData(item) {
      if (!item.pricing) {
        item.pricing = {
          adult: "",
          is_child: false,
          child: "",
          is_infant: false,
          infant: ""
        };
      }
      delete item.__typename;
      this.selectedItem = { ...item };
      this.isUpdate = true;
      this.isNew = true;
    },
    handleSightseeingType(value) {
      const find = this.sihgtseeingTypeList.find((data) => data._id == value);
      return find ? find.name : "";
    },
    handleCloseSightseeing() {
      this.isUpdate = false;
      this.isNew = false;
      this.selectedItem = {};
      this.getAllPkgSightseeing().then((data) => {
        this.sightList = data;
      });
    }
  }
};
</script>

<style scoped>
.overflow {
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.btn-submit {
  border-radius: 0;
  padding: 0px 10px;
  background: #17a2b8;
  color: #fff !important;
  margin: 0 5px;
  font-size: 10px;
}

.btn-success {
  color: #fff !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
  cursor: default !important;
}
</style>
