<template>
  <div>
    <table class="table">
      <thead class="table-teal" style="top: 0">
        <tr>
          <th>
            S.No.
            <input v-if="false" type="checkbox" class="list-checkbox" />
          </th>
          <th>Service Name</th>
          <th>Option</th>
          <th>Discount Date</th>
          <th>Adult</th>
          <th>CNB</th>
          <th>CWB</th>
          <th>Infant</th>
          <th>Discount On</th>
        </tr>
      </thead>
      <tbody v-if="newDateRange.length > 0">
        <tr v-for="(item, index) in newDateRange" :key="item.key">
          <td style="padding: 5px 10px">
            {{ index + 1 }}
            <input
              v-if="false"
              v-model="item.isSelected"
              class="list-checkbox float-right"
              type="checkbox"
            />
          </td>
          <td>{{ item.package }}</td>
          <td>{{ item.option }}</td>
          <td>{{ $moment(item.value).format("DD/MMM/YYYY") }}</td>
          <td>&#8377;{{ item.discount.adult }}</td>
          <td>&#8377;{{ item.discount.cnb }}</td>
          <td>&#8377;{{ item.discount.cwb }}</td>
          <td>&#8377;{{ item.discount.infant }}</td>
          <td>
            {{
              item.type == "booking_date"
                ? "Booking Date"
                : item.type == "travel_date"
                ? "Travel Date"
                : "Deleted Date"
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ListItem",
  props: {
    isSearchMode: { type: Boolean }
  },
  computed: {
    ...mapGetters(["newDateRange"])
  }
};
</script>

<style scoped>
.fnt-12 {
  font-size: 12px;
}

.list-checkbox {
  position: relative;
  top: 3px;
  left: 2px;
}
</style>
