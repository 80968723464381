<template>
  <div class="row gutter14 mb-2">
    <div class="col-2 form-group" :class="{ invalid: itemDataComp.origin.$error }">
      <label class="form-para mb-2 required">Select Origin</label>
      <select @blur="itemDataComp.origin.$touch()" v-model="itemDataComp.origin.$model" class="form-control">
        <option value>Choose</option>
        <option v-for="item in filterDestination(destinations, itemDataComp.destination.$model)" :key="item.id" :value="item.id">{{
          item.destinationName
        }}</option>
      </select>
      <span class="text-muted" v-if="itemDataComp.origin.$error && !itemDataComp.origin.required">origin is required.</span>
    </div>
    <div class="col-2 form-group" :class="{ invalid: itemDataComp.destination.$error }">
      <label class="form-para mb-2 required">Select Destination</label>
      <select @blur="itemDataComp.destination.$touch()" v-model="itemDataComp.destination.$model" class="form-control">
        <option value>Choose</option>
        <option v-for="item in filterDestination(destinations, itemDataComp.origin.$model)" :key="item.id" :value="item.id">{{
          item.destinationName
        }}</option>
      </select>
      <span class="text-muted" v-if="itemDataComp.destination.$error && !itemDataComp.destination.required">destination is required.</span>
    </div>
    <div class="col-3">
      <p class="form-para mb-2">Select Airline</p>
      <select v-model="itemDataComp.airline.$model" class="form-control">
        <option value>Choose</option>
        <option v-for="item in airlinesList" :value="item.code" :key="item.name">{{ item.name }}</option>
      </select>
    </div>
    <div class="col-2">
      <p class="form-para mb-2">Departure Time</p>
      <input step="0" v-model="itemDataComp.departuretime.$model" type="time" class="form-control" />
    </div>
    <div class="col-2">
      <p class="form-para mb-2">Arrival Time</p>
      <input step="0" v-model="itemDataComp.arrivaltime.$model" type="time" class="form-control" />
    </div>
    <!--  <div class="col-1">
      <p class="form-para mb-2">Stops</p>
      <select @change="$emit('changeStops', $event.currentTarget.value)" :value="itemDataComp.stops.$model.length" class="form-control">
        <option selected value="0">0</option>
        <option value="1">1</option>
        <option value="2">2</option>
      </select>
    </div> -->
    <div v-if="action" class="col-1 text-center">
      <p class="form-para mb-2">Action</p>
      <button @click.prevent="$emit('onClickRemove')" class="btn btn-submit ripple btn-hotel my-0">
        Remove
      </button>
    </div>
    <div v-for="(item, index) in itemDataComp.stops.$each.$iter" :key="index" class="col-2 mb-2">
      <p class="form-para mb-2">Stop {{ +index + 1 }}</p>
      <input v-model="item.name.$model" type="text" class="form-control" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Items",
  props: {
    destinations: { type: Array },
    itemData: { type: Object },
    action: { type: Boolean, default: false },
    onClickRemove: { type: Function }
  },
  data() {
    return {
      stopCount: 0
    };
  },
  computed: {
    ...mapGetters(["airlinesList"]),
    itemDataComp() {
      return this.itemData;
    }
  },
  methods: {
    filterDestination(data, type) {
      if (data.length > 0) {
        return data.reduce((newArr, value) => {
          if (newArr.findIndex(val => val.id == value.id) == -1) {
            if (value && value.id != type) {
              delete value.__typename;
              newArr.push(value);
              return newArr;
            } else {
              return newArr;
            }
          }
          return newArr;
        }, []);
      }
    }
  }
};
</script>

<style scoped>
.form-control {
  margin: 0px;
}
</style>
