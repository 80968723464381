<template>
  <container headTitle="Package Discount">
    <div slot="content">
      <div class="row gutter14 mb-4 align-items-center">
        <div class="col-md-4">
          <div class="row gutter14 align-items-center">
            <div class="col-md-3">
              <label class="form-para m-0">Enter Code : </label>
            </div>
            <div class="col-md-7 d-inline-flex align-items-center">
              <input
                @keydown.enter="onPackageSearch"
                v-model.trim="packageCode"
                type="text"
                class="form-control"
                placeholder="Ex: (DP743)"
              />
            </div>
            <div class="col-md-2">
              <button
                @click.prevent="onPackageSearch"
                type="button"
                :disabled="packageCode == '' || pkgDiscountLoading"
                :class="{ loading: pkgDiscountLoading }"
                class="btn btn-info submit-btn"
              >
                Search
                <div v-if="pkgDiscountLoading" class="data-loader">
                  <Loader />
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <p v-if="pkgNotEmpty" class="form-para m-0">
            Package : {{ discountPkg.name }}
          </p>
        </div>
        <div class="col-md-4">
          <div class="row gutter14 align-items-center">
            <div class="col-md-3">
              <label class="form-para m-0">Options :</label>
            </div>
            <div class="col-md-9">
              <select
                class="form-control"
                v-model="selectedOpt"
                :disabled="!pkgNotEmpty || discountPkg.code == '404'"
              >
                <option value="">Select Option</option>
                <option v-for="opt in optionsList" :key="opt" :value="opt">{{
                  opt
                }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="row gutter14 mb-4 align-items-center">
        <div class="col-md-3">
          <div class="row gutter14 align-items-center">
            <div class="col-md-4">
              <label class="form-para m-0">From Date :</label>
            </div>
            <div class="col-md-8">
              <date-picker
                :disabled-dates="fromDateDisabled"
                :value="fromDate"
                v-model="fromDate"
                placeholder="Select Date"
                input-class="form-control"
              ></date-picker>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="row gutter14 align-items-center">
            <div class="col-md-4">
              <label class="form-para m-0">To Date :</label>
            </div>
            <div class="col-md-8">
              <date-picker
                :disabled-dates="toDateDisbaled"
                :value="toDate"
                v-model="toDate"
                placeholder="Select Date"
                input-class="form-control"
              ></date-picker>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="rc-radio-group m-0">
            <label class="radio"
              ><input
                v-model="dateType"
                type="radio"
                name="datetype"
                value="booking_date"
              /><span>For Booking Date</span></label
            ><label class="radio"
              ><input
                v-model="dateType"
                type="radio"
                name="datetype"
                value="travel_date"
              /><span>For Travel Date</span></label
            >
          </div>
        </div>

        <div class="col-md-3">
          <button
            @click.prevent="handleOnSearchSaveAndDelete('search')"
            type="button"
            :class="{ loading: isSearchingDiscount }"
            :disabled="selectedOpt == '' || isSearchingDiscount"
            class="btn btn-info px-3 mx-1 submit-btn"
          >
            Search
            <div v-if="isSearchingDiscount" class="data-loader">
              <Loader />
            </div>
          </button>
          <button
            @click.prevent="isDeleteDiscount = true"
            type="button"
            :disabled="selectedOpt == ''"
            class="btn btn-info btn-danger px-3 mx-1 submit-btn"
          >
            Delete
          </button>
        </div>
      </div>

      <hr />

      <add-discount
        @handleOnSave="handleOnSearchSaveAndDelete('save')"
        :CheckSaveVales="CheckSaveVales"
        :discount="discount"
      />

      <hr />
      <div>
        <h6 class="mt-2">Available Discount :</h6>
        <div>
          <list-item :isSearchMode="isSearchMode" />
          <div v-if="loadingResult" class="list-loader">
            <p>Loading...</p>
          </div>
          <div
            v-if="!loadingResult && newDateRange.length == 0"
            class="list-loader"
          >
            <p>No Result Found!</p>
          </div>
        </div>
      </div>
      <popup-model
        class="delete-model"
        @close-model="isDeleteDiscount = false"
        :closeBtn="false"
        :openModel="isDeleteDiscount"
      >
        <div slot="content">
          <div class="modal-body p-0">
            Woohoo, Are you sure you want To Delete
            <h6 style="font-weight: 600; display: initial">
              {{
                dateType == "booking_date" ? "Booking Dates" : "Travel Dates"
              }}
            </h6>
            Discount ?
          </div>
          <div class="modal-footer pt-2 pb-1 pr-0">
            <button
              @click="isDeleteDiscount = false"
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              @click.prevent="handleOnSearchSaveAndDelete('delete')"
              type="button"
              class="btn btn-primary submit-btn"
              :class="{ loading: deletingDiscount }"
            >
              Confirm
              <div v-if="deletingDiscount" class="data-loader">
                <Loader />
              </div>
            </button>
          </div>
        </div>
      </popup-model>
    </div>
  </container>
</template>
<script>
import ListItem from "./components/list-item";
import AddDiscount from "./components/add_discount";
import Vue from "vue";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Moment from "moment";
import { extendMoment } from "moment-range";

export default {
  name: "CreateSector",
  components: { ListItem, AddDiscount },
  data() {
    return {
      packageCode: "",
      dateType: "booking_date",
      selectedOpt: "",
      isSearchMode: false,
      isDeleteDiscount: false,
      discount: {
        adult: "",
        cwb: "",
        cnb: "",
        infant: ""
      },
      fromDate: this.$moment().toDate(),
      toDate: this.$moment()
        .add(1, "days")
        .toDate(),
      fromDateDisabled: {
        to: this.$moment()
          .subtract(1, "days")
          .toDate()
      },
      toDateDisbaled: {
        to: this.$moment()
          .subtract(1, "days")
          .toDate()
      }
    };
  },
  computed: {
    ...mapGetters([
      "pkgDiscountLoading",
      "discountPkg",
      "newDateRange",
      "updatingDiscount",
      "deletingDiscount",
      "isSearchingDiscount"
    ]),
    loadingResult() {
      return this.updatingDiscount || this.isSearchingDiscount;
    },
    pkgNotEmpty() {
      return !this._.isEmpty(this.discountPkg) && this.discountPkg.code;
    },
    optionsList() {
      let options = [];
      if (this.pkgNotEmpty) {
        return this.discountPkg.options;
      }
      return options;
    },
    CheckSaveVales() {
      let isValid = false;
      for (const item in this.discount) {
        if (this.discount[item] === "" || this.selectedOpt == "") {
          isValid = true;
          break;
        }
      }
      return isValid;
    }
  },
  methods: {
    ...mapActions([
      "getPkgByCode",
      "updateAndDeleteDiscount",
      "getDiscountsList"
    ]),
    ...mapMutations(["setNewDateRange"]),
    onPackageSearch() {
      this.getPkgByCode(this.packageCode).then(() => {
        this.selectedOpt = "";
      });
    },
    handleOnSearchSaveAndDelete(type) {
      this.setNewDateRange([]);
      const newDateList = this.getSelectedDateRange();
      const newData = {
        code: this.packageCode,
        options: this.selectedOpt,
        discountType: this.dateType,
        data: newDateList
      };
      if (type == "save") {
        this.handleOnSave(newData, "update");
      }
      if (type == "search") {
        this.getDiscountsList(newData);
      }
      if (type == "delete") {
        this.handleOnSave(newData, "delete");
      }
    },
    async handleOnSave(value, type) {
      await this.updateAndDeleteDiscount({
        input: value,
        type
      }).then(data => {
        if (type == "update") {
          this.$toasted.success(`${data.length} dates updated`, {
            duration: 1500,
            position: "bottom-center"
          });
        } else {
          this.isDeleteDiscount = false;
          this.$toasted.info(`${data.length} dates deleted`, {
            duration: 1500,
            position: "bottom-center"
          });
        }
      });
    },
    getSelectedDateRange() {
      const range = this.$momentrange().range(
        this.$moment(this.fromDate).startOf("days"),
        this.$moment(this.toDate).startOf("days")
      );
      let dateRange = Vue.observable({});
      for (let month of range.by("days")) {
        const Obj = {
          value: month.format("DD"),
          discount: { ...this.discount }
        };
        if (dateRange.hasOwnProperty(month.format("MM-YYYY"))) {
          dateRange[month.format("MM-YYYY")].push(Obj);
        } else {
          dateRange[month.format("MM-YYYY")] = [Obj];
        }
      }
      return dateRange;
    }
  }
};
</script>
<style scoped>
.form-para {
  font-size: 12px;
}
.sticky-header {
  position: relative;
}
.sticky-header >>> .thead-dark tr th {
  top: -1px;
  position: sticky;
}

.submit-btn {
  position: relative;
  transition: 0.7s all;
}
.submit-btn.loading {
  width: 80px;
  text-align: left;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.data-loader {
  position: absolute;
  top: 3px;
  right: -5px;
}
.data-loader >>> .lds-spinner {
  height: 0px;
  width: 0px;
  display: unset;
  position: absolute;
  right: 50px;
  top: -18px;
}

.data-loader >>> .lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 23px;
  left: 31px;
  width: 2px;
  height: 6px;
  border-radius: 20%;
  background: black;
}

.delete-model >>> .popup_body {
  width: 27%;
}

.delete-btn {
  position: relative;
  width: 28%;
  text-align: left;
  transition: 1s all;
}

.delete-btn .data-loader {
  position: absolute;
  position: absolute;
  right: 0;
  top: 3px;
}

.list-loader {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}
</style>
