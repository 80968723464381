<template>
  <div id="sightseeing_details" class="collapse" data-parent="#services_accordion">
    <div class="card-body p-2">
      <div class="add_button_container">
        <button @click.prevent="is_add_model = true" class="btn btn-info">Add Sightseeing</button>
      </div>
      <hr class="my-2" />
      <table class="table">
        <thead class="table-teal">
          <tr>
            <th style="width: 40px"></th>
            <th style="width: 80px">Status</th>
            <th style="width: 110px">Seat Type</th>
            <th>City</th>
            <th>Name</th>
            <th>Duration</th>
            <th>Timing</th>
            <th>Details</th>
            <th style="width: 70px">Adult</th>
            <th style="width: 70px">Child</th>
            <th style="width: 70px">Infant</th>
            <th style="width: 120px"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in packageSightseeing" :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              <label class="badge" :class="[`badge-${item.is_include ? 'success' : 'warning'}`]">{{ item.is_include ? "Included" : "Optional" }}</label>
            </td>
            <td class="text-capitalize">{{ item.name.seat_type.replace(/_/g, " ") }}</td>
            <td>{{ getCityName(item.city) }}</td>
            <td>{{ item.name.name }}</td>
            <td>{{ item.name.duration || "-" }}</td>
            <td class="text-capitalize">{{ (item.name.timing == "day" ? "All Day" : item.name.timing) || "-" }}</td>
            <td><div v-html="item.details"></div></td>
            <td>
              <div v-if="item.is_include">Include</div>
              <div v-else-if="item.name.pricing">
                <span v-if="item.name.pricing.adult">&#8377; {{ item.name.pricing.adult | rupeeFormat }}</span>
                <span v-else>Included</span>
              </div>
              <div v-else>N/A</div>
            </td>
            <td>
              <div v-if="item.is_include">Include</div>
              <div v-else-if="item.name.pricing && item.name.pricing.is_child">
                <span v-if="item.name.pricing.child">&#8377; {{ item.name.pricing.child | rupeeFormat }}</span>
                <span v-else>Included</span>
              </div>
              <div v-else>N/A</div>
            </td>
            <td>
              <div v-if="item.is_include">Include</div>
              <div v-else-if="item.name.pricing && item.name.pricing.is_infant">
                <span v-if="item.name.pricing.infant">&#8377; {{ item.name.pricing.infant | rupeeFormat }}</span>
                <span v-else>Included</span>
              </div>
              <div v-else>N/A</div>
            </td>
            <td>
              <div class="action-btn">
                <button class="btn btn-info mr-2" @click.prevent="onEditProduct(item, index)">Edit</button>
                <button @click.prevent="delete_item = item" class="btn btn-danger">Delete</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <popup-model class="sightseeing_model" :close-btn="false" :openModel="is_add_model">
      <template #content>
        <div class="title">{{ is_update ? "Update" : "Add" }} Sightseeing</div>
        <hr class="mt-2 mb-0" />
        <div class="form_container">
          <div class="row gutter14">
            <div class="col-md-6">
              <div class="form-group" :class="{ invalid: $v.formData.city.$error }">
                <label class="required">City</label>
                <select :disabled="is_update" @blur.prevent="$v.formData.city.$touch()" v-model.trim="formData.city" class="form-control">
                  <option value>Choose</option>
                  <option v-for="city in packageDetails.pkg_destinations" :key="city.id" :value="city.id">{{ city.destinationName }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group" :class="{ invalid: $v.formData.name.$error }">
                <label class="required mb-2">Name</label>
                <select @blur="$v.formData.name._id.$touch()" :disabled="availableSightseeing.length == 0 || is_sight_loading || is_update" v-model.trim="formData.name._id" class="form-control">
                  <option value="">Choose</option>
                  <option v-for="item in availableSightseeing" :value="item._id" :key="item._id">{{ item.name }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mb-0">
                <label>Details</label>
                <vue-editor :editor-toolbar="toolbarSettings" v-model.trim="formData.details" />
              </div>
            </div>
          </div>
        </div>
        <hr class="my-2" />
        <div class="action_container">
          <div>
            <a @click.prevent="createNewSightseeing" v-if="formData.city != ''" class="add_new_sightseeing" href="javascript:void(0)">Add New Sightseeing</a>
          </div>
          <div class="d-flex">
            <div class="form-group mr-3 mb-0">
              <label class="checkbox" style="font-weight: 600"><input v-model="formData.is_include" type="checkbox" class="mr-2" /> Mark Include</label>
            </div>
            <button @click.prevent="is_add_model = false" class="btn btn-danger mr-2">Cancel</button>
            <button @click.prevent="onSubmitForm" class="btn btn-info">{{ is_update ? "Update" : "Submit" }}</button>
          </div>
        </div>
      </template>
    </popup-model>
    <popup-model class="delete_model" :close-btn="false" :openModel="delete_item != null">
      <template #content>
        <div class="title">Delete Item</div>
        <hr class="my-2" />
        are you sure you want to delete <span v-if="delete_item" class="fnt-13 clr-teal font-bold">{{ delete_item.name.name }}</span> ?
        <hr class="my-2" />
        <div class="d-flex justify-content-end">
          <button @click.prevent="delete_item = null" class="btn btn-danger mr-2">Cancel</button>
          <button @click.prevent="onRemoveProduct" class="btn btn-info">Submit</button>
        </div>
      </template>
    </popup-model>
    <create-sightseeing :isNew="is_new_model" @onClose="onCloseModel" :formItem="selectedCity" :isUpdate="true" />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
import CreateSightseeing from "../../../../sightseeing/insert_sightseeing";
export default {
  name: "Sightseeing",
  components: { CreateSightseeing },
  watch: {
    "formData.city": function (value) {
      if (value != "") {
        this.is_sight_loading = true;
        this.getAllPkgSightseeing({ city_id: value, service: "sightseeing" }).then((data) => {
          this.is_sight_loading = false;
          this.availableSightseeing = data;
        });
      } else this.availableSightseeing = [];
      this.$v.$reset();
    },
    is_add_model(value) {
      if (!value) this.resetForm();
    }
  },
  data() {
    return {
      is_add_model: false,
      is_new_model: false,
      formData: { name: { _id: "" }, city: "", details: "", is_include: false },
      is_update: false,
      delete_item: null,
      is_sight_loading: false,
      availableSightseeing: [],
      toolbarSettings: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [{ header: 1 }, { header: 2 }],
        [{ script: "sub" }, { script: "super" }],
        [{ direction: "rtl" }],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        ["link", "clean"]
      ],
      selected_product: null,
      isNewSightseeing: false,
      selectedCity: {}
    };
  },
  computed: {
    ...mapGetters(["packageSightseeing", "packageDetails"])
  },
  validations: {
    formData: { city: { required }, name: { _id: { required } }, details: {} }
  },
  methods: {
    ...mapActions(["updatePackage", "getAllPkgSightseeing", "getPackageByCode"]),
    ...mapMutations(["managePackageSightseeing"]),
    getCityName(item) {
      const cityIndex = this._.findIndex(this.packageDetails.pkg_destinations, (data) => data.id == item);
      if (cityIndex != -1) {
        return this.packageDetails.pkg_destinations[cityIndex].destinationName;
      } else {
        return "not found";
      }
    },
    onCloseModel() {
      this.getAllPkgSightseeing({ city_id: this.formData.city, service: "sightseeing" }).then((data) => {
        this.is_new_model = false;
        this.availableSightseeing = data;
      });
    },
    onEditProduct(item, index) {
      this.selected_product = index;
      this.formData = JSON.parse(JSON.stringify(item));
      this.is_update = true;
      this.is_add_model = true;
    },
    onSubmitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const _sightseeing = this.availableSightseeing.find((val) => val._id == this.formData.name._id);
        const _exists_sightseeing = JSON.parse(JSON.stringify(this.packageSightseeing));
        const _form_data = { ...this.formData, name: { _id: _sightseeing._id, name: _sightseeing.name } };
        const _exists_index = _exists_sightseeing.findIndex((val) => val.city == this.formData.city && val?.name?._id == this.formData?.name?._id);
        if (this.selected_product == null) {
          if (_exists_index != -1) this.$toasted.info("Sightseeing already exists...", { position: "bottom-center", duration: 3500 });
          else _exists_sightseeing.push(_form_data);
        } else _exists_sightseeing.splice(_exists_index, 1, _form_data);

        if (this.is_update || this.packageSightseeing.length != _exists_sightseeing.length) {
          const _mapped = _exists_sightseeing.map((val) => ({ ...val, name: val.name._id }));
          this.updatePackage({ type: "ADVANCE", data: { advance: { code: this.$route.query.code, data: { sightseeing: _mapped } } } }).then(async () => {
            //this.managePackageSightseeing({ data: { ..._form_data, name: { ..._form_data.name, pricing: _sightseeing.pricing } }, index: this.is_update ? _exists_index : null });
            await this.getPackageByCode(this.$route.query.code);
            this.resetForm();
            this.$toasted.success("Sightseeing Data Is Updated", { position: "bottom-center", duration: 3500 });
          });
        }
      }
    },
    onRemoveProduct() {
      let _exists_sightseeing = JSON.parse(JSON.stringify(this.packageSightseeing));
      const _exists_index = _exists_sightseeing.findIndex((val) => val.city == this.delete_item.city && val?.name?._id == this.delete_item.name?._id);
      _exists_sightseeing.splice(_exists_index, 1);
      const _mapped = _exists_sightseeing.map((val) => ({ ...val, name: val.name._id }));
      this.updatePackage({ type: "ADVANCE", data: { advance: { code: this.$route.query.code, data: { sightseeing: _mapped } } } }).then(async () => {
        //this.managePackageSightseeing({ data: _exists_sightseeing, index: "list_update" });
        await this.getPackageByCode(this.$route.query.code);
        this.$toasted.success(`Sightseeing item ${this.delete_item.name.name} removed...`, { position: "bottom-center", duration: 3500 });
        this.delete_item = null;
      });
    },
    resetForm() {
      this.is_add_model = false;
      this.is_new_model = false;
      this.formData = { name: { _id: "" }, city: "", details: "", is_include: false };
      this.is_update = false;
      this.selected_product = null;
      this.$v.$reset();
    },
    createNewSightseeing() {
      const _dest = this.packageDetails.pkg_destinations.find((data) => data.id == this?.formData?.city);
      if (_dest) {
        this.selectedCity = {
          name: "",
          description: "",
          image_url: "",
          timing: "",
          duration: "",
          service: "sightseeing",
          seat_type: "",
          city: { ..._dest, name: _dest.destinationName },
          pricing: { adult: "", is_child: false, child: "", is_infant: false, infant: "" }
        };
      } else {
        this.selectedCity = {};
      }
      this.is_new_model = true;
    }
  }
};
</script>

<style scoped>
.sightseeing_model >>> .popup_body {
  border-radius: 0;
  padding: 0;
  width: 650px;
}

.sightseeing_model .title {
  font-size: 14px;
  font-weight: 600;
  padding: 4px 10px 0px 10px;
}

.sightseeing_model >>> .form_container {
  padding: 4px 10px;
}

.sightseeing_model >>> .action_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px 10px 10px;
}

.sightseeing_model >>> .action_container button,
.delete_model >>> button {
  padding: 4px 10px;
  border-radius: 0;
  font-size: 0.8rem;
  width: 80px;
}

.sightseeing_model >>> .form_container .form-group label {
  font-weight: 600;
  font-size: 12px;
}

.delete_model >>> .popup_body {
  border-radius: 0;
  padding: 4px 10px;
  width: 300px;
}

/* .form-control {
  margin: 0;
}

.sight_btn {
  font-size: 12px;
  padding: 6px 9px;
  margin-top: 28px;
}
 */
.action-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.action-btn button {
  border-radius: 0;
  height: 20px;
  line-height: 5px;
  font-size: 10px;
}

.add_button_container {
  display: flex;
  justify-content: flex-end;
}

.add_button_container button {
  border-radius: 0;
  font-size: 0.8rem;
  padding: 3px 10px;
}

.form-group .checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  cursor: pointer;
  margin-top: 4px;
}

.add_new_sightseeing {
  font-weight: 600;
  color: var(--success);
  text-decoration: none;
}
</style>
