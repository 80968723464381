<template>
  <popup-model
    class="insert_hotel"
    @close-model="$emit('onClose')"
    :closeBtn="false"
    :openModel="isNew"
  >
    <div slot="content">
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label class="form-para mb-1 required">Hotel Name</label>
            <input
              placeholder="Enter Name"
              v-model.trim="formData.name"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label class="form-para mb-1 required">City</label>
            <destSelector
              v-if="!isUpdate"
              :selectInput="true"
              @onSelectItem="onSelected"
              :selectedData="formData.city"
              :asyncData="getCityListByName"
              placeholder="Enter Name"
            />
            <div
              v-else-if="isUpdate"
              class="form-control"
              style="background-color: rgb(233, 236, 239)"
            >
              {{ formItem.city ? formItem.city.name : "" }}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label class="form-para mb-1">Rating</label>
            <select class="form-control" v-model.trim="formData.rating">
              <option selected value>Choose Rating</option>
              <option value="1">1 Star</option>
              <option value="2">2 Star</option>
              <option value="3">3 Star</option>
              <option value="4">4 Star</option>
              <option value="5">5 Star</option>
            </select>
          </div>
        </div>
        <div class="col-8">
          <div class="form-group">
            <label class="form-para mb-1">Address</label>
            <textarea
              style="height: 90px"
              v-model.trim="formData.address"
              type="text"
              class="form-control"
              placeholder="Enter Address"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-right">
          <a
            @click.prevent="handleSubmitHotelForm"
            :class="{ disabled: $v.$invalid }"
            href="javascript:void(0)"
            class="btn btn-info mr-4"
            >{{ isUpdate ? "Update" : "Save" }}</a
          >
          <a
            @click.prevent="$emit('onClose')"
            href="javascript:void(0)"
            class="btn btn-danger"
            >Cancel</a
          >
        </div>
      </div>
    </div>
  </popup-model>
</template>

<script>
import destSelector from "../create/components/basic/dest-selector.vue";
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
export default {
  name: "InsertHotel",
  components: {
    destSelector
  },
  props: {
    isNew: { type: Boolean, default: false },
    isUpdate: { type: Boolean, default: false },
    formItem: { type: Object }
  },
  data() {
    return {
      hotelTypeList: [],
      formData: {
        name: "",
        city: {},
        address: "",
        rating: "",
        type: ""
      }
    };
  },
  created() {
    this.getAllPkgHotelsType().then(data => {
      this.hotelTypeList = data;
    });
  },
  validations: {
    formData: {
      name: { required },
      city: { required }
    }
  },
  watch: {
    formItem(value) {
      if (this._.isEmpty(value)) {
        this.formData = {
          name: "",
          city: {},
          address: "",
          rating: "",
          type: ""
        };
      } else {
        this.formData = value;
      }
    },
    isNew(value) {
      if (!value) {
        this.formData = {
          name: "",
          city: {},
          address: "",
          rating: "",
          type: ""
        };
        this.$emit("onClose");
      }
    }
  },
  methods: {
    ...mapActions([
      "createPackageHotel",
      "getAllPkgHotelsType",
      "getCityListByName"
    ]),
    onSelected(value) {
      this.formData.city = { ...value, name: value.destinationName };
    },
    handleSubmitHotelForm() {
      delete this.formData.createdBy;
      delete this.formData.updatedBy;
      delete this.formData.createdAt;
      delete this.formData.updatedAt;
      delete this.formData.type;
      this.createPackageHotel({
        ...this.formData,
        city: this.formData.city.id
      }).then(data => {
        this.$emit("onClose");
      });
    }
  }
};
</script>

<style scoped>
.insert_hotel >>> .popup_body {
  height: 370px;
  width: 40%;
  overflow: unset;
}
</style>
