<template>
  <div class="row">
    <div class="col-sm-6">
      <div class="row">
        <label
          v-if="!is_honeymoon"
          class="rc-control rc-control-checkbox col-sm-1"
        >
          <input
            v-model="validationData.adult.singleallowed.$model"
            type="checkbox"
            :disabled="disabledInputs"
          />
          <div class="rc-control-indicator"></div>
        </label>
        <p
          :class="{ 'ml-3': is_honeymoon }"
          class="form-para mb-2 col-sm-11 pl-1"
        >
          Adult
          <small>(per person with Single)</small>
        </p>
      </div>
      <input
        :disabled="
          disabledInputs ||
            !validationData.adult.singleallowed.$model ||
            this.is_honeymoon
        "
        type="number"
        v-model.trim.number="validationData.adult.single.$model"
        class="form-control"
      />
      <div class="row">
        <label class="rc-control rc-control-checkbox col-sm-1"> </label>
        <p class="form-para mb-2">
          Adult
          <small>(per person on twin sharing)</small>
        </p>
      </div>
      <input
        v-model.trim.number="validationData.adult.twin.$model"
        :disabled="disabledInputs || !validationData.adult.twinallowed.$model"
        type="number"
        class="form-control"
      />
      <div class="row">
        <label
          v-if="!is_honeymoon"
          class="rc-control rc-control-checkbox col-sm-1"
        >
          <input
            v-model="validationData.adult.extrabedallowed.$model"
            type="checkbox"
            :disabled="disabledInputs"
          />
          <div class="rc-control-indicator"></div>
        </label>
        <p :class="{ 'ml-3': is_honeymoon }" class="form-para mb-2">
          Adult
          <small>(per person With Extra Bed)</small>
        </p>
      </div>
      <input
        v-model.trim.number="validationData.adult.extrabed.$model"
        :disabled="
          disabledInputs ||
            !validationData.adult.extrabedallowed.$model ||
            this.is_honeymoon
        "
        type="number"
        class="form-control"
      />
      <div class="row">
        <label
          v-if="!is_honeymoon"
          class="rc-control rc-control-checkbox col-sm-1"
        >
          <input
            v-model="validationData.child.cwballowed.$model"
            type="checkbox"
            :disabled="disabledInputs"
          />
          <div class="rc-control-indicator"></div>
        </label>
        <p :class="{ 'ml-3': is_honeymoon }" class="form-para mb-2">
          CWB
          <small>(child with bed)</small>
        </p>
      </div>

      <input
        v-model.trim.number="validationData.child.cwb.$model"
        :disabled="
          disabledInputs ||
            !validationData.child.cwballowed.$model ||
            this.is_honeymoon
        "
        type="number"
        class="form-control"
      />
      <div class="row">
        <label
          v-if="!is_honeymoon"
          class="rc-control rc-control-checkbox col-sm-1"
        >
          <input
            v-model="validationData.child.cnballowed.$model"
            type="checkbox"
            :disabled="disabledInputs"
          />
          <div class="rc-control-indicator"></div>
        </label>
        <p :class="{ 'ml-3': is_honeymoon }" class="form-para mb-2">
          CNB
          <small>(child with no bed)</small>
        </p>
      </div>

      <input
        v-model.trim.number="validationData.child.cnb.$model"
        :disabled="
          disabledInputs ||
            !validationData.child.cnballowed.$model ||
            this.is_honeymoon
        "
        type="number"
        class="form-control"
      />
      <div class="row">
        <label
          v-if="!is_honeymoon"
          class="rc-control rc-control-checkbox col-sm-1"
        >
          <input
            v-model="validationData.infantallowed.$model"
            type="checkbox"
            :disabled="disabledInputs"
          />
          <div class="rc-control-indicator"></div>
        </label>
        <p :class="{ 'ml-3': is_honeymoon }" class="form-para mb-2">Infant</p>
      </div>

      <input
        v-model.trim.number="validationData.infant.$model"
        :disabled="
          disabledInputs ||
            !validationData.infantallowed.$model ||
            this.is_honeymoon
        "
        type="number"
        class="form-control mb-0"
      />
    </div>
    <div class="col-sm-6">
      <p class="form-para mb-2">Adult Surcharge</p>
      <input
        v-model.trim.number="validationData.adult.surcharge.$model"
        :disabled="
          disabledInputs ||
            (!validationData.adult.singleallowed.$model &&
              !validationData.adult.twinallowed.$model &&
              !validationData.adult.extrabedallowed.$model)
        "
        type="number"
        class="form-control"
      />
      <p class="form-para mb-2" style="margin-top:170px">CWB Surcharge</p>
      <input
        v-model.trim.number="validationData.child.cwbsurcharge.$model"
        :disabled="
          disabledInputs ||
            !validationData.child.cwballowed.$model ||
            this.is_honeymoon
        "
        type="number"
        class="form-control"
      />
      <p class="form-para mb-2">CNB Surcharge</p>
      <input
        v-model.trim.number="validationData.child.cnbsurcharge.$model"
        :disabled="
          disabledInputs ||
            !validationData.child.cnballowed.$model ||
            this.is_honeymoon
        "
        type="number"
        class="form-control"
      />
      <p class="form-para mb-2">Surcharge Description</p>
      <select
        v-model.trim.number="validationData['surcharge-description'].$model"
        :disabled="disabledInputs"
        class="form-control"
      >
        <option value>Choose</option>
        <option value="Hotel">Hotel</option>
        <option value="Flight">Flight</option>
        <option value="Weekend">Weekend</option>
        <option value="Long Weekend">Long Weekend</option>
        <option value="Season">Season</option>
        <option value="Peak Season">Peak Season</option>
        <option value="Gala Dinner">Gala Dinner</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "Prices",
  props: {
    is_honeymoon: { type: Boolean },
    modifiyEvent: { type: String },
    validation: { type: Object }
  },
  computed: {
    disabledInputs() {
      return this.modifiyEvent == "disabled" || this.modifiyEvent == "";
    },
    validationData() {
      return this.validation;
    }
  }
};
</script>

<style></style>
