<template>
  <div class="card">
    <div>
      <div class="card-header">
        <button
          @click.prevent="$emit('onClickTab')"
          :class="{ collapsed: activeTabs }"
          class="btn btn-link"
          type="button"
        >
          Package Availability
          <!-- Accordian Arrow -->
          <v-arrowicon />
        </button>
      </div>
      <div
        :class="{ show: activeTabs }"
        id="packageAvailability"
        class="collapse"
        data-parent="#services_accordion"
        style
      >
        <div class="card-body">
          <div class="row">
            <div class="col-7">
              <!-- Calender Section -->

              <v-calender
                :currentMonth="currentMonth"
                @onClickNext="onClickNext"
                @onClickPrev="onClickPrev"
                :onSelectDate="onSelectionDates"
                :highlight-dates="allHighlightDates"
              />
              <div class="mt-2 row">
                <div class="col-md-3">
                  <!--  <p
                    v-if="modifiyEvent == 'insert'"
                    @click.prevent="handleSectionDates"
                    class="m-0 selectionBtn"
                  >
                    Select All
                  </p>-->
                </div>
                <div class="col-md-7">
                  <ul class="list-inline text-center">
                    <li class="list-inline-item surcharge-color">Surcharge</li>
                    <li class="list-inline-item active-color">Active</li>
                    <li class="list-inline-item deactive-color">
                      Disabled
                    </li>
                    <!-- <li class="list-inline-item disabled-color">Disabled</li> -->
                  </ul>
                </div>
              </div>
              <!-- <p v-if="this.modifiyEvent=='insert'" class="mt-1 select-btn">Select All</p> -->

              <ul class="list-inline mt-2 event-radio text-center">
                <li
                  @click.prevent="checkIfSameModify('insert')"
                  class="list-inline-item"
                >
                  <label for="insert" class="radio">
                    <input
                      v-model="modifiyEvent"
                      value="insert"
                      type="radio"
                      name="action"
                      id="insert"
                    />
                    <span>Insert New Dates</span>
                  </label>
                </li>
                <!--   <li
                  @click.prevent="checkIfSameModify('updateall')"
                  class="list-inline-item"
                >
                  <label for="updateall" class="radio">
                    <input
                      v-model="modifiyEvent"
                      value="updateall"
                      type="radio"
                      name="action"
                      id="updateall"
                    />
                    <span>Update Selected Dates</span>
                  </label>
                </li>-->
                <li
                  @click.prevent="checkIfSameModify('disabled')"
                  class="list-inline-item"
                >
                  <label for="disabled" class="radio">
                    <input
                      v-model="modifiyEvent"
                      value="disabled"
                      type="radio"
                      name="action"
                      id="disabled"
                    />
                    <span>Enable/Disable Dates</span>
                  </label>
                </li>
              </ul>

              <p
                @click="isDateModel = true"
                class="text-center fnt-16 my-3 font-bold text-uppercase"
              >
                {{ totalDate }} Dates Selected
              </p>
            </div>
            <div class="col-5">
              <div class="rc-radio-group mt-0">
                <!-- Bind All Options And Categories -->
                <label
                  v-for="item in availableOptions(pkgOptions.items)"
                  :key="item.type"
                  class="radio"
                >
                  <input
                    @change.prevent="changeOptionType(item.type)"
                    :checked="selectedOptionType == item.type"
                    name="pkg-options-3"
                    type="radio"
                  />
                  <span :class="{ honeymoon_pink: item.is_honeymoon }">
                    {{ item.type }}
                  </span>
                </label>
              </div>

              <!-- Prices Section -->
              <prices-vue
                :is_honeymoon="isHoneyMoonOption"
                :validation="$v.datesPrices"
                :modifiyEvent="modifiyEvent"
              />

              <p class="text-center mb-0">
                <button
                  :disabled="
                    checkIsDatesSelected ||
                      (modifiyEvent != 'disabled' && $v.$invalid)
                  "
                  @click="handleSaveData('newSelectedDates')"
                  href="javascript:void(0)"
                  class="btn btn-submit ripple btn-hotel mr-3"
                >
                  Save Dates
                </button>
                <a
                  target="_blank"
                  class="btn btn-submit ripple btn-hotel"
                  :href="
                    `/packages/available-dates?pkg_code=${$route.query.code}`
                  "
                  >New Dates</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <popup-model @close-model="isDateModel = false" :openModel="isDateModel">
      <div slot="content" class="mt-4">
        <p>
          Available Dates for Package :
          <b class="clr-blue">{{ selectedOptionType }}</b>
        </p>
        <ul>
          <li
            style="list-style:none;"
            v-for="(item, key) in allHighlightDates"
            :key="key"
          >
            <strong>{{
              $moment(convertDateWithKey(key, 11)).format("MMMM-YYYY")
            }}</strong>
            <ul class="avail-dates mt-2 mb-2">
              <li
                v-for="date in filterSelectedDateView(item)"
                :key="date.value"
              >
                {{
                  $moment(convertDateWithKey(key, date.value)).format(
                    "DD-MMM-YYYY"
                  )
                }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </popup-model>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
import pricesVue from "./prices.vue";
import { requiredIf } from "vuelidate/lib/validators";
import methods from "./helpers/methods";
export default {
  name: "Availablity",
  mixins: [methods],
  props: {
    onClickTab: { type: Function },
    activeTabs: { type: Boolean }
  },
  components: {
    pricesVue
  },
  created() {
    this.manageTotalDates();
    this.getHighLightDates();
  },
  data() {
    return {
      totalDate: 0,
      dateChanges: false,
      isDateModel: false,
      allHighlightDates: {},
      newDateSelected: true,
      modifiyEvent: "",
      currentMonth: this.$moment(),
      toastMessages: {
        options: {
          success: ``
        }
      }
    };
  },
  computed: {
    ...mapGetters([
      "pkgOptions",
      "selectedOptionType",
      "datesPrices",
      "newSelectedDates"
    ]),
    isHoneyMoonOption() {
      const findData = this.pkgOptions.items.find(
        data => data.type == this.selectedOptionType
      );
      if (findData) {
        return findData.is_honeymoon;
      } else {
        return false;
      }
    },
    checkIsDatesSelected() {
      if (this.modifiyEvent == "") {
        return true;
      }
      let textFields = false;
      if (this.isHoneyMoonOption) {
        textFields = this.datesPrices.adult.twin == "";
      } else {
        textFields =
          this.datesPrices.adult.single == "" &&
          this.datesPrices.adult.twin == "" &&
          this.datesPrices.adult.extrabed == "";
      }

      if (this.modifiyEvent === "updateall") {
        return textFields;
      } else if (this.modifiyEvent === "insert") {
        return this.newDateSelected || textFields;
      } else {
        return false;
      }
    }
  },
  watch: {
    modifiyEvent() {
      this.setNewSelectionDate();
      this.resetPricesOnChange();
      this.getHighLightDates();
      this.manageTotalDates();
    },
    selectedOptionType() {
      this.setNewSelectionDate();
      this.getHighLightDates();
      this.resetPricesOnChange();
      this.manageTotalDates();
    },
    dateChanges() {
      this.getHighLightDates();
      this.checkNewDatesExits();
      this.manageTotalDates();
    }
  },

  methods: {
    ...mapMutations([
      "changeOptionType",
      "manageSelectionDates",
      "handleUpdateAndActiveDates",
      "isFieldsDataExists",
      "resetPricesOnChange",
      "setNewSelectionDate"
    ]),
    ...mapActions(["updatePackage"]),
    handleSectionDates() {
      if (this.modifiyEvent == "insert") {
        let index = 1;
        if (this.$moment().isSame(this.currentMonth, "month")) {
          index = this.currentMonth.date();
        }
        for (index; index <= this.currentMonth.daysInMonth(); index++) {
          let curDate =
            this.currentMonth.format("MM/") +
            index +
            this.currentMonth.format("/YYYY");
          let date = this.$moment(new Date(curDate));
          this.manageSelectionDates({ date });
        }
        this.getHighLightDates();
        this.checkNewDatesExits();
        this.manageTotalDates();
        this.dateChanges = !this.dateChanges;
      }
    },
    availableOptions(opt) {
      return opt.filter(val => !val.isNew);
    }
  },
  validations: {
    datesPrices: {
      adult: {
        single: {
          required: requiredIf(function(val) {
            if (!val.singleallowed) {
              val.single = "";
            }
            return !this.isHoneyMoonOption && val.singleallowed;
          })
        },
        singleallowed: {},
        twin: {
          required: requiredIf(val => {
            return val.twinallowed;
          })
        },
        twinallowed: {},
        extrabed: {
          required: requiredIf(function(val) {
            if (!val.extrabedallowed) {
              val.extrabed = "";
            }
            return !this.isHoneyMoonOption && val.extrabedallowed;
          })
        },
        extrabedallowed: {},
        surcharge: {}
      },
      child: {
        cwb: {
          required: requiredIf(function(val) {
            if (!val.cwballowed) {
              val.cwb = "";
              val.cwbsurcharge = "";
            }
            return !this.isHoneyMoonOption && val.cwballowed;
          })
        },
        cwballowed: {},
        cnb: {
          required: requiredIf(function(val) {
            if (!val.cnballowed) {
              val.cnb = "";
              val.cnbsurcharge = "";
            }
            return !this.isHoneyMoonOption && val.cnballowed;
          })
        },
        cnballowed: {},
        cwbsurcharge: {},
        cnbsurcharge: {}
      },
      "surcharge-description": {},
      infant: {
        required: requiredIf(function(val) {
          if (!val.infantallowed) {
            val.infant = "";
          }
          return !this.isHoneyMoonOption && val.infantallowed;
        })
      },
      infantallowed: {}
    }
  }
};
</script>

<style scoped>
.list-inline-item::after {
  content: "";
  display: block;
  height: 10px;
  width: 10px;
  float: left;
  top: 4px;
  position: relative;
  margin-right: 5px;
}

.surcharge-color::after {
  background-color: #5457ad;
}
.active-color::after {
  background-color: #17a2b8;
}
.deactive-color::after {
  background-color: #929292;
}
.disabled-color::after {
  background-color: #e5e5e5;
}

.event-radio label.radio {
  margin: 0px !important;
}

.select-btn {
  cursor: pointer;
}

.selectionBtn {
  background: #828e80;
  text-align: center;
  color: #fff;
  width: 90px;
  padding: 2px 4px;
  cursor: pointer;
}
.honeymoon_pink {
  color: hotpink !important;
}
.honeymoon_pink::after {
  background: hotpink !important;
}
.honeymoon_pink::before {
  border-color: hotpink !important;
}
</style>
