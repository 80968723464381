<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 right-border-color">
        <div class="row no-gutters">
          <div
            class="col-md-12 d-flex justify-content-between align-items-center"
          >
            <label class="labelWidth" for="">Search</label>
            <input
              v-model.trim="filterBy.searchOne.freeText"
              type="text"
              placeholder="Search By Package Code/Name"
              class="form-control form-control-sm"
            />
          </div>
          <div class="col-md-12 d-flex justify-content-end mt-3">
            <button
              @click="onSearchButton('searchOne')"
              :disabled="filterBy.searchOne.freeText === ''"
              class="btn btn-info btn-sm"
            >
              Search
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row no-gutters mb-3">
          <div
            class="col-10 col-md-11 d-flex justify-content-between align-items-center"
          >
            <label class="labelWidth">Country</label>
            <select
              :disabled="countryLoading"
              @change="onSelectedCountry"
              v-model="filterBy.searchTwo.country"
              class="form-control form-control-sm"
            >
              <option value="all">All</option>
              <option
                v-for="item in countryList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.destinationName }}
              </option>
            </select>
          </div>
          <div class="col-2 col-md-1">
            <div v-if="countryLoading" class="data-loader">
              <Loader />
            </div>
          </div>
        </div>
        <div class="row no-gutters my-3">
          <div
            class="col-12 col-md-11 d-flex justify-content-between align-items-start"
          >
            <label class="labelWidth">Cities</label>
            <div class="w-100">
              <select
                :disabled="cityList.length == 0"
                @change="onSelectedCity"
                class="form-control form-control-sm"
              >
                <option value="all">Select City</option>
                <option
                  v-for="item in cityList"
                  :key="item.id"
                  :value="JSON.stringify(item)"
                >
                  {{ item.destinationName }}
                </option>
              </select>
              <div class="chips mt-3 d-flex align-items-center">
                <span
                  @click.prevent="filterBy.searchTwo.city.splice(index, 1)"
                  v-for="(item, index) in filterBy.searchTwo.city"
                  :key="item.id"
                  class="dest_chips"
                  >{{ item.destinationName }}</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="row no-gutters my-3">
          <div
            class="col-12 col-md-11 d-flex justify-content-between align-items-center"
          >
            <label class="labelWidth">Status</label>
            <select
              v-model="filterBy.searchTwo.status"
              class="form-control form-control-sm mb-0"
            >
              <option value="all">All</option>
              <option value="available">Available</option>
              <option value="sold">Sold Out</option>
              <option value="request">On Request</option>
              <option value="static">Static</option>
            </select>
          </div>
        </div>
        <div class="row no-gutters my-3">
          <div
            class="col-12 col-md-11 d-flex justify-content-between align-items-center"
          >
            <label class="labelWidth">Type</label>
            <select
              v-model.trim="filterBy.searchTwo.pkgtype"
              class="form-control form-control-sm mb-0"
            >
              <option value="all">All</option>
              <option value="pkg_origin">Land Packages</option>
              <option value="is_feature">Featured</option>
            </select>
          </div>
        </div>
        <div class="row no-gutters my-3">
          <div class="col-11 d-flex justify-content-end">
            <button
              @click="onSearchButton('searchTwo')"
              class="btn btn-info btn-sm"
            >
              Search
              <div
                v-if="packagesLoading"
                class="data-loader"
                style="position: unset"
              >
                <Loader />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "FilterList",
  data() {
    return {
      countryList: [],
      cityList: [],
      countryLoading: false,
      filterBy: {
        searchOne: {
          freeText: ""
        },
        searchTwo: {
          country: "all",
          city: [],
          status: "all",
          pkgtype: "all"
        }
      }
    };
  },
  created() {
    this.countryLoading = true;
    this.getPackageDestinations().then(data => {
      this.countryList = data;
      this.countryLoading = false;
    });
  },
  computed: {
    ...mapGetters(["packagesLoading"])
  },
  methods: {
    ...mapActions(["getCityListByName", "getPackageDestinations"]),
    onSelectedCity(value) {
      const cityData = JSON.parse(value.currentTarget.value);
      if (
        this.filterBy.searchTwo.city.findIndex(
          data => data.id === cityData.id
        ) == -1
      ) {
        delete cityData.__typename;
        this.filterBy.searchTwo.city.push(cityData);
      }
      value.currentTarget.value = "all";
    },
    onSearchButton(type) {
      let finalData = {
        ...this.filterBy[type]
      };
      if (type == "searchTwo") {
        for (let item in finalData) {
          const val = finalData[item];
          if (val == "" || val == "all" || val.length == 0) {
            delete finalData[item];
          }
        }
        if (finalData.city && finalData.city.length) {
          delete finalData.country;
          finalData = {
            ...finalData,
            city: finalData.city.map(data => data.id)
          };
        }
      }

      this.$emit("onSearchButton", finalData);
    },
    onSelectedCountry() {
      if (this.filterBy.searchTwo.country !== "all") {
        this.cityList = [];
        const selectCountry = this.countryList.find(
          data => data.id == this.filterBy.searchTwo.country
        );
        if (selectCountry) {
          this.cityList = selectCountry.cities;
        }
      } else {
        this.cityList = [];
        this.filterBy.searchTwo.city = [];
      }
    }
  }
};
</script>

<style scoped>
.labelWidth {
  margin: 0;
  width: 30%;
  font-weight: bold;
}
.chips {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
}

.dest_chips {
  border-radius: 50px;
  padding: 1px 10px;
  background: transparent;
  border: 1px solid #17a2b8;
  color: #17a2b8;
  cursor: pointer;
  font-size: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.dest_chips:last-child {
  margin-right: 0;
}

/* .dest_chips:not(:nth-child(:last-child)) {
  margin-right: 0;
} */

.dest_chips > small {
  font-size: 20px;
  text-align: right;
  top: 2px;
  position: relative;
  right: -4px;
  cursor: pointer;
}

.dest_chips > small:hover {
  color: #f56d6d;
}

.parent-row .form-control {
  font-size: 12px;
}

.right-border-color {
  border-right: 1px solid #adadad;
}

.form-para {
  color: #3c3c3c;
  font-size: 12px;
  font-weight: 700;
}

.data-loader {
  position: relative;
}
.data-loader >>> .lds-spinner {
  height: 0px;
  width: 0px;
  display: unset;
  position: absolute;
  right: 50px;
  top: -18px;
}

.data-loader >>> .lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 23px;
  left: 31px;
  width: 2px;
  height: 6px;
  border-radius: 20%;
  background: black;
}
.radio-label {
  cursor: pointer;
}
</style>
