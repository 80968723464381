import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions(["fetchPackageDatesPricing", "updatePackageDatesPricing"]),
    handleMonthChange(type) {
      if (type == "next") {
        this.currentDate = this.$moment(this.currentDate).add(1, "month");
      } else {
        this.currentDate = this.$moment(this.currentDate).subtract(1, "month");
      }
    },
    bindDatesOnPageLoad(date) {
      const month = this.$moment(date.day).format("MM-YYYY");
      if (this.selectedDates.length != 0) {
        const isMonth = this.selectedDates.findIndex(_m => _m.month === month);
        if (isMonth != -1) {
          const isDate = this.selectedDates[isMonth]?.items?.findIndex(_d =>
            this.$moment(_d.day).isSame(this.$moment(date.day), "days")
          );
          if (isDate != -1) {
            this.selectedDates[isMonth]?.items.splice(isDate, 1);
            if (this.selectedDates[isMonth]?.items.length == 0) {
              this.selectedDates.splice(isMonth, 1);
            }
          } else {
            this.selectedDates[isMonth].items.push({
              day: this.$moment(date.day),
              active: "active" in date ? date.active : true,
              prices:
                date?.prices || JSON.parse(JSON.stringify(this.datesPricing))
            });
          }
        } else {
          this.selectedDates.push({
            month,
            items: [
              {
                day: this.$moment(date.day),
                active: "active" in date ? date.active : true,
                prices:
                  date?.prices || JSON.parse(JSON.stringify(this.datesPricing))
              }
            ]
          });
        }
      } else {
        this.selectedDates.push({
          month,
          items: [
            {
              day: this.$moment(date.day),
              active: "active" in date ? date.active : true,
              prices:
                date?.prices || JSON.parse(JSON.stringify(this.datesPricing))
            }
          ]
        });
      }
    },
    handleSelectDates(date) {
      const month = this.$moment(date.day).format("MM-YYYY");
      if (this.date_types != "") {
        if (this.modifiedDates.length != 0) {
          const isMonth = this.modifiedDates.findIndex(
            _m => _m.month === month
          );
          if (isMonth != -1) {
            const isDate = this.modifiedDates[isMonth]?.items?.findIndex(_d =>
              this.$moment(_d.day).isSame(this.$moment(date.day), "days")
            );
            if (isDate != -1) {
              if (this.date_types == "insert") {
                this.modifiedDates[isMonth]?.items.splice(isDate, 1);
                if (this.modifiedDates[isMonth]?.items.length == 0) {
                  this.modifiedDates.splice(isMonth, 1);
                }
              }
              if (this.date_types == "update") {
                this.modifiedDates[isMonth]?.items.splice(isDate, 1, {
                  ...this.modifiedDates[isMonth]?.items[isDate],
                  is_update: !this.modifiedDates[isMonth]?.items[isDate]
                    ?.is_update
                });
              }
              if (this.date_types == "disabled") {
                this.modifiedDates[isMonth]?.items.splice(isDate, 1, {
                  ...this.modifiedDates[isMonth]?.items[isDate],
                  is_update: true,
                  active: !this.modifiedDates[isMonth]?.items[isDate]?.active
                });
              }
            } else {
              if (this.date_types == "insert") {
                this.modifiedDates[isMonth].items.push({
                  day: this.$moment(date.day),
                  active: "active" in date ? date.active : true,
                  prices: date?.prices || { ...this.pricing }
                });
              }
            }
          } else {
            this.modifiedDates.push({
              month,
              items: [
                {
                  day: this.$moment(date.day),
                  active: "active" in date ? date.active : true,
                  prices:
                    date?.prices ||
                    JSON.parse(JSON.stringify(this.datesPricing))
                }
              ]
            });
          }
        } else {
          this.modifiedDates.push({
            month,
            items: [
              {
                day: this.$moment(date.day),
                active: "active" in date ? date.active : true,
                prices:
                  date?.prices || JSON.parse(JSON.stringify(this.datesPricing))
              }
            ]
          });
        }
      }
      if (this.date_types == "") {
        this.pricing = date.prices;
      }
    }
  }
};
