<template>
  <container headTitle="Manage Menus">
    <div slot="content" style="width: 99.5%">
      <div class="container-fluid px-0">
        <div class="row no-gutters mb-3">
          <div class="col-md-1 p-0">
            <div class="user">User</div>
          </div>
          <div class="col-md-4 p-0">
            <label
              class="form-group"
              :class="{ invalid: this.notselected }"
              for="user"
            >
              <vue-autosuggest
                :suggestions="filteredOptions"
                @selected="onSelected"
                :limit="10"
                :render-suggestion="renderSuggestion"
                :input-props="inputProps"
                @input="onInputChange"
              ></vue-autosuggest>
            </label>
          </div>
        </div>

        <div class="row">
          <div class="col-md-2">
            <ul class="v-tabs tabs-left">
              <li
                v-for="item in menuItems.leftMenu"
                :key="item.id"
                @click="menuItems.activeTab = item.id"
                :class="{ active: menuItems.activeTab == item.id }"
              >
                <a>{{ item.name }}</a>
              </li>
            </ul>
          </div>

          <div class="col-md-10">
            <!-- Right hand side menu  For Selection Of Menus items -->
            <right-menu
              :parent="menuItems.activeTab"
              :menu-data="rightMenuData"
            />

            <div
              class="d-flex justify-content-md-between align-items-center pb-3"
            >
              <div>
                <span>{{ successMessage }}</span>
              </div>
              <div class="text-right">
                <button
                  :disabled="selected == ''"
                  @click="handleSubmitData"
                  class="btn btn-primary btn-info pull-right"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </container>
</template>

<script>
import rightMenu from "./manage-menus/right-menu";
import { VueAutosuggest } from "vue-autosuggest";
import { mapGetters } from "vuex";
import _ from "lodash";
export default {
  name: "managemenu",
  components: {
    rightMenu,
    VueAutosuggest
  },
  data() {
    return {
      successMessage: "",
      menuItems: {
        activeTab: "",
        leftMenu: [],
        rightMenu: {}
      },
      notselected: false,
      selected: "",
      filteredOptions: [],
      inputProps: {
        id: "user_input",
        class: "form-control form-control-sm",
        type: "text",
        placeholder: "Search By Name And Username"
      },
      limit: 10
    };
  },
  watch: {
    successMessage() {
      this.timer = setTimeout(() => {
        this.successMessage = "";
        this.selected = "";
        document.querySelector("#user_input").value = "";
        clearTimeout(this.timer);
      }, 3000);
    }
  },
  computed: {
    ...mapGetters(["users", "selectedMenus"]),
    rightMenuData() {
      return this.menuItems.rightMenu[this.menuItems.activeTab];
    },
    isEmptySelectMenu() {
      return _.isEmpty(this.selectedMenus);
    }
  },
  async created() {
    await this.fetchMeunsFromServer();
    this.$store.dispatch("getSupplierUsers");
  },
  methods: {
    filterSelectedUserMenu() {
      let localThis = this;
      for (let item in localThis.selectedMenus) {
        let notactive = localThis.menuItems.rightMenu[item].notactive;
        let newActive = [],
          newNotActive = [];
        for (let childItem = 0; childItem < notactive.length; childItem++) {
          if (
            localThis.selectedMenus[item].includes(notactive[childItem]._id)
          ) {
            newActive.push(notactive[childItem]);
          } else {
            newNotActive.push(notactive[childItem]);
          }
        }
        localThis.menuItems.rightMenu[item].active = newActive;
        localThis.menuItems.rightMenu[item].notactive = newNotActive;
      }
    },
    /* Auto Suggest Methods */
    onSelected(option, clear) {
      this.$store.dispatch("getUserMenus", option.item.id).then(async data => {
        let userSelectedMenu = {};
        await this.fetchMeunsFromServer();
        if (data) {
          for (let item of data.menuitems) {
            userSelectedMenu[item._id] = [item._id];
            for (let child of item.child) {
              userSelectedMenu[item._id].push(child._id);
            }
          }
          this.$store.commit("setSelectedMenuItem", {
            itemByUser: userSelectedMenu
          });
          this.filterSelectedUserMenu();
        }
      });
      this.selected = option.item;
    },
    onInputChange(text) {
      this.selected = "";
      if (text === "" || text === undefined) {
        this.notselected = false;
        this.fetchMeunsFromServer();
        return;
      }

      // Full customizability over filtering
      const filteredData = this.users
        .filter(option => {
          return option.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);

      this.filteredOptions = [
        {
          data: filteredData
        }
      ];
    },
    renderSuggestion(suggestion) {
      const character = suggestion.item;
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <span style={{ color: "navyblue" }}>
            {character.name}{" "}
            <span style={{ fontSize: 10, color: "#333333" }}>
              ({character.username})
            </span>
          </span>
        </div>
      );
    },
    /* End Auto Suggestion Methods */
    handleSubmitData() {
      let Localdata = this;
      if (Localdata.selected == "") {
        this.notselected = true;
      } else if (_.isEmpty(this.selectedMenus)) {
        alert("atleast a single menu item must be selected");
      } else {
        //document.querySelector("#user_input").value = "";
        const newData = _.valuesIn(this.selectedMenus).flat();
        this.$store
          .dispatch("setMenusItemToUser", {
            _id: Localdata.selected.id,
            data: newData,
            compType: "Affiliate"
          })
          .then(data => {
            setTimeout(() => {
              document.querySelector("#user_input").value = "";
            }, 500);
            this.successMessage = `${data.username} is updated`;
            Localdata.fetchMeunsFromServer();
            Localdata.filteredOptions = [];
            Localdata.selected = "";
          });
      }
      //
    },
    fetchMeunsFromServer() {
      this.menuItems = {
        activeTab: "",
        leftMenu: [],
        rightMenu: {}
      };
      return this.$store
        .dispatch("getMenuForUsers", { compType: "Affiliate" })
        .then(data => {
          if (data.length > 0) {
            this.menuItems.activeTab = data[0]._id;
            for (var item = 0; item < data.length; item++) {
              this.menuItems.leftMenu.push({
                id: data[item]._id,
                name: data[item].parentname
              });
              this.menuItems.rightMenu[data[item]._id] = data[item].submenu;
            }
          }
        });
    }
  }
};
</script>

<style scoped>
.c-top-head {
  font-size: 16px;
  font-weight: 600;
  vertical-align: middle;
  color: #5a738e;
}

.section-bg {
  background-color: #f1f1f1;
  padding: 15px;
}
.btn {
  padding: 2px 10px;
  font-size: 12px;
}
/*vertical tabs starts*/
.tabs-left > li.active > a,
.tabs-left > li.active > a:hover,
.tabs-left > li.active > a:focus {
  border-left: 2px solid #17a2b8;
  background-color: #fff;
  color: #17a2b8;
}

.tabs-left > li > a {
  border-radius: 4px 0 0 4px;
  margin-right: 0;
  display: block;
}

.v-tabs {
  padding: 0;
  margin: 0;
  list-style-type: none;
  border-right: 1px solid rgba(23, 162, 184, 0.37);
}

.v-tabs li {
  float: none;
  padding: 0;
  margin: 0;
}

.v-tabs li:hover {
  background-color: #fff;
  color: #17a2b8;
  cursor: pointer;
}

.v-tabs li a {
  color: #73879c;
  text-decoration: none;
  border-left: 2px solid transparent;
  border-radius: 0;
  margin-right: 0;
  display: block;
  padding: 6px;
}

.v-tabs li:hover a,
.v-tabs li:active a,
.v-tabs li:focus a {
  text-decoration: none;
  border-left: 2px solid #17a2b8;
  background-color: #fff;
  color: #17a2b8;
}
/*vertical tabs end*/

/* .switch-btn {
  padding: 0;
} */
label {
  display: block;
  margin-bottom: 0.5rem;
}

.user {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #fff;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .switch-btn input.form-control {
  border-radius: 0 !important;
  box-shadow: none !important;
  margin: 0px !important;
} */
</style>
