<template>
  <div id="ims_setting" data-parent="#services_accordion" class="ims_setting_container p-2 collapse">
    <div class="ims_setting_destination">
      <div class="ims_setting_dest_content">
        <div class="row no-gutters">
          <div class="col-md-6">
            <div class="row no-gutters">
              <div class="col-3 col-md-3">
                <div class="form-group mb-0 mr-3" :class="{ invalid: $v.form_data.origin.$error }">
                  <label for="">Origin</label>
                  <select @blur="$v.form_data.origin.$touch()" v-model.trim="form_data.origin" class="form-control form-control-sm">
                    <option value="">Choose</option>
                    <option v-for="_dest in destinations" :key="_dest.id" :value="_dest.id">{{ _dest.destinationName }}</option>
                  </select>
                </div>
              </div>
              <div class="col-3 col-md-3">
                <div class="form-group mb-0" :class="{ invalid: $v.form_data.destination.$error }">
                  <label for="">Destination</label>
                  <select @blur="$v.form_data.destination.$touch()" v-model.trim="form_data.destination" class="form-control form-control-sm">
                    <option value="">Choose</option>
                    <option v-for="_dest in destinations" :key="_dest.id" :value="_dest.id">{{ _dest.destinationName }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <table class="table mt-2 mb-0">
              <thead class="table-teal">
                <tr>
                  <th style="width: 150px">Airline</th>
                  <th style="width: 70px" class="">Nights</th>
                  <th class="">Number</th>
                  <th title="Markup ( 100 ) And Discount ( -100 )">M / D</th>
                  <th style="width: 50px"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(_airline, index) in $v.form_data.airlines.$each.$iter" :key="index">
                  <td>
                    <div class="form-group mb-0" :class="{ invalid: _airline.code.$error }">
                      <select @blur="_airline.code.$touch()" :disabled="!_airline.active.$model" v-model.trim="_airline.code.$model" class="form-control form-control-sm">
                        <option value="">Choose</option>
                        <option v-for="_air in airlines" :key="_air._id" :value="_air.code">{{ _air.name }}</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <div class="form-group mb-0">
                      <input v-input-type="'number'" :disabled="!_airline.active.$model" v-model.trim="_airline.nights.$model" type="text" class="form-control form-control-sm" />
                    </div>
                  </td>
                  <td>
                    <div class="form-group mb-0">
                      <input :disabled="!_airline.active.$model" v-model.trim="_airline.flight_no.$model" type="text" class="form-control form-control-sm" />
                    </div>
                  </td>
                  <td>
                    <div class="form-group mb-0">
                      <input :disabled="!_airline.active.$model" v-input-type="'number_negative'" v-model.trim.number="_airline.amount.$model" type="text" class="form-control form-control-sm" />
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <input v-model="_airline.active.$model" type="checkbox" class="active_checkbox" />
                      <a @click.prevent="form_data.airlines.splice(index, 1)" class="close_btn" href="javascript:void(0)">&times;</a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="mt-2 d-flex justify-content-center">
              <a @click.prevent="add_new_flight" class="add_flight_button" href="javascript:void(0)">Add Flight</a>
            </div>
          </div>
        </div>
        <hr class="my-2" />
        <div class="d-flex justify-content-center">
          <button @click.prevent="handleSaveData" href="javascript:void(0)" class="btn btn-submit ripple mb-0">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
export default {
  name: "ImsSetting",
  data() {
    return {
      destinations: [],
      airlines: [],
      form_data: {
        origin: "",
        destination: "",
        is_return_different: false,
        airlines: []
      }
    };
  },
  computed: {
    ...mapGetters(["packageDetails"])
  },
  async created() {
    this.destinations = await this.getPackageDestinationsCities();
    this.airlines = await this.fetchImsAirlines();
    if (this?.packageDetails?.ims_setting) {
      this.form_data = this?.packageDetails?.ims_setting;
    }
    if (!this?.packageDetails?.ims_setting?.origin && this.packageDetails?.pkg_origin?.id) {
      this.form_data.origin = this?.packageDetails?.pkg_origin?.id;
    }
  },
  methods: {
    ...mapActions(["updatePackage", "getPackageDestinationsCities", "fetchImsAirlines"]),
    handleSaveData() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.updatePackage({
          type: "ADVANCE",
          data: {
            advance: {
              code: this.$route.query.code,
              data: { ims_setting: this.form_data }
            }
          }
        }).then(() => {
          this.$toasted.success("Ims Setting Is Updated", {
            position: "bottom-center",
            duration: 5000
          });
        });
      }
    },
    add_new_flight() {
      this.form_data.airlines.push({ code: "", nights: "", flight_no: "", amount: "", active: true });
    }
  },
  validations() {
    return {
      form_data: {
        origin: { required },
        destination: { required },
        airlines: {
          $each: {
            code: {
              required: requiredIf((data) => {
                return data.active;
              })
            },
            nights: {},
            flight_no: {},
            amount: {},
            active: {}
          }
        }
      }
    };
  }
};
</script>

<style scoped>
.close_btn {
  font-weight: 600;
  font-size: 24px;
  text-decoration: none;
  line-height: 1.15;
  color: red;
  position: relative;
  bottom: 2px;
  margin-left: 5px;
}

.active_checkbox {
  cursor: pointer;
}

.add_flight_button {
  font-weight: 500;
  font-size: var(--fnt-13);
  text-decoration: none;
}
</style>