<template>
  <container headTitle="Manage Package Seo">
    <div slot="createbtn">
      <div class="row">
        <div class="offset-6 col-3">
          <select
            v-model="destinations"
            @change="getPackages(destinations)"
            id="countryselect"
            class="form-control form-control-sm m-0"
          >
            <option value>Choose Country</option>
            <option
              v-for="item in information"
              :key="item.dest_id"
              :value="item.dest_id"
              >{{ item.destName }}</option
            >
          </select>
        </div>
        <div class="col-3">
          <div class="row no-gutters">
            <div class="col-8">
              <input
                v-model="code"
                type="text"
                placeholder="Search By Code"
                class="form-control form-control-sm m-0"
                id="searchbycode"
              />
            </div>
            <div class="col-4">
              <button
                :disabled="code == ''"
                @click="getPackagesByCode(code)"
                class="btn btn-info submit-btn"
                style="border-radius: 0px !important"
                type="submit"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="content">
      <table class="table">
        <thead class="table-teal" style="top: 0">
          <tr>
            <th>Service Name</th>
            <th>Product Code</th>
            <th>Page Title</th>
            <th>Meta Description</th>
            <th>Meta Keywords</th>
            <th>Url</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in packages" :key="item.code">
            <td>
              <p>{{ item.name }}</p>
            </td>
            <td>
              <p>{{ item.code }}</p>
            </td>
            <td>
              <p v-if="editField != item.code">
                {{ item.seo ? item.seo.pagetitle : "" }}
              </p>
              <textarea
                type="text"
                v-else-if="editField == item.code"
                v-model="item.seo.pagetitle"
              ></textarea>
            </td>
            <td>
              <p v-if="editField != item.code">
                {{ item.seo ? item.seo.metadescription : "" }}
              </p>
              <textarea
                type="text"
                v-else-if="editField == item.code"
                v-model="item.seo.metadescription"
              ></textarea>
            </td>
            <td>
              <p v-if="editField != item.code">
                {{ item.seo ? item.seo.metakeywords : "" }}
              </p>
              <textarea
                type="text"
                v-else-if="editField == item.code"
                v-model="item.seo.metakeywords"
              ></textarea>
            </td>
            <td>
              <p v-if="editField != item.code">
                {{ item.seo ? item.seo.url : "" }}
              </p>
              <textarea
                type="text"
                v-else-if="editField == item.code"
                v-model="item.seo.url"
              ></textarea>
            </td>
            <td>
              <VueButtonSpinner
                v-if="editField != item.code"
                class="btn btn-info btn-sm"
              >
                <span @click="editRowField('edit', item.code, item)">Edit</span>
              </VueButtonSpinner>
              <div class="d-inline-flex" v-else>
                <VueButtonSpinner
                  class="btn btn-success btn-sm mr-2"
                  :is-disabled="item.seo.pagetitle == ''"
                >
                  <span
                    :class="{ disabled: item.seo.pagetitle == '' }"
                    @click="UpdatePackageDtls('edit', item.code, item)"
                    >Save</span
                  >
                </VueButtonSpinner>
                <VueButtonSpinner class="btn btn-danger btn-sm">
                  <span @click="editRowField('cancel', item.code, item)"
                    >Cancel</span
                  >
                </VueButtonSpinner>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="isloading" id="splash-wrapper">
        <div id="splash-text">
          <img src="/images/dpauls-logo.png" />
        </div>
        <div id="splash-content"></div>
      </div>
    </div>
  </container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VueButtonSpinner from "vue-button-spinner";
// import { url } from "vuelidate/lib/validators";
export default {
  name: "PackageSeo",
  data() {
    return {
      destinations: "",
      packages: [],
      searchBycode: false,
      code: "",
      cloneData: {},
      Isactive: false,
      editField: null,
      isloading: false
    };
  },
  components: {
    VueButtonSpinner
  },
  computed: {
    ...mapGetters(["information"])
  },
  created() {
    this.$store.dispatch("getDestInfo");
  },
  methods: {
    ...mapActions(["updatePackage"]),
    getPackages(destinations) {
      this.isloading = true;
      this.Isactive = true;
      this.packages = [];
      this.$store
        .dispatch("getPackagesByCountry", { countryID: destinations })
        .then(data => {
          this.isloading = false;
          this.packages = data.filter(val => {
            if (!val.seo) {
              val.seo = {
                pagetitle: "",
                metadescription: "",
                metakeywords: "",
                url: ""
              };
            }
            return val;
          });
          this.Isactive = false;
        });
    },
    getPackagesByCode(code) {
      this.packages = [];
      this.isloading = true;
      this.$store.dispatch("getPackageByCode", code).then(data => {
        if (!data.seo) {
          data.seo = {
            pagetitle: "",
            metadescription: "",
            metakeywords: "",
            url: ""
          };
        }
        if (data.code) {
          this.isloading = false;
          this.packages.push(data);
        }
      });
    },
    UpdatePackageDtls(type, value, item) {
      this.updatePackage({
        type: "DETAILS",
        data: {
          details: {
            code: item.code,
            data: { seo: item.seo }
          }
        }
      }).then(() => {
        this.editField = null;
        this.cloneData = {};
        this.$toasted.success(`update ${item.code} seo Details`, {
          position: "bottom-center",
          duration: 5000
        });
      });
    },
    editRowField(type, value, item) {
      if (!item.seo) {
        item.seo = {
          pagetitle: "",
          metadescription: "",
          metakeywords: "",
          url: ""
        };
      }
      const pkgIndex = this.packages.findIndex(data => data.code == value);
      if (type == "edit") {
        this.editField = value;
        delete this.packages[pkgIndex].seo.__typename;
        this.cloneData = { ...this.packages[pkgIndex].seo };
      } else {
        this.packages[pkgIndex].seo = this.cloneData;
        this.cloneData = {};
        this.editField = null;
      }
    }
  }
};
</script>

<style scoped>
.width-center {
  margin: 0 auto;
}
.table >>> textarea {
  width: 100%;
}

table p {
  margin: 0;
}
.remove-right-radius {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.btn-info:hover {
  color: #fff !important;
  background-color: #138496 !important;
  border-color: #117a8b !important;
}
.btn-info {
  border: 0;
  -webkit-appearance: none;
  background-color: #138496 !important;
  color: white !important;
  cursor: pointer;
}
.btn-info > button:disabled {
  cursor: not-allowed;
}

.overflow {
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.submit-btn {
  height: 27.95px;
  font-size: 12px;
  width: 100%;
}

.btn-submit {
  border-radius: 0;
  padding: 0px 10px;
  background: #17a2b8;
  color: #fff !important;
  margin: 0 4px;
  font-size: 10px;
}

.btn-success {
  color: #fff !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
  cursor: default !important;
}

.btn-danger {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
  cursor: default !important;
}

span.disabled {
  pointer-events: none;
  cursor: not-allowed;
  background-color: #efefef;
}
button[is-disabled="true"] {
  pointer-events: none !important;
  cursor: not-allowed !important;
  background-color: #efefef !important;
}

.vue-btn {
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  border-radius: 0.2rem !important;
}
</style>
