<template>
  <div id="hotel_details" class="collapse" data-parent="#services_accordion">
    <div v-if="!pkgOptions.isSingleHotel">
      <div v-if="pkgOptions && pkgOptions.items && pkgOptions.items.length" class="container-fluid py-2">
        <div class="row">
          <div class="col-2">
            <ul class="options_list list-inline">
              <li
                :class="{
                  active: item.type == selectedOption.type
                }"
                @click.prevent="selectedOption = JSON.parse(JSON.stringify(item))"
                class="option_list_item"
                v-for="item in pkgOptions.items.filter(data => !data.isNew)"
                :key="item.type"
              >
                {{ item.type }}
              </li>
            </ul>
          </div>
          <div class="col-10">
            <!--  <div v-if="selectedOption" class="option_details_container ml-2">
              <div class="destination_container">
                <div
                  @click.prevent="handleSelectedDestination(city)"
                  class="destination_item"
                  v-for="city in renderDestinationWithHotels"
                  :key="city.id"
                >
                  <div>{{ city.destinationName }}, {{ city.countryName }}</div>
                  <div>
                    <span>
                      Stay: <b>{{ city.city_stay }}</b>
                    </span>
                     <span> | </span>
                    <span>
                      Hotels: <b>{{ city.hotels.length }}</b>
                    </span>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="destination_item_container">
              <div class="destination_item" v-for="city in renderDestinationWithHotels" :key="city.id">
                <label class="mb-1">
                  {{ city.destinationName }}, {{ city.countryName }}
                  <span @click.prevent="handleSelectedDestination(city)" class="add_button">Add New</span>
                </label>
                <div class="destination_item_content">
                  <table class="table mb-0">
                    <thead class="table-teal">
                      <tr>
                        <th style="width: 5%">Nights</th>
                        <th style="width: 84%">Hotels</th>
                        <th style="width: 11%">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="hotel in city.hotels" :key="hotel.stay">
                        <td>{{ hotel.stay }}</td>
                        <td>{{ hotel.hotels.map(data => data.name).join(" / ") }}</td>
                        <td>
                          <div class="d-flex flex-wrap">
                            <button class="btn btn-submit m-0" @click.prevent="handleSelectedDestination(city, hotel)">Edit</button>
                            <button class="btn btn-danger m-0 ml-2" @click.prevent="selectedItemForDelete = { city, hotel }">Remove</button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="no_record" v-if="!city.hotels.length">
                    <div style="display:flex:justify-content:center;width:100%" class="">No Record Found</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="option_not_found">Option Not Found</div>
      <popup-model class="hotel_model" :openModel="selectedDestination != null" :closeBtn="false">
        <div v-if="selectedDestination" slot="content">
          <div class="d-flex justify-content-between align-items-center hotel_model_title" v-if="selectedDestination">
            <h3 class="m-0 p-0">
              Choose Hotels For :
              <span style="color:var(--teal)">{{ selectedDestination.destinationName }}, {{ selectedDestination.countryName }}</span>
            </h3>
            <div class="action_btn">
              <div class="form_section">
                <div class="form-group mb-0 mr-2">
                  <input v-model="search_hotel" type="text" placeholder="search hotel by name" class="form-control form-control-sm" />
                </div>
                <div class="form-group mb-0 mr-2">
                  <select :disabled="is_update_hotel" v-model.trim.number="selected_night" class="form-control form-control-sm">
                    <option selected :value="0">Nights</option>
                    <option v-for="item in packageDetails.total_nights" :key="item" :value="+item">{{ item }}</option>
                  </select>
                </div>
              </div>
              <div class="d-flex">
                <button :disabled="is_submitting" @click.prevent="cancelHotelSelection" class="btn btn-danger btn-sm">
                  Cancel
                </button>
                <button :disabled="is_submitting || !selected_night" @click.prevent="handleUpdateHotels" class="btn btn-info btn-sm ml-2">
                  Update
                </button>
              </div>
            </div>
          </div>
          <hr class="mb-0" />
          <hotel-selection
            @on-select="handleHotelSelect"
            :is_single_hotel="pkgOptions.isSingleHotel"
            :search_hotel="search_hotel"
            v-if="selectedDestination"
            :selected_hotels="selectedHotels"
            :destination_id="selectedDestination.id"
          />
        </div>
      </popup-model>
      <popup-model class="is_confirmed_model" :openModel="selectedItemForDelete != null" :closeBtn="false">
        <h4 class="mb-1" v-if="selectedItemForDelete" slot="title">
          Remove <span style="color:#f56d6d">{{ selectedItemForDelete.city.destinationName }}</span> Stay
          <span style="color:#f56d6d">{{ selectedItemForDelete.hotel.stay }}</span> Night
        </h4>
        h
        <div slot="content">
          <hr class="my-1" />
          <p class="popup-header">
            Are you sure you want to remove this hotel items
          </p>
          <div class="d-flex flex-wrap justify-content-center">
            <button class="btn btn-submit m-0 mr-2" @click.prevent="handleRemoveHotels">Confirm</button>
            <button class="btn btn-danger m-0" @click="selectedItemForDelete = null">Cancel</button>
          </div>
        </div>
      </popup-model>
    </div>
    <div v-else style="position: relative;">
      <hotel-selection
        @on-select="handleHotelSelect"
        :is_single_hotel="pkgOptions.isSingleHotel"
        :search_hotel="search_hotel"
        v-if="selectedDestination"
        :hotel_id="this.packageDetails.hotel_id"
        :selected_hotels="selectedHotels"
        :destination_id="selectedDestination.id"
      />
      <div v-if="is_submitting" class="loader_section">
        <loader />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import HotelSelection from "./select_hotels.vue";
import { head, uniqBy } from "lodash";
export default {
  name: "Hotels",
  components: { HotelSelection },
  props: {
    activeTabs: { type: String }
  },
  data() {
    return {
      selectedOption: null,
      selectedDestination: null,
      selected_night: 0,
      selectedHotels: [],
      search_hotel: "",
      is_submitting: false,
      is_update_hotel: false,
      selectedItemForDelete: null
    };
  },
  watch: {
    pkgOptions: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value.isSingleHotel) {
          if (this.packageDetails.pkg_destinations.length) {
            this.selectedDestination = {
              id: head(this.packageDetails.pkg_destinations).id
            };
          }
        } else if (value?.items?.length) {
          if (!this.selectedOption) {
            this.selectedOption = JSON.parse(JSON.stringify(value.items[0]));
          }
        }
      }
    },
    activeTabs() {
      if (!this.pkgOptions.isSingleHotel && this?.pkgOptions?.items?.length) {
        this.selectedOption = JSON.parse(JSON.stringify(this?.pkgOptions?.items[0]));
      }
    }
    /* selected_night() {
      if (this.selected_night) {
        if (this?.selectedOption?.hotels?.length) {
          const _hotels = this?.selectedOption?.hotels?.find(data => data.city == this.selectedDestination.id);
          if (_hotels) {
            const _items = _hotels?.item?.find(_ht => _ht.stay == this.selected_night);
            this.selectedHotels = _items?.hotels || [];
          }
        }
      }
    } */
  },
  computed: {
    ...mapGetters(["pkgOptions", "packageDetails", "availableHotels"]),
    renderDestinationWithHotels() {
      const selectedOptionHotels = this?.selectedOption?.hotels || [];
      const destinations = JSON.parse(JSON.stringify(this?.packageDetails?.pkg_destinations)) || [];
      return destinations.reduce((arr, data) => {
        const _hotel = selectedOptionHotels.find(_ht => _ht.city == data.id);
        data.hotels = _hotel?.item /* ?.reduce((a, b) => {
            a.push(...b.hotels);
            return a;
          }, []) */ || [];
        data.city_stay = _hotel?.item?.reduce((a, b) => a + b.stay, 0) || 0;
        arr.push(data);
        return arr;
      }, []);
    }
  },
  methods: {
    ...mapMutations(["addAndRemoveHotels", "updateAvailableHotels"]),
    ...mapActions(["updatePackage", "getAllPkgHotels"]),
    handleSelectedDestination(city, hotel) {
      this.selectedDestination = city;
      if (!hotel) {
        /* this.selectedHotels =
          city?.hotels?.map(data => {
            delete data.id;
            return data;
          }) || []; */
        this.selectedHotels = [];
        this.selected_night = 0;
      } else {
        this.is_update_hotel = true;
        this.selectedHotels =
          hotel?.hotels?.map(data => {
            delete data.id;
            return data;
          }) || [];
        this.selected_night = hotel?.stay || 0;
      }
    },
    cancelHotelSelection() {
      this.search_hotel = "";
      this.selectedDestination = null;
      this.selected_night = 0;
      this.selectedHotels = [];
      this.search_hotel = "";
      this.is_update_hotel = false;
    },
    handleHotelSelect(item) {
      if (this.pkgOptions.isSingleHotel) {
        this.selectedHotels = [{ _id: item._id, name: item.name }];
        this.handleSubmitData("single");
      } else {
        if (this?.selectedHotels?.length) {
          const _index = this.selectedHotels.findIndex(data => data._id == item._id);
          if (_index == -1) {
            this.selectedHotels.push({ _id: item._id, name: item.name });
          } else {
            this.selectedHotels.splice(_index, 1);
          }
        } else {
          this.selectedHotels.push({ _id: item._id, name: item.name });
        }
      }
    },
    handleUpdateHotels() {
      if (!this.pkgOptions.isSingleHotel) {
        const _htlIndex = this?.selectedOption?.hotels?.findIndex(data => data.city == this.selectedDestination.id);
        if (_htlIndex == -1) {
          this?.selectedOption?.hotels.push({
            city: this.selectedDestination.id,
            item: [{ stay: this.selected_night, hotels: this.selectedHotels }],
            stay: 0
          });
        } else {
          const _itemIndex = this?.selectedOption?.hotels[_htlIndex]?.item?.findIndex(_ht => _ht.stay == this.selected_night);
          if (this.selectedHotels.length) {
            if (_itemIndex != -1) {
              this?.selectedOption?.hotels[_htlIndex]?.item.splice(_itemIndex, 1, {
                hotels: this.is_update_hotel ? this.selectedHotels : this.handleDestHotels(this?.selectedOption?.hotels[_htlIndex]?.item[_itemIndex]),
                stay: this.selected_night
              });
            } else {
              this?.selectedOption?.hotels[_htlIndex]?.item?.push({
                hotels: this.selectedHotels,
                stay: this.selected_night
              });
            }
          } else if (this.is_update_hotel && _itemIndex != -1) {
            this?.selectedOption?.hotels[_htlIndex]?.item.splice(_itemIndex, 1);
          }
        }
      }
      this.handleSubmitData("multiple").then(data => {
        this.updateAvailableHotels(this.selectedOption);
      });
    },
    handleDestHotels(oldHotels) {
      const allHotels = [...oldHotels?.hotels, ...this.selectedHotels];
      return uniqBy(allHotels, "_id");
    },
    handleRemoveHotels() {
      const _htlIndex = this?.selectedOption?.hotels?.findIndex(data => data.city == this.selectedItemForDelete.city.id);
      const _itemIndex = this?.selectedOption?.hotels[_htlIndex]?.item?.findIndex(_ht => _ht.stay == this.selectedItemForDelete.hotel.stay);
      if (_itemIndex != -1) {
        this?.selectedOption?.hotels[_htlIndex]?.item.splice(_itemIndex, 1);
      }
      this.handleSubmitData("multiple").then(() => {
        this.selectedItemForDelete = null;
        this.updateAvailableHotels(this.selectedOption);
      });
    },
    handleSubmitData(type) {
      return new Promise(resolve => {
        let pkgOptions = JSON.parse(JSON.stringify(this.pkgOptions));
        pkgOptions = {
          ...pkgOptions,
          items: pkgOptions.items.filter(data => !data.isNew)
        };
        if (type != "single") {
          let _optIndex = this.pkgOptions.items.findIndex(data => data.type == this.selectedOption.type);
          pkgOptions.items.splice(_optIndex, 1, this.selectedOption);
        }
        this.is_submitting = true;
        this.updatePackage({
          type: "ADVANCE",
          data: {
            advance: {
              code: this.$route.query.code,
              data: {
                [this.pkgOptions.isSingleHotel ? "hotel_id" : "options"]: this.pkgOptions.isSingleHotel ? head(this.selectedHotels) : pkgOptions
              }
            }
          }
        })
          .then(() => {
            this.is_submitting = false;
            if (type != "single") {
              this.cancelHotelSelection();
              resolve("update-hotel");
            }
            this.$toasted.success("Hotels Data Is Updated", {
              position: "bottom-center",
              duration: 5000
            });
          })
          .catch(err => {
            this.is_submitting = false;
          });
      });
    }
  }
};
</script>

<style scoped>
.is_confirmed_model >>> .popup_body {
  padding: 10px;
  width: 450px;
}

.popup-header {
  color: #5c5c5c;
  font-size: 1.2rem;
}

.is_confirmed_model .btn {
  width: 75px;
  height: 25px;
}

.hotel_model >>> .popup_body {
  overflow: hidden !important;
  padding: 0;
  max-width: unset;
  max-height: 615px;
  min-height: 615px;
}

.hotel_model >>> .popup_body .hotel_model_title {
  padding: 8px 10px 0px 10px !important;
}
.option_not_found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  color: rgb(243, 72, 20);
}

.options_list {
  list-style: none !important;
  margin-bottom: 0;
}

.options_list > .option_list_item {
  background-color: #e6e6e6;
  padding: 5px 10px;
  cursor: pointer;
}

.options_list > .option_list_item.active {
  background-color: var(--teal);
  color: var(--white);
}

.options_list > .option_list_item:not(:last-child) {
  border-bottom: 1px solid rgb(180, 180, 180);
}

.destination_container {
  display: flex;
  flex-wrap: wrap;
}

.destination_container .destination_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(195, 193, 193);
  padding: 4px 10px;
  color: #333;
  cursor: pointer;
  margin-right: 5px;
  margin-top: 5px;
  width: 15%;
}

.destination_item_container .destination_item:not(:last-child) {
  border-bottom: 1px dotted #3c3c3c;
  padding-bottom: 10px;
  margin-bottom: 8px;
}

.destination_item_container .destination_item > label {
  font-weight: 600 !important;
  color: #5c5c5c;
  display: flex;
  align-items: center;
}

.destination_item_container .destination_item table {
  color: #3c3c3c;
}

.destination_item_container .destination_item table tbody td {
  font-weight: 600;
}

.destination_container .destination_item:nth-child(-n + 6) {
  margin-top: 0;
}

.action_btn {
  display: flex;
}

.action_btn {
  display: flex;
}

.action_btn .form_section {
  display: flex;
}

.action_btn button {
  border-radius: 0px;
  width: 80px;
  padding: 3px 0px;
}

.loader_section {
  position: absolute;
  z-index: 999;
  background-color: rgba(0, 0, 0, 30%);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.add_button {
  border: 1px solid #5c5c5c;
  font-size: 10px;
  padding: 0px 8px;
  border-radius: 10px;
  font-weight: 600;
  color: #5c5c5c;
  margin-left: 5px;
  cursor: pointer;
}

.btn-submit,
.btn-danger {
  border-radius: 0;
  padding: 0px 10px;
  font-size: 10px;
}

.btn-submit {
  background: #17a2b8;
  color: #fff !important;
}

.no_record {
  color: red;
  display: flex;
  justify-items: center;
  width: 100%;
  text-align: center;
  height: 25px;
  align-items: center;
}
</style>
