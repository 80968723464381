export default {
  methods: {
    /* Handle A dates Modification Section Against Events Of Insert & Update a& Disabled */
    onSelectionDates(date) {
      if (this.modifiyEvent == "insert") {
        this.manageSelectionDates({ date });
      } else if (this.modifiyEvent == "disabled") {
        this.handleUpdateAndActiveDates({ date, type: this.modifiyEvent });
      } else if (this.modifiyEvent == "updateall") {
        this.handleUpdateAndActiveDates({ date, type: this.modifiyEvent });
      } else if (this.modifiyEvent == "") {
        this.viewSelectedDate(date);
      }
      this.dateChanges = !this.dateChanges;
    },
    getHighLightDates() {
      const OptIndex = this.pkgOptions.items.findIndex(
        value => value.type == this.selectedOptionType
      );
      if (
        (this.modifiyEvent != "updateall" && this.modifiyEvent != "insert") ||
        this.modifiyEvent == ""
      ) {
        if (OptIndex != -1) {
          this.allHighlightDates = this.handleUpdateAndDesabledDates(OptIndex);
        }
      } else if (this.modifiyEvent == "updateall") {
        if (OptIndex != -1) {
          this.allHighlightDates = this.handleUpdateAndDesabledDates(OptIndex);
        }
      } else {
        if (OptIndex != -1) {
          this.allHighlightDates = this.newSelectedDates[OptIndex].dates;
        }
      }
    },

    handleUpdateAndDesabledDates(OptIndex) {
      let newdataList = {};
      const selectDate = { ...this.newSelectedDates[OptIndex].dates };
      const existsDates = { ...this.pkgOptions.items[OptIndex].dates };
      for (const item in existsDates) {
        newdataList[item] = existsDates[item].map(data => {
          if (selectDate[item]) {
            const selDate = selectDate[item].findIndex(
              val => val.value == data.value
            );
            if (selDate != -1) {
              data = selectDate[item][selDate];
            }
          }
          return data;
        });
      }
      return newdataList;
    },
    onClickNext() {
      this.currentMonth = this.currentMonth.clone().add(1, "months");
    },
    onClickPrev() {
      this.currentMonth = this.currentMonth.clone().subtract(1, "months");
    },
    manageTotalDates() {
      const optIndex = this.newSelectedDates.findIndex(
        data => data.type == this.selectedOptionType
      );
      if (optIndex != -1) {
        if (this.modifiyEvent == "") {
          this.totalDate = this._.flatMapDeep(
            this.pkgOptions.items[optIndex].dates
          ).length;
        } else {
          this.totalDate = this._.flatMapDeep(
            this.newSelectedDates[optIndex].dates
          ).length;
        }
      } else {
        this.totalDate = 0;
      }
    },
    handleSaveData(type) {
      const data = this[type];
      let pkgOptions = this.pkgOptions;
      if (this.modifiyEvent == "insert") {
        pkgOptions = this.handleInsertDates(pkgOptions, data);
        this.handleSubmitData(pkgOptions);
      } else if (this.modifiyEvent == "updateall") {
        pkgOptions = this.handleUpdateAvailDates(pkgOptions);
        //this.handleSubmitData(pkgOptions);
      } else {
        pkgOptions = this.handleInsertDates(pkgOptions, data);
        this.handleSubmitData(pkgOptions);
      }
    },
    handleHotelData(listitem) {
      let newListItem = JSON.parse(listitem);
      for (let item of newListItem) {
        if (item.hotels) {
          for (let hotel of item.hotels) {
            if (hotel.type && hotel.type._id) {
              hotel.type = hotel.type._id;
            }
            if (hotel.item) {
              hotel.item = hotel.item.map(data => ({
                ...data,
                name: data.name && data.name._id ? data.name._id : data.name
              }));
            }
          }
        }
      }
      return newListItem;
    },
    handleSubmitData(pkgOptions) {
      const items = pkgOptions.items.filter(data => !data.isNew);
      pkgOptions = {
        ...pkgOptions,
        items: this.handleHotelData(JSON.stringify(items))
      };
      this.updatePackage({
        type: "ADVANCE",
        data: {
          advance: {
            code: this.$route.query.code,
            data: { options: pkgOptions }
          }
        }
      }).then(() => {
        if (this.modifiyEvent != "disabled") {
          this.setNewSelectionDate();
          this.getHighLightDates();
          this.resetValueOFInsertData();
          this.manageTotalDates();
          this.resetPricesOnChange();
        }
        this.$toasted.success(`Dates are ${this.modifiyEvent} successfully`, {
          position: "bottom-center",
          duration: 5000
        });
      });
    },
    /* Handle New Insert Dates Into Options */
    handleInsertDates(pkgOptions, data) {
      /* Condition only true when both old and new destination options are same in length */
      if (pkgOptions.items.length == data.length) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].type === pkgOptions.items[i].type) {
            for (let date in data[i].dates) {
              if (pkgOptions.items[i].dates.hasOwnProperty(date)) {
                for (let k = 0; k < data[i].dates[date].length; k++) {
                  const dateIndex = pkgOptions.items[i].dates[date].findIndex(
                    val => val.value == data[i].dates[date][k].value
                  );
                  if (dateIndex != -1) {
                    if (this.modifiyEvent == "insert") {
                      let finalPrice = this.datesPrices;
                      if (pkgOptions.items[i].is_honeymoon) {
                        finalPrice = {
                          ...finalPrice,
                          adult: {
                            ...finalPrice.adult,
                            singleallowed: false,
                            extrabedallowed: false
                          },
                          child: {
                            ...finalPrice.child,
                            cwballowed: false,
                            cnballowed: false
                          },
                          infantallowed: false
                        };
                      }
                      pkgOptions.items[i].dates[date][
                        dateIndex
                      ].prices = finalPrice;
                    } else if (this.modifiyEvent == "disabled") {
                      pkgOptions.items[i].dates[date][dateIndex].active =
                        data[i].dates[date][k].active;
                    }
                  } else {
                    pkgOptions.items[i].dates[date].push({
                      ...data[i].dates[date][k],
                      prices: {
                        ...this.datesPrices,
                        adult: {
                          ...this.datesPrices.adult,
                          singleallowed: pkgOptions.items[i].is_honeymoon
                            ? false
                            : this.datesPrices.adult.singleallowed,
                          extrabedallowed: pkgOptions.items[i].is_honeymoon
                            ? false
                            : this.datesPrices.adult.extrabedallowed
                        },
                        child: {
                          ...this.datesPrices.child,
                          cwballowed: pkgOptions.items[i].is_honeymoon
                            ? false
                            : this.datesPrices.child.cwballowed,
                          cnballowed: pkgOptions.items[i].is_honeymoon
                            ? false
                            : this.datesPrices.child.cnballowed
                        },
                        infantallowed: pkgOptions.items[i].is_honeymoon
                          ? false
                          : this.datesPrices.infantallowed
                      }
                    });
                  }
                }
              } else {
                pkgOptions.items[i].dates[date] = data[i].dates[date].filter(
                  data => {
                    return (data.prices = {
                      ...this.datesPrices,
                      adult: {
                        ...this.datesPrices.adult,
                        singleallowed: pkgOptions.items[i].is_honeymoon
                          ? false
                          : this.datesPrices.adult.singleallowed,
                        extrabedallowed: pkgOptions.items[i].is_honeymoon
                          ? false
                          : this.datesPrices.adult.extrabedallowed
                      },
                      child: {
                        ...this.datesPrices.child,
                        cwballowed: pkgOptions.items[i].is_honeymoon
                          ? false
                          : this.datesPrices.child.cwballowed,
                        cnballowed: pkgOptions.items[i].is_honeymoon
                          ? false
                          : this.datesPrices.child.cnballowed
                      },
                      infantallowed: pkgOptions.items[i].is_honeymoon
                        ? false
                        : this.datesPrices.infantallowed
                    });
                  }
                );
              }
            }
          }
        }
        return pkgOptions;
      } else {
        this.$toasted.info("options are not matched");
      }
    },
    /* Update Prices On All Aavailable dates at same time */
    handleUpdateAvailDates(pkgOptions) {
      pkgOptions.items.filter(data => {
        for (let item in data.dates) {
          data.dates[item].filter(date => (date.prices = this.datesPrices));
        }
      });
      return pkgOptions;
    },
    /* Reset New Selected Date Array */
    resetValueOFInsertData() {
      let newData = [];
      for (let item of this.pkgOptions.items) {
        let newDates = {};
        newData.push({
          type: item.type,
          dates: {}
        });
        for (let month in item.dates) {
          newDates[month] = [];
          for (let date of item.dates[month]) {
            newDates[month].push({
              ...date
            });
          }
        }
      }
      this.isFieldsDataExists({ value: newData, type: "newSelectedDates" });
    },
    viewSelectedDate(date) {
      const optIndex = this.pkgOptions.items.findIndex(
        data => data.type == this.selectedOptionType
      );
      if (
        optIndex != -1 &&
        this.pkgOptions.items[optIndex].dates[date.format("MM-YYYY")]
      ) {
        const dateIndex = this.pkgOptions.items[optIndex].dates[
          date.format("MM-YYYY")
        ].findIndex(val => +val.value == +date.format("DD"));
        if (dateIndex != -1) {
          const prices = this.pkgOptions.items[optIndex].dates[
            date.format("MM-YYYY")
          ][dateIndex];
          this.isFieldsDataExists({ value: prices.prices, type: "prices" });
        } else {
          this.resetPricesOnChange();
        }
      } else {
        this.resetPricesOnChange();
      }
    },
    /* Handle Check New Dates are Exits In insertMode */
    checkNewDatesExits() {
      let count = 0;
      for (const item of this.newSelectedDates) {
        if (!this._.isEmpty(item.dates)) {
          count++;
        }
      }
      if (count > 0) {
        this.newDateSelected = false;
      } else {
        this.newDateSelected = true;
      }
    },
    convertDateWithKey(key, value) {
      return key.split("-")[1] + key.split("-")[0] + value;
    },
    checkIfSameModify(e) {
      if (this.modifiyEvent == e) {
        this.modifiyEvent = "";
      } else {
        this.modifiyEvent = e;
      }
    },
    filterSelectedDateView(value) {
      return this._.sortBy(value, "value");
    }
  }
};
